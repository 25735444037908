import React, { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import './ModalContent.scss';

interface Props {
  children: React.ReactNode;
}

const ModalContent = ({ children }: Props) => {
  return (
    <div className="modal-content">
      <div className="modal-inside">{children}</div>
    </div>
  );
};

export default ModalContent;
