import React from 'react';

import { connect } from 'react-redux';
import Navigation from './Navigation';
import { ApplicationState } from '../../../reducers';
import { bindActionCreators } from 'redux';
import { menu } from '../../../actions';
import { ButtonsContainer, LogoContainer } from '../../Layout';

import { Button, Logo, MenuToggle, ProfileCircle } from '..';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NavigationItem } from '../../../types/navigation';
import { __ } from '../../../services/translation';

import './Sidebar.scss';

interface Props {
  menuItems: NavigationItem[];
  desktopItems?: NavigationItem[];
  toggled: boolean;
  toggleMenu: () => void;
}

const Sidebar = ({ menuItems, desktopItems, toggled, toggleMenu }: Props) => {
  return (
    <>
      <aside className={`sidebar ${toggled ? 'toggled' : ''}`}>
        <div className="sidebar-container">
          <div className="sidebar-logo">
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </div>
          <div className="sidebar-toggle">
            <MenuToggle />
          </div>
          <div className="sidebar-circle">
            <ProfileCircle />
          </div>

          <Navigation items={menuItems} desktopItems={desktopItems} />
          {/* <ButtonsContainer className="sidebar-button">
            <Button
              click={toggleMenu}
              className="close-button"
              text={('sidebar.close')}
              faIcon={faChevronRight}
              leftIcon
            />
          </ButtonsContainer> */}
        </div>
      </aside>
      <div
        className={`sidebar-shadow ${!toggled ? '' : 'toggled'}`}
        onClick={toggleMenu}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  toggled: state.menu.toggled,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ toggleMenu: menu.toggleMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
