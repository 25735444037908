import errorTranslations from '../data/errorsTranslations.json';
import { i18n } from '../services/translation';

export const formOnTranslateString = (s: string, params?: any[]): string => {
  const transes: {
    [lang: string]: {
      [key: string]: string;
    };
  } = errorTranslations;

  let translated = '';
  if (
    i18n.currentLang &&
    transes &&
    transes[i18n.currentLang] &&
    transes[i18n.currentLang][s]
  ) {
    translated = transes[i18n.currentLang][s];
  } else translated = i18n.__(s, {});

  if (params) {
    return translated.replace(/{(\d+)}/g, (match: any, number: number) => {
      return typeof params[number] !== 'undefined'
        ? typeof params[number] === 'object'
          ? Object.values(params[number])
          : params[number]
        : match;
    });
  }
  return translated;
};
