import { I18nEntry } from '../i18n';

import {
  NewSubproduct,
  SubproductDetails,
  SubproductEntity,
  SubproductSelect,
} from './subproduct';

export enum ProductType {
  STANDARD,
  PROFILE,
}

export interface ProductEntity {
  id: string;
  name: I18nEntry;
  description: I18nEntry;
  code: string;
  price?: number;
  weight?: number;
  priceFromSubproducts: boolean;
  weightFromSubproducts: boolean;
  children: SubproductEntity[];
  parents: SubproductEntity[];
  type: ProductType;
  image: string;
}

export interface ProductDetails {
  id: string;
  name: string;
  description: string;
  code: string;
  price: number;
  weight: number;
  priceFromSubproducts: boolean;
  weightFromSubproducts: boolean;
  children: SubproductDetails[];
  parents: SubproductDetails[];
  type: ProductType;
  image: string;
}

export interface ProductTableDetails {
  id: string;
  name: string;
  description: string;
  code: string;
  price: number;
  weight: number;
  priceFromSubproducts: boolean;
  weightFromSubproducts: boolean;
  children: SubproductDetails[];
  parents: SubproductDetails[];
  image: string;
}

export interface ProductForm {
  name: string;
  description: string;
  code: string;
  price: number;
  weight: number;
  priceFromSubproducts: boolean;
  weightFromSubproducts: boolean;
  children: SubproductSelect[];
  image: File;
}

export interface CreateProductReq {
  id?: string;
  name: string;
  description: string;
  code: string;
  price: string;
  weight: string;
  priceFromSubproducts: string;
  weightFromSubproducts: string;
  children: string;
}

export interface CreateProductReqParsed {
  id?: string;
  name: I18nEntry;
  description: I18nEntry;
  code: string;
  price: number;
  weight: number;
  priceFromSubproducts: boolean;
  weightFromSubproducts: boolean;
  children: NewSubproduct[];
}

export interface CreateProfileReq {
  id?: string;
  name: string;
  description: string;
  code: string;
  price: string;
  weight: string;
}

export interface CreateProfileReqParsed {
  id?: string;
  name: I18nEntry;
  description: I18nEntry;
  code: string;
  price: number;
  weight: number;
}
