import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Duplicate from './Duplicate';
import Edit from './Edit';
import List from './List';
import New from './New';

const Profiles = () => (
  <Switch>
    <Route
      exact
      path="/panel/profiles"
      render={() => <Redirect to="/panel/profiles/list" />}
    />
    <Route path="/panel/profiles/list" component={List} />
    <Route path="/panel/profiles/new" component={New} />
    <Route path="/panel/profiles/edit/:productId" component={Edit} />
    <Route path="/panel/profiles/duplicate/:productId" component={Duplicate} />
  </Switch>
);

export default Profiles;
