import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Ripple from './Ripple';
import LinkDelayed from './LinkDelayed';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { __ } from '../../../services/translation';

import './Button.scss';

interface ButtonProps {
  text?: string;
  to?: string;
  click?: any;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  success?: boolean;
  dangerText?: boolean;
  primaryText?: boolean;
  type?: ButtonType;
  big?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  disabled?: boolean;
  blank?: boolean;
  className?: string;
  faIcon?: IconProp;
  leftIcon?: boolean;
  rightIcon?: boolean;
  reversed?: boolean;
  mobileIconOnly?: boolean;
  outline?: boolean;
  link?: boolean;
}

export enum ButtonType {
  Submit = 'submit',
  Button = 'button',
  Reset = 'reset',
}

const Button = (props: ButtonProps): any => {
  const [ripples, setRipples] = useState<{ x: number; y: number }[]>([]);

  const {
    className,
    primary,
    small,
    extraSmall,
    big,
    disabled,
    blank,
    to,
    type,
    click,
    text,
    secondary,
    faIcon,
    rightIcon,
    leftIcon,
    danger,
    success,
    reversed,
    mobileIconOnly,
    outline,
    link,
  } = props;

  const setButtonType = (userinput = 'button'): any => {
    switch (userinput) {
      case ButtonType.Button:
        return 'button';
      case ButtonType.Submit:
        return 'submit';
      default:
        return 'button';
    }
  };

  const buttonClass = clsx('button', className, {
    'button-primary': primary,
    'button-reversed': reversed,
    'button-secondary': secondary,
    'button-big': big,
    'button-small': small,
    'button-extra-small': extraSmall,
    'button-right-icon': rightIcon,
    'button-left-icon': leftIcon,
    'button-disabled': disabled,
    'button-danger': danger,
    'button-success': success,
    'button-outline': outline,
    'button-no-text': !text,
    'button-link': link,
  });

  const handleClick = (event: any) => {
    event.stopPropagation();

    const el = event.currentTarget;
    const r = el.getBoundingClientRect();
    const b = document.body.getBoundingClientRect();
    const x = Math.round(event.pageX - r.left);
    const y = Math.round(event.pageY - (r.top - b.top));

    setRipples((ripples) => [...ripples, { x, y }]);

    if (typeof click === 'function') setTimeout(() => click(event), 200);
    if (el.type !== 'submit' && el.target !== '_blank') event.preventDefault();
  };

  const content = (
    <>
      {ripples.map((r) => (
        <Ripple
          key={`${r.x} ${r.y}`}
          x={r.x}
          y={r.y}
          primary={primary}
          danger={danger}
          success={success}
          secondary={secondary}
        />
      ))}
      <span
        className={clsx('button-text', { 'button-icon-only': mobileIconOnly })}
      >
        {__(text)}
      </span>
    </>
  );

  if (to) {
    if (disabled) {
      return <a className={buttonClass}>{content}</a>;
    }
    if (blank) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={buttonClass}
          href={to}
          onClick={handleClick}
        >
          {leftIcon && faIcon ? (
            <div className="button-icon">
              <FontAwesomeIcon icon={faIcon} />
            </div>
          ) : null}
          {content}
        </a>
      );
    }
    return (
      <LinkDelayed className={buttonClass} to={to} onClick={handleClick}>
        {leftIcon && faIcon ? (
          <div className="button-icon">
            <FontAwesomeIcon icon={faIcon} />
          </div>
        ) : null}
        {content}
        {rightIcon && faIcon ? (
          <div className="button-icon">
            <FontAwesomeIcon icon={faIcon} />
          </div>
        ) : null}
      </LinkDelayed>
    );
  }

  return (
    <button
      type={setButtonType(type) || 'button'}
      disabled={disabled}
      onClick={handleClick}
      className={buttonClass}
    >
      {leftIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
      {rightIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
      {content}
    </button>
  );
};

export default Button;
