import React from 'react';
import HelloText from '../HelloText';
import ProfileDetails from '../ProfileDetails';

import './HomeCircle.scss';

interface Props {
  children?: React.ReactNode;
}

const HomeCircle = ({children}: Props) => (
  <div className="home-circle-wrapper">
    <div className="mobile-hello-text">
      {children}
    </div>
    <div className="circle first"></div>
    <div className="circle second"></div>
  </div>
);

export default HomeCircle;