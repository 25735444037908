import SessionService from '../services/session-service';
import { i18n } from '../services/translation';

interface ChangeLang {
  type: 'CHANGE_LANGUAGE';
  payload: {
    lang: string;
    saveInSession: boolean;
  };
}

export interface LangState {
  current: string;
  loadingLang: boolean;
}

const initialState: LangState = {
  current: SessionService.getCurrentLanguage(),
  loadingLang: false,
};

type Action = ChangeLang;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE': {
      i18n.changeLang(action.payload.lang, action.payload.saveInSession);
      return { ...state, current: action.payload.lang };
    }
    default:
      return state;
  }
};
