import React from 'react';
import './Subtitle.scss';

interface Props {
    children: React.ReactNode;
}

const Subtitle = ({children}: Props) => {
    return <div className='subtitle'>{children}</div>
}

export default Subtitle;