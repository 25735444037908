import React from 'react';
import { Button, MenuToggle } from '..';
import { __ } from '../../../services/translation';
import { Wrapper } from '../../Layout';

import './Header.scss';

interface Props {
  header: string;
  buttonText?: string;
  buttonTo?: string;
  children?: React.ReactNode;
}

const Header = ({ header, buttonText, buttonTo, children }: Props) => (
  <Wrapper className="header-wrapper">
    <div className="header-main">
      <MenuToggle />
      <h1 className="section-header">{__(header)}</h1>
    </div>
    <div className="header-additional">
      {buttonText && buttonTo && (
        <Button secondary text={buttonText} to={buttonTo} />
      )}
      {children}
    </div>
  </Wrapper>
);

export default Header;
