import React from 'react';
import './FileInput.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileImage,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { __ } from '../../../services/translation';

interface Props {
  input: any;
  error?: any;
  meta?: any;
  touched?: any;
  placeholder?: string;
  label?: string;
  value?: any;
  options?: any[];
  readonly?: boolean;
  nomargin?: boolean;
  max?: boolean;
  hasImage?: string;
  onChange?: (value: string) => void;
  isNotImage?: boolean;
  accept?: string;
}

interface State {
  currentImg: any;
  imgFileName: string;
  imgStatus: boolean;
  dragging: boolean;
  cantRender: boolean;
}

const errorClass = (error: boolean, touched: boolean) =>
  error && touched ? 'is-invalid' : '';

const adaptFileEventToValue = (delegate: any) => (e: any) =>
  delegate(e && e.target && e.target.files ? e.target.files[0] : e);

class FileInput extends React.Component<Props, State> {
  fileReader: FileReader;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentImg: null,
      imgFileName: '',
      imgStatus: false,
      dragging: false,
      cantRender: false,
    };
    this.fileReader = new FileReader();
  }

  private onChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.input.onChange(e.target.files[0]);
    if (e.target.files[0] && e.target.files) {
      this.setState({
        imgFileName: e.target.files[0].name,
      });
      this.fileReader.onload = (e: any) => {
        this.setState({
          currentImg: e.target.result,
          imgStatus: true,
          cantRender: false,
        });
      };
      this.fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  private onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files[0]) {
      this.setState({
        imgFileName: e.dataTransfer.files[0].name,
        dragging: false,
      });
      this.fileReader.onload = (e: any) => {
        this.setState({
          currentImg: e.target.result,
          imgStatus: true,
          cantRender: false,
        });
      };
    }
    this.fileReader.readAsDataURL(e.dataTransfer.files[0]);
    this.props.input.onChange(e.dataTransfer.files[0]);
  };

  private onDragEnter = (e: any) => {
    e.preventDefault();
    this.setState({
      dragging: true,
    });
    e.stopPropagation();
  };

  private onDragLeave = (e: any) => {
    e.preventDefault();
    this.setState({
      dragging: false,
    });
    e.stopPropagation();
  };

  private hasImage = () => {
    return this.props.hasImage || this.props.input.value;
  };

  componentDidMount() {
    const { input } = this.props;
    if (input.value && typeof input.value !== 'string') {
      this.fileReader.onload = (e: any) => {
        this.setState({
          currentImg: e.target.result,
          imgStatus: true,
          cantRender: false,
        });
      };
      this.fileReader.readAsDataURL(input.value);
    }
  }

  private cantRenderFile = () => {
    this.setState({
      cantRender: true,
    });
  };

  render() {
    const { accept } = this.props;
    const { cantRender } = this.state;
    const inputCopy = { ...this.props.input };

    delete inputCopy.value;
    const imgPreview = (
      <div className="img-preview-wrapper">
        <p className="file-name">{this.state.imgFileName}</p>
        {!cantRender && (
          <img
            className="img-preview"
            src={
              this.state.currentImg ||
              (typeof this.props.input.value !== 'string' &&
                this.props.input.value) ||
              this.props.hasImage
            }
            onError={this.cantRenderFile}
          />
        )}
        {cantRender && (
          <div>
            <FontAwesomeIcon icon={faFilePdf} size="2x" />
            <p>{__('application.cant_render_file')}</p>
          </div>
        )}
      </div>
    );
    return (
      <>
        <label className="input-container">
          <div
            className={`file-container ${
              this.state.dragging ? 'dragging' : ''
            } ${this.props.max ? 'input-max' : ''} ${
              this.props.nomargin ? 'nomargin' : ''
            } ${errorClass(this.props.meta.error, this.props.meta.touched)}`}
          >
            <span className="input-name">{this.props.placeholder} </span>
            <div
              className={`file-wrapper ${this.hasImage() ? 'has-image' : ''}`}
            >
              {this.hasImage() ? (
                imgPreview
              ) : (
                <div>
                  <FontAwesomeIcon icon={faFileImage} size="2x" />
                  <p>{__('application.select_or_drag_file')}</p>
                </div>
              )}
              <input
                // {...(this.props.isNotImage !== true ? { accept: accept || 'image/*' } : { accept: accept || '*' })}
                className={`input input-file ${
                  this.props.max ? 'input-max' : ''
                } ${this.props.nomargin ? 'nomargin' : ''}`}
                id={this.props.input.name}
                readOnly={this.props.readonly}
                placeholder={this.props.placeholder}
                type="file"
                {...inputCopy}
                onChange={this.onChange}
                onBlur={adaptFileEventToValue(inputCopy.onBlur)}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragOver={this.onDragEnter}
                onDragLeave={this.onDragLeave}
              />
            </div>
          </div>
          {this.props.meta.error && this.props.meta.touched && (
            <small className="text-danger">{this.props.meta.error}</small>
          )}
        </label>
      </>
    );
  }
}
export default FileInput;
