import React from 'react';
import { ButtonsContainer } from '../../components/Layout';
import { Button, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';

interface Props {
  text: string;
  confirmCallback: () => void;
  children?: React.ReactNode;
  cancelCallback?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const Confirmation = ({
  text,
  confirmCallback,
  children,
  cancelCallback,
  confirmLabel,
  cancelLabel,
}: Props) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: ApplicationState) => state.modal);
  return (
    <ModalContent>
      {loading && <Spinner overlay halfTransparent />}
      <ModalHeader>
        <ModalTitle title="application.confirmation" />
      </ModalHeader>
      <p className="confirmation-paragraph">{__(text)}</p>
      {children}
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={
            cancelCallback ||
            (() => {
              dispatch(modal.hideModal());
            })
          }
          text={cancelLabel || 'application.no'}
          danger
          small
        />
        <Button
          className="button-modal"
          click={confirmCallback}
          small
          text={confirmLabel || 'application.yes'}
          primary
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default Confirmation;
