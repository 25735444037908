import React, { ChangeEvent, useEffect, useState } from 'react';
import { ButtonsContainer, FieldsGroup } from '../../components/Layout';
import { Button, Input, InputSelect } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal, offer } from '../../actions';
import { useDispatch } from 'react-redux';
import { ServiceDetails } from 'profilpol-types';
import ApiService from '../../services/api-service';

const OfferNewService = () => {
  const dispatch = useDispatch();
  const [services, setServices] = useState<ServiceDetails[]>([]);
  const [service, setService] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);

  const getServices = async () => {
    await ApiService.callFetch(
      'GET',
      'service/list-raw',
      (data: ServiceDetails[]) => {
        setServices(data);
      }
    );
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.add_service" />
      </ModalHeader>
      <p>{__('offer.add_service_description')}</p>

      <FieldsGroup>
        <InputSelect
          options={services.map((p) => ({ label: p.name, value: p.id }))}
          onChange={(serviceId: string) => {
            setService(serviceId);
          }}
          placeholder={'service.select'}
          value={service}
        />
        <Input
          type="number"
          step={0.01}
          max={9999}
          min={0.01}
          input={{
            value: quantity,
            onChange: (e: ChangeEvent<any>) => {
              setQuantity(e.currentTarget.value);
            },
          }}
        />
      </FieldsGroup>
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => {
            dispatch(modal.hideModal());
          }}
          text={'application.cancel'}
          danger
          small
        />
        <Button
          className="button-modal"
          click={() => {
            if (service) {
              const fullService = services.find((s) => s.id === service);
              if (fullService)
                dispatch(
                  offer.addServiceToOffer(fullService, Number(quantity))
                );
              dispatch(modal.hideModal());
            }
          }}
          disabled={!quantity || quantity <= 0 || !service}
          small
          text={'application.save'}
          primary
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default OfferNewService;
