import clsx from 'clsx';
import React from 'react';

import './Logo.scss';

interface Props {
  big?: boolean;
}

const Logo = ({big}: Props) => (
  <div className={clsx("logo-wrapper", {"logo-big": big})}>
    <img src="/assets/logo_svg.svg"></img>
  </div>
);

export default Logo;