import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  ProductType,
  RecipeProductDetails,
  RecipeServiceDetails,
} from 'profilpol-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../..';
import { modal, recipes } from '../../../../actions';
import { RecipeProduct, Confirmation, RecipeService } from '../../../../modals';
import RecipeProfile from '../../../../modals/RecipeProfile';
import { __ } from '../../../../services/translation';
import { ButtonsContainer, Wrapper } from '../../../Layout';

import './LineTab.scss';

interface Props {
  name: string;
  recipeId: string;
  componentId: string;
  type: 'product' | 'service';
  item: RecipeProductDetails | RecipeServiceDetails;
}

const LineTab = ({ name, item, recipeId, componentId, type }: Props) => {
  const dispatch = useDispatch();
  const editProduct = (item: RecipeProductDetails) => {
    if (item.product.type === ProductType.PROFILE) {
      dispatch(
        modal.showModal(
          <RecipeProfile
            componentId={componentId}
            recipeId={recipeId}
            id={item.id}
          />
        )
      );
    } else {
      dispatch(
        modal.showModal(
          <RecipeProduct
            componentId={componentId}
            recipeId={recipeId}
            id={item.id}
          />
        )
      );
    }
  };

  const confirmProductDelete = (fieldId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="recipe.confirm_product_delete"
          confirmCallback={() =>
            dispatch(recipes.deleteRecipeProduct(fieldId, recipeId))
          }
        />
      )
    );
  };

  const editService = (item: RecipeServiceDetails) => {
    dispatch(
      modal.showModal(
        <RecipeService
          componentId={componentId}
          recipeId={recipeId}
          id={item.id}
        />
      )
    );
  };

  const confirmServiceDelete = (fieldId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="recipe.confirm_service_delete"
          confirmCallback={() =>
            dispatch(recipes.deleteRecipeService(fieldId, recipeId))
          }
        />
      )
    );
  };

  const edit = (item: RecipeServiceDetails | RecipeProductDetails) => {
    if (type === 'product') editProduct(item as RecipeProductDetails);
    else editService(item as RecipeServiceDetails);
  };

  const confirmDelete = (item: RecipeServiceDetails | RecipeProductDetails) => {
    if (type === 'product') confirmProductDelete(item.id);
    else confirmServiceDelete(item.id);
  };

  const buttons = [
    {
      text: 'application.edit',
      icon: faPenAlt,
      click: edit,
      primary: true,
    },
    {
      text: 'application.delete',
      icon: faTrash,
      click: confirmDelete,
      danger: true,
    },
  ];

  const getName = () => {
    let additional: string | React.ReactNode = '';
    if (type === 'product') {
      const product = item as RecipeProductDetails;
      if (product.name) {
        additional = <strong>{product.name}</strong>;
      }
    }

    return (
      <>
        <strong>{__('application.name_label')}: </strong>
        <span className="line-main-name">{name}</span>
        {additional}
      </>
    );
  };

  return (
    <Wrapper className="line-tab-wrapper">
      <div className="line-tab-inside">
        {typeof item.position !== 'undefined' && (
          <div className="line-tab-position">{item.position}</div>
        )}
        <div className="line-tab-content">{getName()}</div>
      </div>
      <ButtonsContainer tabs>
        {buttons.map((button) => (
          <Button
            key={button.text}
            mobileIconOnly
            primary={button.primary}
            danger={button.danger}
            leftIcon
            reversed
            faIcon={button.icon}
            text={button.text}
            click={() => (button.click ? button.click(item) : {})}
          />
        ))}
      </ButtonsContainer>
    </Wrapper>
  );
};

export default LineTab;
