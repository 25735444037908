import React from 'react';
import { NavLink } from 'react-router-dom';

import { scrollTop } from '../../../../utils/scroll-top';
import { bindActionCreators } from 'redux';
import { menu } from '../../../../actions';
import { connect } from 'react-redux';
import './MenuItem.scss';
import { NavigationItem } from '../../../../types/navigation';
import { __ } from '../../../../services/translation';

interface Props extends NavigationItem {
  toggleMenu: () => void;
  desktop?: boolean;
}

class MenuItem extends React.Component<Props> {
  private handleItemClick = (): void => {
    const { onClick, toggleMenu } = this.props;
    if (typeof onClick !== 'undefined') onClick();
    toggleMenu();
  };

  render(): any {
    const { link, icon, name, desktop } = this.props;
    const itemClass = `main-menu-item ${!icon ? 'sub-menu-item' : ''} ${desktop ? 'desktop' : ''}`;
    if (link) {
      return (
        <NavLink to={link} title={name} className={itemClass} activeClassName="active" onClick={this.handleItemClick}>
          {__(name)}
        </NavLink>
      );
    } else {
      return (
        <a role="button" tabIndex={0} title={name} className={itemClass} onClick={this.handleItemClick}>
          {__(name)}
        </a>
      );
    }
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ toggleMenu: menu.toggleMenu }, dispatch);

export default connect(null, mapDispatchToProps)(MenuItem);
