import clsx from "clsx";
import React from "react";

import "./ButtonsContainer.scss";

interface Props {
  children: React.ReactNode;
  max?: boolean;
  center?: boolean;
  className?: string;
  baseline?: boolean;
  block?: boolean;
  column?: boolean;
  tabs?: boolean;
  padding?: boolean;
  marginTop?: boolean;
}

const ButtonsContainer = ({
  max,
  center,
  children,
  className,
  baseline,
  block,
  tabs,
  padding,
  marginTop
}: Props) => (
  <div
    className={clsx("buttons-container", className, {
      max: max,
      block: block,
      center: center,
      baseline: baseline,
      tabs: tabs,
      padding: padding,
      'margin-top': marginTop
    })}
  >
    {children}
  </div>
);

export default ButtonsContainer;
