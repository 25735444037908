import { OfferDetails } from 'profilpol-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { lang } from '../../actions';
import { Alert, Spinner } from '../../components/Common';
import { Container, Main } from '../../components/Layout';
import Offer from '../../components/Offer';
import ApiService from '../../services/api-service';
import { i18n } from '../../services/translation';
import CONFIG from '../../config/app';
import './OfferForClient.scss';

const OfferForClient = () => {
  const { accessToken, offerId } = useParams<{
    accessToken: string;
    offerId: string;
  }>();

  const [prevLanguage, setPrevLanguage] = useState(i18n.currentLang);
  const [offer, setOffer] = useState<OfferDetails>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const getOffer = async () => {
    await ApiService.callFetch(
      'GET',
      `offer/for-client/${offerId}/${accessToken}`,
      (offer: OfferDetails) => {
        setOffer(offer);
      }
    );
  };

  useEffect(() => {
    i18n.loaded = false;
    getOffer();

    return () => {
      dispatch(lang.changeLanguage(prevLanguage || CONFIG.DEFAULT_LANG));
    };
  }, []);

  useEffect(() => {
    if (offer) {
      if (i18n.currentLang !== offer.language) {
        dispatch(lang.changeLanguage(offer.language));
        checkI18nLoaded();
      } else {
        i18n.loaded = true;
        setLoading(false);
      }
    }
  }, [offer]);

  const checkI18nLoaded = () => {
    if (i18n.loaded && offer && prevLanguage) {
      setLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  if (loading) return <Spinner />;

  return (
    <Main>
      <div className="offer-container">
        <Container narrow>
          <div className="offer-wrapper">
            <Offer offer={offer} standalone getOffer={getOffer} />
          </div>
        </Container>
      </div>
    </Main>
  );
};

export default OfferForClient;
