import React from 'react';

import './FieldBox.scss';

interface Props {
  text: string;
  id?: number;
}

const FieldBox = ({text, id}: Props) => (
  <div className="field-box" key={id}>
    <p className="field-text">{text}</p>
  </div>
);

export default FieldBox;
