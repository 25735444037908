import { ApiErrorCode, ApiResult } from 'profilpol-types';
import { notifications, auth } from '../actions';
import SessionService from './session-service';
import { store, history } from '../App';

export default class ApiService {
  static url =
    typeof process.env.REACT_APP_API_URL === 'string'
      ? `${process.env.REACT_APP_API_URL.trim()}/`
      : 'http://localhost:3001/';

  static call = async (
    dispatch: any,
    method: string,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData?: any,
    sendAsMultipart?: boolean,
    disableFailureNotification?: boolean
  ) => {
    dispatch({ type: loadingAction, payload: true });

    const headers = sendAsMultipart
      ? {
          'Content-Type': 'multipart/form-data',
        }
      : {
          'Content-Type': 'application/json',
        };

    try {
      const response = await fetch(`${ApiService.url}${url}`, {
        method,
        headers: requestData && requestData instanceof FormData ? {} : headers,
        credentials: 'include',
        body:
          requestData && requestData instanceof FormData
            ? requestData
            : JSON.stringify(requestData),
      });

      const data = await response.json();
      if (ApiService.is403(data)) {
        // dispatch(notifications.errorNotification(ApiErrorCode.InsufficientPrivileges));
      } else {
        switch (data.result) {
          case ApiResult.ok:
            dispatch({ type: successAction, payload: data.payload });
            if (successCb) successCb(data.payload);
            dispatch({ type: loadingAction, payload: false });
            break;
          case ApiResult.error:
            if (data.error_code === ApiErrorCode.NoSuchObjectWithThisId) {
              history.push('/error');
            } else {
              dispatch({ type: failedAction, payload: data.result });
              if (failedCb) failedCb();
              if (disableFailureNotification !== true) {
                dispatch(notifications.errorNotification(data.error_code));
              }
              dispatch({ type: loadingAction, payload: false });
            }
            break;
          default:
            if (ApiService.is404(data)) {
              history.push('/error');
              if (failedCb) failedCb(data);
            } else if (ApiService.is401(data)) {
              // dispatch(auth.logout());
              dispatch(auth.clearUserSession());
              dispatch({ type: loadingAction, payload: false });
            } else if (ApiService.is502(data)) {
              history.push('/error');
            } else {
              dispatch({
                type: failedAction,
                payload: ApiErrorCode.OtherError,
              });
              dispatch(
                notifications.errorNotification(ApiErrorCode.OtherError)
              );
              dispatch({ type: loadingAction, payload: false });
            }
        }
      }
    } catch (error) {
      dispatch({ type: failedAction, payload: ApiErrorCode.OtherError });
      dispatch(notifications.errorNotification(ApiErrorCode.OtherError));
      // dispatch(auth.logout());
      dispatch({ type: loadingAction, payload: false });
    }
  };

  static callFetch = async (
    method: string,
    url: string,
    successCb?: any,
    failedCb?: any,
    requestData?: any
  ) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(`${ApiService.url}${url}`, {
        method,
        headers: requestData && requestData instanceof FormData ? {} : headers,
        credentials: 'include',
        body:
          requestData && requestData instanceof FormData
            ? requestData
            : JSON.stringify(requestData),
      });

      const data = await response.json();
      if (ApiService.is403(data)) {
        // store.dispatch(
        //   notifications.errorNotification(ApiErrorCode.InsufficientPrivileges)
        // );
      } else {
        switch (data.result) {
          case ApiResult.ok:
            if (successCb) successCb(data.payload);
            return data.payload;
          case ApiResult.error:
            if (failedCb) failedCb(data);
            store.dispatch(notifications.errorNotification(data.error_code));
            break;
          default:
            if (ApiService.is401(data)) {
              store.dispatch(auth.clearUserSession());
            } else {
              store.dispatch(notifications.errorNotification(data.error_code));
            }
        }
      }
    } catch (error) {
      store.dispatch(notifications.errorNotification(ApiErrorCode.OtherError));
    }
  };

  static loadForm = async (url: string) => {
    try {
      return JSON.parse(((await ApiService.callFetch('GET', url)) as any).form);
    } catch (e) {}
  };

  static loadFormWithData = async (url: string, body: any) => {
    try {
      return JSON.parse(
        ((await ApiService.callFetch(
          'POST',
          url,
          undefined,
          undefined,
          body
        )) as any).form
      );
    } catch (e) {}
  };

  static sendForm = async (
    url: string,
    values: any,
    method = 'POST',
    successCb?: any,
    failedCb?: any
  ): Promise<any | void> =>
    new Promise(async (resolve, reject) => {
      await ApiService.callFetch(
        method,
        url,
        (payload: any) => {
          if (successCb) successCb(payload);
          resolve(payload);
        },
        (data: any) => {
          if (data.form_errors) {
            resolve(data);
          } else {
            if (failedCb) failedCb(data);
            reject(data.result);
          }
        },
        values
      );
    });

  static is401 = (response: any) => response.statusCode === 401;
  static is403 = (response: any) => response.statusCode === 403;

  static is404 = (response: any) => response.statusCode === 404;

  static is502 = (response: any) => response.statusCode === 502;

  static post = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData: any,
    sendAsMultipart?: boolean,
    disableFailureNotification?: boolean
  ) => {
    if (sendAsMultipart) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        sendAsMultipart
      );
    } else if (disableFailureNotification) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        (sendAsMultipart = false),
        disableFailureNotification
      );
    } else if (disableFailureNotification && sendAsMultipart) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        sendAsMultipart,
        disableFailureNotification
      );
    } else {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData
      );
    }
  };

  static patch = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData?: any
  ) => {
    await ApiService.call(
      dispatch,
      'PATCH',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction,
      requestData
    );
  };

  static get = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING'
  ) => {
    await ApiService.call(
      dispatch,
      'GET',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction
    );
  };

  static delete = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING'
  ) => {
    await ApiService.call(
      dispatch,
      'DELETE',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction
    );
  };
}
