import React, { useEffect, useState } from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Alert, Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal, recipes } from '../../actions';
import { useDispatch } from 'react-redux';

const OfferAccepted = () => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.accepted" />
      </ModalHeader>

      {__('offer.accepted_description')}
      <ButtonsContainer>
        <Button
          text="application.ok"
          primary
          click={() => dispatch(modal.hideModal())}
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default OfferAccepted;
