import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Alert,
  SectionHeader,
  Input,
  Button,
} from '../../../components/Common';
import { FieldsGroup } from '../../../components/Layout';
import { formOnTranslateString } from '../../../utils/trans-form';
import CONFIG from '../../../config/app';
import './Options.scss';
import { flatObject } from '../../../utils/flat-object';

interface Props {
  updateOptions: (options: any[]) => void;
  initialOptions?: any[];
  errors?: {
    value?: any;
    name?: any;
    [key: string]: any;
    arrayIndex: number;
  };
}

const Options = ({ updateOptions, errors, initialOptions }: Props) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (initialOptions) {
      const flatten = initialOptions.map((opt) => flatObject(opt));
      setOptions(flatten);
    }
  }, []);

  useEffect(() => {
    updateOptions(options);
  }, [options]);

  const addOption = () => {
    setOptions((options) => [
      ...options,
      {
        value: '',
      },
    ]);
  };

  const removeOption = (indexToRemove: number) => {
    setOptions((option) =>
      option.filter((opt, index) => index !== indexToRemove)
    );
  };

  const setValue = (index: number, field: string, value: string) => {
    setOptions((options) => {
      const newOptions = [...options];
      newOptions[index][field] = value;
      return newOptions;
    });
  };

  const getErrors = (field: string, index: number) => {
    return errors && errors.arrayIndex === index
      ? {
          touched: errors[field] && errors[field].length > 0,
          error:
            errors[field] && errors[field].length
              ? formOnTranslateString(
                  errors[field][0].messageWithPlaceholders,
                  errors[field][0].params
                )
              : null,
        }
      : {};
  };
  return (
    <>
      <SectionHeader
        title="recipe.field_options"
        buttonName="recipe.add_option"
        click={addOption}
      />
      {options.length === 0 ? (
        <Alert type="notice" text="recipe.no_field_options" />
      ) : (
        options.map((sub, index) => (
          <div className="single-option">
            <div className="single-option-header">
              <Input
                type="text"
                label="application.option_value"
                placeholder="application.option_value_placeholder"
                input={{
                  value: options[index].value,
                  onChange: (e: ChangeEvent<any>) => {
                    setValue(index, `value`, e.currentTarget.value);
                  },
                }}
                meta={getErrors(`value`, index)}
              />
              <Button
                faIcon={faTrash}
                danger
                small
                leftIcon
                className="option-remove"
                click={() => removeOption(index)}
              />
            </div>
            <FieldsGroup key={index}>
              {CONFIG.LANGUAGES.map((language) => {
                return (
                  <Input
                    type="text"
                    required={language === CONFIG.DEFAULT_LANG}
                    label={`application.name.${language}`}
                    placeholder={`application.name_placeholder.${language}`}
                    input={{
                      value: options[index][`name.${language}`],
                      onChange: (e: ChangeEvent<any>) => {
                        setValue(
                          index,
                          `name.${language}`,
                          e.currentTarget.value
                        );
                      },
                    }}
                    meta={getErrors(`name.${language}`, index)}
                  />
                );
              })}
            </FieldsGroup>
          </div>
        ))
      )}
    </>
  );
};

export default Options;
