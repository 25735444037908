import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  FormContainer,
  ButtonsContainer,
} from '../../../../components/Layout';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  Spinner,
  Button,
  Header,
  InputSelect,
  FieldsMapper,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import {
  CreateProductReq,
  CreateProfileReq,
  ProductForm,
  ServiceDetails,
} from 'profilpol-types';
import { __ } from '../../../../services/translation';
import { ButtonType } from '../../../../components/Common/Button';
import { successNotification } from '../../../../actions/notifications';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { history } from '../../../../App';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { pathToObject } from '../../../../utils/path-to-object';

const New = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Header header="profiles.new" />
      <Button
        leftIcon
        link
        faIcon={faChevronLeft}
        text={'application.back'}
        to="/panel/profiles"
      />
      <FormContainer>
        <ItfApiForm
          formId="productForm"
          schemaMayBeDynamic={false}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() => ApiService.loadForm(`product/form/add-profile`)}
          sendFetcher={(formId: string, values: ProductForm) => {
            const newProduct = {
              ...values,
            };

            const parsed = pathToObject(newProduct);

            const product = new FormData();
            for (const key in parsed) {
              if (key === 'image' && values.image) {
                product.append('image', parsed.image, parsed.image.name);
              } else product.append(key, JSON.stringify(parsed[key]));
            }

            return ApiService.sendForm(
              `product/profile`,
              product,
              'POST',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <>
              <ButtonsContainer>
                <Button
                  disabled={loading}
                  big
                  type={ButtonType.Submit}
                  primary
                  text={'application.save'}
                />
              </ButtonsContainer>
            </>
          )}
          onRenderFullField={FieldsMapper}
          onSavedSuccessfully={(data: ServiceDetails) => {
            dispatch(successNotification('profile.created'));
            history.push('/panel/profiles');
          }}
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{
            ReactCreatableSelect: InputSelect,
            ReactSelect: InputSelect,
          }}
        />
      </FormContainer>
    </Container>
  );
};

export default New;
