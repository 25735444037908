export enum SettingType {
  EUR_PLN_RATIO,
}

export interface SettingEntity {
  type: SettingType;
  value: number;
}

export interface SettingDetails extends SettingEntity {}
export interface CreateSettingReq {
  value: number;
}
export interface UpdateSettingReq extends CreateSettingReq {}
