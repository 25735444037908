import { ClientType } from '../client';
import { Currency } from '../currency';
import { I18nEntry } from '../i18n';
import { PaymentTypeEntity } from '../payment-type';
import {
  OfferComponent,
  OfferComponentDetails,
  OfferCreatorComponent,
  OfferDescription,
  OfferProduct,
  OfferProductDetails,
  OfferSection,
  OfferService,
  OfferServiceDetails,
  RecipeDetails,
  RecipeEntity,
  RecipeProductDetails,
  RecipeServiceDetails,
} from '../recipe';
import { UserEntity } from '../user';

export interface OfferEntity {
  accessToken: string;
  validToDate: Date;
  id: string;
  status: OfferStatus;
  currency: Currency;
  value: number;
  createDate: Date;
  client: OfferClient;
  clientId: string;
  owner: UserEntity;
  recipeName: I18nEntry;
  recipeId: string;
  recipeVersionUuid: string;
  sections: OfferSection[];
  components: OfferComponent[];
  description: OfferDescription[];
  additionalProducts: OfferProduct[];
  additionalServices: OfferService[];
  additionalInfo: string;
  language: string;
  reason: string;
  eurPlnRatio: number;
  name: string | null;
  parsedDescription: string | null;
  recipe: RecipeEntity;
}

export interface OfferForm {
  name: string;
  parsedDescription: string;
  currency: string;
  paymentTypeId: string;
  validTo: string;
  additionalInfo: string;
  language: string;
}

export interface ShareOfferReq {
  validTo: Date;
}

export interface OfferNameReq {
  name: string;
}

export interface ChangeOfferClientReq {
  clientId: string;
}

export interface CopyOfferReq {
  clientId: string;
  validTo: Date;
}

export interface ShareOfferResponse {
  accessToken: string;
  offerId: string;
  validToDate: Date;
}

export interface OfferDetails {
  validToDate: Date;
  id: string;
  status: OfferStatus;
  currency: Currency;
  value: number;
  taxRate: number;
  weight: number;
  createDate: Date;
  client: OfferClient;
  clientId: string;
  creatorId: string;
  creatorName: string;
  creatorPhone: string;
  creatorEmail: string;
  recipeName: I18nEntry;
  recipeVersionUuid: string;
  recipeId: string;
  sections: OfferSection[];
  components: OfferComponentDetails[];
  description: OfferDescription[];
  additionalProducts: OfferProductDetails[];
  additionalServices: OfferServiceDetails[];
  additionalInfo: string;
  language: string;
  accessToken: string;
  reason: string;
  eurPlnRatio: number;
  parsedDescription: string;
  name: string;
}

export interface OfferForEdit extends OfferDetails {
  componentsCreator: OfferCreatorComponent[];
  additionalProductsCreator: RecipeProductDetails[];
  additionalServicesCreator: RecipeServiceDetails[];
}

export interface OfferForClient {
  validToDate: Date;
  id: string;
  status: OfferStatus;
  currency: Currency;
  value: number;
  taxRate: number;
  weight: number;
  createDate: Date;
  client: OfferClient;
  clientId: string;
  components: OfferComponentDetails[];
  description: OfferDescription[];
  additionalProducts: OfferProductDetails[];
  additionalServices: OfferServiceDetails[];
  additionalInfo: string;
  accessToken: string;
  recipeName: I18nEntry;
  reason: string;
  language: string;
  parsedDescription: string;
}

export interface CreateOfferReq {
  name: string;
  parsedDescription: string;
  components: OfferComponent[];
  sections: OfferSection[];
  additionalProducts: OfferProduct[];
  additionalServices: OfferService[];
  description: OfferDescription[];
  client: OfferClient;
  additionalInfo: string;
  language: string;
  currency: Currency;
  paymentTypeId: string;
  recipeId: string;
  validTo: Date;
  offerId?: string; // offer that is currently edited - used for prices verification
}

export interface OfferListDetails {
  id: string;
  status: OfferStatus;
  value: number;
  taxRate: number;
  createDate: Date;
  language: string;
  currency: Currency;
  client: OfferClient;
  name: string;
}

export interface OfferClient {
  id: string;
  internalId: string;
  type: ClientType;
  paymentType: PaymentTypeEntity;
  companyName: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  companyVatNumber: string;
  defaultMargin: number;
  currency: Currency;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
}

export enum OfferStatus {
  NEW,
  ACCEPTED,
  CANCELLED,
  FINISHED,
  DRAFT,
  NEEDS_VERIFICATION
}

export enum ItemType {
  PRODUCT,
  SERVICE,
}

export interface ChangeOfferStatusReq {
  newStatus: OfferStatus;
  reason?: string;
}


export interface RefreshPricesReq {
  components: OfferCreatorComponent[],
  additionalProducts: RecipeProductDetails[],
  additionalServices: RecipeServiceDetails[];
  recipeId: string;
}


export interface RefreshedPricesRes {
  components: OfferCreatorComponent[],
  additionalProducts: RecipeProductDetails[],
  additionalServices: RecipeServiceDetails[];
}