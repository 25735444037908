import React from 'react';
import {
  FieldType,
  TableResponse,
  TableSchema,
  TableSchemaField,
} from 'profilpol-tables';
import { Button, Alert, Pagination } from '../../Common';
import { formatPrice } from '../../../utils/format-price';
import './Table.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { __ } from '../../../services/translation';
import clsx from 'clsx';

interface Props {
  scheme: TableSchema;
  data: TableResponse<any>;
  secondary?: boolean;
  initialPage?: number;
  disableNumeration?: boolean;
  onPageChange?: (page: number) => void;
  sort?: {
    sortBy: string;
    sortDirBack: boolean;
  };
  onSortChange?: (sortBy: string, sortDirBack: boolean) => void;
  isRowInactive?: (row: any) => boolean;
  isRowDeleted?: (row: any) => boolean;
}

const formatValue = (value: any, field: TableSchemaField, allValues: any) => {
  if (field.field.includes('.')) {
    const parts = field.field.split('.');
    if (!allValues[parts[0]]) return;
    value = allValues[parts[0]][parts[1]];
  }

  if (typeof field.customValue === 'function') {
    value = field.customValue(allValues, field);
  }

  switch (field.type) {
    case FieldType.Custom: {
      return field.customRender && field.customRender(value, field, allValues);
    }

    case FieldType.Url: {
      return value === null ? (
        '-'
      ) : (
        <a href={value} target="_blank" rel="noreferrer noopener">
          {value}
        </a>
      );
    }

    case FieldType.Email: {
      return value === null ? (
        '-'
      ) : (
        <a href={`mailto:${value}`} target="_blank" rel="noreferrer noopener">
          {value}
        </a>
      );
    }
    case FieldType.Tel: {
      return value === null ? (
        '-'
      ) : (
        <a href={`tel:${value}`} target="_blank" rel="noreferrer noopener">
          {value}
        </a>
      );
    }
    case FieldType.Img: {
      return value !== null && <img src={value} alt={field.name} />;
    }
    case FieldType.Date: {
      return !value
        ? __('application.never')
        : new Date(value).toLocaleString();
    }
    case FieldType.Boolean: {
      return value === null
        ? __('application.default')
        : value
        ? __('application.no')
        : __('application.yes');
    }
    case FieldType.Price: {
      return value === null ? '-' : formatPrice(value);
    }
    case FieldType.Percent: {
      return value === null ? '-' : `${value}%`;
    }
    case FieldType.Text:
    default: {
      return !value ? '-' : value;
    }
  }
};

function genSortItems(
  header: TableSchemaField,
  sort: { sortBy: string; sortDirBack: boolean },
  onSortChange: (sortBy: string, sortDirBack: boolean) => void
) {
  if (header.sortable !== true) return null;

  const handleSortChange = (dirBack: boolean) => {
    onSortChange(header.field, dirBack);
  };

  const arrow1 = (
    <a
      onClick={() => handleSortChange(false)}
      className={`sort sort-up ${
        sort.sortBy === header.field && sort.sortDirBack !== true
          ? 'active'
          : ''
      }`}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </a>
  );
  const arrow2 = (
    <a
      onClick={() => handleSortChange(true)}
      className={`sort sort-down ${
        sort.sortBy === header.field && sort.sortDirBack === true
          ? 'active'
          : ''
      }`}
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </a>
  );

  return (
    <>
      {arrow1}
      {arrow2}
    </>
  );
}

class Table extends React.Component<Props> {
  state = {
    toggled: -1,
  };

  private toggleRow = (index: number) => {
    const { toggled } = this.state;
    if (index === toggled) {
      this.setState({ toggled: -1 });
    } else this.setState({ toggled: index });
  };

  render() {
    const {
      data,
      sort,
      onSortChange,
      onPageChange,
      scheme,
      disableNumeration,
      initialPage,
      secondary,
      isRowInactive,
      isRowDeleted,
    } = this.props;
    const { toggled } = this.state;
    const desktop = window.matchMedia('(min-width: 920px)').matches;
    let content = null;
    if (!data || data.items.length === 0)
      return <Alert simple type="error" text="application.no_data" />;

    if (desktop) {
      const headers = scheme.fields.map((header: TableSchemaField) => (
        <th className={`${header.mobile ? 'mobile' : ''}`} key={header.field}>
          {__(header.name)}
          {sort && onSortChange && genSortItems(header, sort, onSortChange)}
        </th>
      ));
      const rows = data.items.map((row: any, rowIndex: number) => (
        <tr
          key={rowIndex}
          className={clsx({
            inactive:
              typeof isRowInactive === 'function' ? isRowInactive(row) : false,
            deleted:
              typeof isRowDeleted === 'function' ? isRowDeleted(row) : false,
          })}
        >
          {!disableNumeration && (
            <td>
              {rowIndex +
                1 +
                (initialPage ? (initialPage - 1) * scheme.countPerPage : 0)}
            </td>
          )}
          {scheme.fields.map((header: TableSchemaField, index: number) => (
            <td
              className={`${header.mobile ? 'mobile' : ''}`}
              key={`${header.field}_${rowIndex}`}
            >
              {header.buttons
                ? header.buttons.map((button: any, index: number) => (
                    <Button
                      small
                      key={index}
                      primary={button.type === 'primary'}
                      secondary={button.type === 'secondary'}
                      danger={button.type === 'danger'}
                      success={button.type === 'success'}
                      to={button.to ? `${button.to}${row.id}` : ''}
                      click={button.click ? () => button.click(row) : null}
                      text={button.name}
                    />
                  ))
                : formatValue(row[header.field], header, row)}
            </td>
          ))}
        </tr>
      ));

      content = (
        <div className="table-container">
          <table className={clsx('table', { secondary })}>
            <thead>
              <tr>
                {!disableNumeration && (
                  <th>{__('application.table_number')}</th>
                )}
                {headers}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      );
    } else {
      content = (
        <div className="list">
          {data.items.map((row: any, rowIndex: number) => (
            <div
              className={`list-element ${
                toggled === rowIndex ? 'toggled' : ''
              }`}
              onClick={() => this.toggleRow(rowIndex)}
              key={rowIndex}
            >
              {scheme.fields.map((header: TableSchemaField, index: number) => (
                <div className={`list-row mobile`} key={header.name}>
                  {header.buttons ? (
                    <div className="list-buttons">
                      {header.buttons.map((button: any, index: number) => (
                        <Button
                          small
                          key={row.id}
                          primary={button.type === 'primary'}
                          danger={button.type === 'danger'}
                          to={button.to ? `${button.to}${row.id}` : ''}
                          click={button.click ? () => button.click(row) : null}
                          text={button.name}
                        />
                      ))}
                    </div>
                  ) : (
                    <>
                      <strong>{__(header.name)}</strong>
                      <span className="list-value">
                        {formatValue(row[header.field], header, row)}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }

    return (
      <>
        {content}
        <Pagination
          initialPage={initialPage || 1}
          pageCount={Math.ceil(data.countTotal / scheme.countPerPage)}
          onPageChange={(page) => onPageChange && onPageChange(page)}
        />
      </>
    );
  }
}

export default Table;
