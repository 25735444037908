import React from 'react';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';
import { useDispatch } from 'react-redux';

interface Props {
  title: string;
  content: React.ReactNode;
}

const ItemDetails = ({ title, content }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle rawTitle={title} />
      </ModalHeader>

      {content}
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => dispatch(modal.hideModal())}
          small
          text={'application.ok'}
          primary
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ItemDetails;
