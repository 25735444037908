import { SingleTab } from './tabs';

export enum CustomSearchType {
  MODIFY_URL_ADDRESS,
  ADD_SEARCH_PARAMETER,
}

export interface CustomSearchTab {
  default: string | number | null;
  field: string;
  options: SingleTab[];
  type: CustomSearchType;
}
