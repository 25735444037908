import React from 'react';
import { __ } from '../../../services/translation';
import { Wrapper } from '../../Layout';

import './OfferFooter.scss';

const OfferFooter = () => (
  <Wrapper className="offer-footer">
    <Wrapper column>
      <span className="footer-company">{__('offer.company')}</span>
      <span>{__('offer.address_street')}</span>
      <span>{__('offer.address_city')}</span>
    </Wrapper>
    <Wrapper column>
      <span>
        <a href={`tel:${__('offer.phone_1')}`}>{__('offer.phone_1')}</a>
      </span>

      <span>
        <a href={`tel:${__('offer.phone_2')}`}>{__('offer.phone_2')}</a>
      </span>
    </Wrapper>
    <p>
      <a href={`mailto:${__('offer.mail')}`}>{__('offer.mail')}</a>
    </p>
  </Wrapper>
);

export default OfferFooter;
