import React from 'react';
import { Button, Icon, Table } from '../../Common';
import { __ } from '../../../services/translation';
import {
  formatPrice,
  formatPriceWithoutConvertion,
} from '../../../utils/format-price';

import './OfferSummary.scss';
import { OfferDetails, UserRole } from 'profilpol-types';
import { formatWeight } from '../../../utils/format-weight';
import OfferSubtitle from '../OfferSubtitle';
import { TableSchema, FieldType } from 'profilpol-tables';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';

interface Props {
  offer: OfferDetails;
  noIssuer?: boolean;
}

const OfferSummary = ({ offer, noIssuer }: Props) => {
  const { current } = useSelector((state: ApplicationState) => state.lang);

  const scheme: TableSchema = {
    countPerPage: 99999,
    fields: [
      {
        field: 'value',
        name: 'offer.net',
        type: FieldType.Text,
        customValue: (item: OfferDetails) => {
          return formatPriceWithoutConvertion(item.value, item.currency);
        },
      },
      {
        field: 'gross',
        name: 'offer.gross',
        type: FieldType.Text,
        customValue: (item: OfferDetails) => {
          return formatPriceWithoutConvertion(
            item.value * item.taxRate + item.value,
            item.currency
          );
        },
      },
      {
        field: 'taxRate',
        name: 'offer.vat_value',
        type: FieldType.Text,
        customValue: (item: OfferDetails) => {
          return formatPriceWithoutConvertion(
            item.value * item.taxRate,
            item.currency
          );
        },
      },
      {
        field: 'weight',
        name: 'offer.weight',
        type: FieldType.Text,
        customValue: (item: OfferDetails) => {
          return formatWeight(item.weight);
        },
      },
    ],
  };

  const tableData = [offer];

  return (
    <section className="offer-summary">
      <OfferSubtitle title="offer.summary" noBorder />
      <Table
        disableNumeration
        secondary
        data={{
          countTotal: 1,
          items: tableData,
        }}
        scheme={scheme}
      />

      <div className="offer-summary-section">
        <OfferSubtitle title="offer.payment_method" />
        <div className="offer-payment-method">
          <Icon small faIcon={faDotCircle} className="offer-payment-icon" />{' '}
          {offer.client.paymentType.name[current]}
        </div>
        {offer.client.paymentType.clause[current]}
      </div>

      {offer.additionalInfo && (
        <div className="offer-summary-section">
          <OfferSubtitle title="offer.additional_info" />
          <p>{offer.additionalInfo}</p>
        </div>
      )}

      {!noIssuer && offer.creatorId && (
        <div className="offer-summary-section">
          <OfferSubtitle title="offer.issuer_info" />
          <div>{offer.creatorName}</div>
          <div>
            <a href={`mailto:${offer.creatorEmail}`}>{offer.creatorEmail}</a>
          </div>
          <div>
            <a href={`tel:${offer.creatorPhone}`}>{offer.creatorPhone}</a>
          </div>
        </div>
      )}
    </section>
  );
};

export default OfferSummary;
