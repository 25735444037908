import React from 'react';
import ApiService from '../../../services/api-service';
import './OfferClientDescription.scss';

interface Props {
  description: string;
}

const OfferClientDescription = ({ description }: Props) => {
  if (!description) return null;

  const splittedDescription = description.split('**');
  const clearedDescription = splittedDescription.map((el) => {
    let newVal = el.trim();

    newVal = el[0] === '.' ? el.substring(1) : el;

    return newVal.trim();
  });

  return (
    <>
      <ul className="offer-client-description">
        {clearedDescription.map((description) => {
          const isImage = description.includes('.jpeg');
          return (
            <li>
              {isImage ? (
                <div className="offer-client-description-image">
                  <a
                    href={`${ApiService.url}recipe/description/image-file/${description}`}
                    target="_blank"
                  >
                    <img
                      src={`${ApiService.url}recipe/description/image-file/${description}`}
                    />
                  </a>
                </div>
              ) : (
                description
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default OfferClientDescription;
