import clsx from "clsx";
import React from "react";

import "./LogoContainer.scss";

interface Props {
  children: React.ReactNode;
  hidden?: boolean;
  noPadding?: boolean;
  toRight?: boolean;
}

const LogoContainer = ({ children, hidden, noPadding, toRight }: Props) => (
  <div className={clsx("logo-container", { hidden: hidden, "no-padding": noPadding, "to-right": toRight })}>{children}</div>
);

export default LogoContainer;
