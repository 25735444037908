import React from 'react';
import {
  Button,
  Header,
  ApiList,
  Subtitle,
} from '../../../../components/Common';
import { Container, Wrapper } from '../../../../components/Layout';
import { __ } from '../../../../services/translation';

// import './List.scss';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { paymentTypesTableSchema } from 'profilpol-tables';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import Item from './Item';

const List = () => {
  const scheme = new InteractiveTableSchema(paymentTypesTableSchema);
  const total = useSelector(
    (state: ApplicationState) => state.apiList.countTotal
  );
  return (
    <Container>
      <Wrapper className="recipes-header-wrapper">
        <Header header="payment_type.title" />
        <Button
          secondary
          text={'payment_type.button_text'}
          to="/panel/payment-types/new"
        />
      </Wrapper>
      <Wrapper>
        <Subtitle>
          {__('payment_type.subtitle')}: <strong>{total}</strong>
        </Subtitle>
      </Wrapper>
      <ApiList
        scheme={scheme}
        apiEndpointSubUrl="payment-type/list"
        component={Item}
      />
    </Container>
  );
};

export default List;
