import ApiService from "../services/api-service";

export const getUserDetails = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    "FETCH_USER_DETAILS_SUCCESS",
    "FETCH_USER_DETAILS_FAILED",
    null,
    null,
    "user/details",
    "LOADING_FETCH_USER_DETAILS"
  );
};
