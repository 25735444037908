import { faClone, faPenAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  RecipeFieldDetails,
  RecipeInfoDetails,
  RecipeSectionDetails,
} from 'profilpol-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RecipeTab } from '..';
import { FieldBox } from '../..';
import { apiList, modal, notifications } from '../../../../actions';
import { Confirmation, RecipeSection } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../services/translation';
import { getCurrentFromI18nEntry } from '../../../../utils/language';
import { Wrapper } from '../../../Layout';

import './SectionTab.scss';

interface Props {
  recipeId: string;
  item: RecipeSectionDetails;
}

const SectionTab = ({ item, recipeId }: Props) => {
  const dispatch = useDispatch();

  const { current } = useSelector((state: ApplicationState) => state.lang);

  const confirmDelete = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="recipe.confirm_section_delete"
          confirmCallback={() => deleteSection()}
        />
      )
    );
  };

  const deleteSection = async () => {
    await ApiService.callFetch('DELETE', `recipe/section/${item.id}`, () => {
      dispatch(notifications.successNotification('recipe.section_deleted'));
      dispatch(apiList.refreshApiList());
      dispatch(modal.hideModal());
    });
  };

  const editSection = (item: RecipeSectionDetails) => {
    dispatch(
      modal.showModal(<RecipeSection recipeId={recipeId} id={item.id} />)
    );
  };

  const buttons = [
    {
      text: 'application.edit',
      icon: faPenAlt,
      click: editSection,
      primary: true,
    },
    {
      text: 'application.delete',
      icon: faTrash,
      click: confirmDelete,
      danger: true,
    },
  ];

  return (
    <Wrapper className="section-tab">
      <RecipeTab
        item={item}
        buttons={buttons}
        icon={faClone}
        noMargin
        name={item.name}
        bold
      />
      <div className="content-wrapper">
        {item.inputs && item.inputs.length > 0 && (
          <div className="data-wrapper input-data">
            <p className="info-header">{__('recipe.inputs')}</p>
            <div className="data">
              {[...item.inputs]
                .sort((a, b) => a.position - b.position)
                .map((data: RecipeFieldDetails) => (
                  <FieldBox
                    text={getCurrentFromI18nEntry(data.title, current)}
                  />
                ))}
            </div>
          </div>
        )}
        {item.infos && item.infos.length > 0 && (
          <div className="data-wrapper">
            <p className="info-header">{__('recipe.infos')}</p>
            <div className="data">
              {[...item.infos]
                .sort((a, b) => a.position - b.position)
                .map((data: RecipeInfoDetails) => (
                  <FieldBox
                    text={getCurrentFromI18nEntry(data.title, current)}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default SectionTab;
