import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PaymentTypeDetails } from 'profilpol-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { modal, notifications, apiList } from '../../../../../actions';
import { DetailLine } from '../../../../../components/Common';
import { DetailsTab } from '../../../../../components/Common/Tabs';
import { Confirmation } from '../../../../../modals';
import ApiService from '../../../../../services/api-service';
import { __ } from '../../../../../services/translation';
import { ListButton } from '../../../../../types/list-button';

interface Props {
  item: PaymentTypeDetails;
}

const Item = ({ item }: Props) => {
  const dispatch = useDispatch();

  const confirmDelete = (paymentTypeId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="payment_type.confirm_delete"
          confirmCallback={() => deletePaymentType(paymentTypeId)}
        />
      )
    );
  };

  const deletePaymentType = async (paymentTypeId: string) => {
    await ApiService.callFetch(
      'DELETE',
      `payment-type/${paymentTypeId}`,
      () => {
        dispatch(notifications.successNotification('payment_type.deleted'));
        dispatch(apiList.refreshApiList());
        dispatch(modal.hideModal());
      }
    );
  };

  const buttons: ListButton[] = [
    {
      text: 'application.edit',
      icon: faPencilAlt,
      to: `/panel/payment-types/edit/${item.id}`,
    },
    {
      text: 'application.delete',
      icon: faTrash,
      click: () => confirmDelete(item.id),
      danger: true,
    },
  ];

  const excerpt = (
    <>
      <div>{item.name}</div>
    </>
  );

  const details = (
    <div>
      <DetailLine title="payment_type.id" data={item.id} />
      <DetailLine title="payment_type.clause" breakLine data={item.clause} />
    </div>
  );

  return <DetailsTab excerpt={excerpt} details={details} buttons={buttons} />;
};

export default Item;
