import RecipeTab from './RecipeTab';
import SectionTab from './SectionTab';
import DetailsTab from './DetailsTab';
import NavigationTab from './NavigationTab';
import ProductTab from './ProductTab';
import LineTab from './LineTab';
import ModalTab from './ModalTab';

export {
  RecipeTab,
  SectionTab,
  DetailsTab,
  NavigationTab,
  ProductTab,
  LineTab,
  ModalTab
}