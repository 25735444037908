import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Edit from './Edit';
import List from './List';
import New from './New';

const PaymentTypes = () => (
  <Switch>
    <Route
      exact
      path="/panel/payment-types"
      render={() => <Redirect to="/panel/payment-types/list" />}
    />
    <Route path="/panel/payment-types/list" component={List} />
    <Route path="/panel/payment-types/new" component={New} />
    <Route path="/panel/payment-types/edit/:paymentTypeId" component={Edit} />
  </Switch>
);

export default PaymentTypes;
