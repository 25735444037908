import React from 'react';
import { __ } from '../../../services/translation';
import { FieldsMapper } from '../../../components/Common';
import Options from '../Options';
import { RecipeFieldType } from 'profilpol-types';

const RecipeFieldFieldsMapper = (
  field: any,
  errors: any,
  fieldHtmlId: any,
  currentValue: any,
  onChange: any,
  onFocus: any,
  onBlur: any,
  type: RecipeFieldType,
  recipeId?: string
) => {
  if (field.id === 'options') {
    if ([RecipeFieldType.SELECT, RecipeFieldType.RADIOBUTTONS].includes(type))
      return (
        <Options
          initialOptions={currentValue}
          updateOptions={onChange}
          errors={errors ? errors[0] : {}}
        />
      );
    return <></>;
  } else
    return FieldsMapper(
      field,
      errors,
      fieldHtmlId,
      currentValue,
      onChange,
      onFocus,
      onBlur,
      { recipeId }
    );
};

export default RecipeFieldFieldsMapper;
