import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
  Block,
  BlockType,
  RecipeFieldDetails,
  RecipeInfoDetails,
  SelectOption,
} from 'profilpol-types';
import React, { useEffect, useState } from 'react';
import uuidv4 from 'uuidv4';
import ApiService from '../../../services/api-service';
import { FullScreenService } from '../../../services/full-screen-service';
import { __ } from '../../../services/translation';
import Button from '../Button';
import Spinner from '../Spinner';
import FieldOptions from './FieldOptions';
import SingleBlock from './SingleBlock';
import './GraphEditor.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { getCurrentFromI18nEntry } from '../../../utils/language';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

interface Props {
  recipeId: string;
  label: string;
  onChange: (block: any) => void;
  initialValue: any;
  onlyValues?: boolean;
}

const GraphEditor = ({
  label,
  initialValue,
  recipeId,
  onChange,
  onlyValues,
}: Props) => {
  const [blocks, setBlocks] = useState<Block[]>(initialValue);
  const [activeBlock, setActiveBlock] = useState<Block | null>(null);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [fields, setFields] = useState<RecipeFieldDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [fieldSelectorVisible, showFieldSelector] = useState(false);
  const isModalVisible = useSelector(
    (state: ApplicationState) => state.modal.isVisible
  );

  const { current } = useSelector((state: ApplicationState) => state.lang);

  let graphRef: any;

  const getData = async () => {
    await ApiService.callFetch(
      'GET',
      `recipe/fields-list-raw/${recipeId}`,
      (data: RecipeFieldDetails[]) => {
        setFields(data);
        setOptions((options) => [
          ...options,
          ...data.map((f) => ({
            name: `${getCurrentFromI18nEntry(f.title, current)} ${__(
              'blocks.field'
            )} `,
            value: f.id,
          })),
        ]);
      }
    );
    await ApiService.callFetch(
      'GET',
      `recipe/infos-list-raw/${recipeId}`,
      (data: RecipeInfoDetails[]) => {
        setOptions((options) => [
          ...options,
          ...data.map((f) => ({
            name: `${getCurrentFromI18nEntry(f.title, current)} ${__(
              'blocks.info'
            )} `,
            value: f.id,
          })),
        ]);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getData();
    setBlocks(initialValue);
  }, []);

  useEffect(() => {
    onChange(blocks);
  }, [blocks]);

  const findAndAdd = (blocks: Block[], newBlock: Block) => {
    if (!activeBlock) return blocks;
    blocks.forEach((block) => {
      if (block.uuid === activeBlock.uuid) {
        if (!block.blocks) block.blocks = [];
        block.blocks.push(newBlock);
      } else if (block.blocks) {
        return findAndAdd(block.blocks, newBlock);
      }
    });
    return blocks;
  };

  const findAndDelete = (blocks: Block[], toDelete: Block): Block[] => {
    if (!toDelete) return blocks;
    return blocks
      .map((block: Block) => {
        if (block.uuid === toDelete.uuid) {
          return null;
        } else if (block.blocks) {
          return { ...block, blocks: findAndDelete(block.blocks, toDelete) };
        } else {
          return block;
        }
      })
      .filter((b) => b) as Block[];
  };

  const findAndUpdate = (blocks: Block[], toUpdate: Block): Block[] => {
    if (!toUpdate) return blocks;
    return blocks
      .map((block: Block) => {
        if (block.uuid === toUpdate.uuid) {
          return toUpdate;
        } else if (block.blocks) {
          return { ...block, blocks: findAndUpdate(block.blocks, toUpdate) };
        } else {
          return block;
        }
      })
      .filter((b) => b) as Block[];
  };

  const updateBlock = (toUpdate: Block) => {
    const updatedBlocks = findAndUpdate([...blocks], toUpdate);
    setBlocks(updatedBlocks);
  };

  const appendBlock = (type: BlockType, value?: string | number) => {
    const newBlock: Block = { type, uuid: uuidv4(), value: value };
    if (type === BlockType.IF) {
      newBlock.blocks = [
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
      ];
    }
    if (type === BlockType.OR) {
      newBlock.blocks = [
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
      ];
    }
    if (type === BlockType.AND) {
      newBlock.blocks = [
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
      ];
    }
    if (
      [
        BlockType.ROUND,
        BlockType.ROUND_DOWN,
        BlockType.ROUND_UP,
        BlockType.NOT,
      ].includes(type)
    ) {
      newBlock.blocks = [
        {
          type: BlockType.GROUP,
          denyDelete: true,
          uuid: uuidv4(),
        },
      ];
    }
    putBlock(newBlock);
  };

  const putBlock = (block: Block) => {
    if (!activeBlock) {
      setBlocks((blocks) => [...blocks, block]);
    } else {
      const updatedBlocks = findAndAdd([...blocks], block);
      setBlocks(updatedBlocks);
    }
  };

  const deleteBlock = (toDelete: Block) => {
    const updatedBlocks = findAndDelete([...blocks], toDelete);
    setBlocks(updatedBlocks);
  };

  const replaceBlock = (newBlock: Block, oldBlock: Block, position: number) => {
    const updatedBlocks = findAndDelete([...blocks], oldBlock);
    if (!activeBlock) {
      if (position === -1) setBlocks((blocks) => [...updatedBlocks, newBlock]);
      else if (position === 0)
        setBlocks((blocks) => [newBlock, ...updatedBlocks]);
      else {
        setBlocks((blocks) => [
          ...updatedBlocks.slice(0, position),
          newBlock,
          ...updatedBlocks.slice(position),
        ]);
      }
    } else {
      const newBlocks = findAndAdd([...updatedBlocks], newBlock);
      setBlocks(newBlocks);
    }
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      if (isModalVisible) {
        disableBodyScroll(document.querySelector('.modal-content')!);
      }
      FullScreenService.closeFullscreen();
      setFullscreen(false);
    } else {
      if (isModalVisible) {
        clearAllBodyScrollLocks();
      }
      FullScreenService.launchIntoFullscreen(graphRef);
      setFullscreen(true);
    }
  };

  const setRef = (ref: any) => {
    graphRef = ref;
  };

  const [dragged, setDragged] = useState<Block>();

  const startDrag = (block: Block) => {
    setDragged(block);
  };

  const endDrag = (e: any) => {
    let position = -1;
    const active = graphRef.querySelector('.active-dropzone');
    if (active) {
      const children = active.querySelectorAll('.single-block');
      if (children) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          const boundingBox = child.getBoundingClientRect();
          if (e.clientX < boundingBox.left) {
            position = i;
            break;
          }
        }
      }
    }
    if (dragged) {
      const copy = {
        ...dragged,
        uuid: uuidv4(),
      };
      replaceBlock(copy, dragged, position);
      setDragged(undefined);
    }
  };

  if (loading) return <Spinner />;

  return (
    <div
      className={clsx('graph-editor', {
        'full-screen': isFullscreen,
      })}
      ref={setRef}
    >
      <div className="graph-header">
        <span className="graph-label">{__(label)}</span>
        <Button
          primary
          extraSmall
          faIcon={faSquareFull}
          leftIcon
          text={
            isFullscreen
              ? 'application.close_full_screen'
              : 'application.full_screen'
          }
          click={toggleFullScreen}
        />
      </div>
      <div
        className={clsx('graph-area', { 'active-dropzone': !activeBlock })}
        onClick={() => setActiveBlock(null)}
        onDragEnd={endDrag}
        onDragOver={(e) => {
          setActiveBlock(null);
          // e.stopPropagation();
        }}
      >
        {blocks.map((block, index) => (
          <SingleBlock
            key={block.uuid}
            options={options}
            active={activeBlock}
            block={block}
            onClick={setActiveBlock}
            deleteBlock={deleteBlock}
            updateBlock={updateBlock}
            endDrag={endDrag}
            startDrag={startDrag}
          />
        ))}
      </div>
      <div className="graph-buttons">
        {!onlyValues && (
          <>
            <Button
              extraSmall
              primary
              text="blocks.or"
              click={() => appendBlock(BlockType.OR)}
            />
            <Button
              extraSmall
              primary
              text="blocks.and"
              click={() => appendBlock(BlockType.AND)}
            />
            <Button
              extraSmall
              primary
              text="blocks.equals"
              click={() => appendBlock(BlockType.EQUALS)}
            />
            <Button
              extraSmall
              primary
              text="blocks.different"
              click={() => appendBlock(BlockType.DIFFERENT)}
            />
            <Button
              extraSmall
              primary
              text="blocks.more_than"
              click={() => appendBlock(BlockType.MORE_THAN)}
            />
            <Button
              extraSmall
              primary
              text="blocks.more_or_equal"
              click={() => appendBlock(BlockType.MORE_OR_EQUAL)}
            />
            <Button
              extraSmall
              primary
              text="blocks.lesser_than"
              click={() => appendBlock(BlockType.LESSER_THAN)}
            />
            <Button
              extraSmall
              primary
              text="blocks.lesser_or_equal"
              click={() => appendBlock(BlockType.LESSER_OR_EQUAL)}
            />

            <Button
              extraSmall
              primary
              text="blocks.add"
              click={() => appendBlock(BlockType.ADD)}
            />
            <Button
              extraSmall
              primary
              text="blocks.substract"
              click={() => appendBlock(BlockType.SUBSTRACT)}
            />
            <Button
              extraSmall
              primary
              text="blocks.multiply"
              click={() => appendBlock(BlockType.MULTIPLE)}
            />
            <Button
              extraSmall
              primary
              text="blocks.divide"
              click={() => appendBlock(BlockType.DIVIDE)}
            />
            <Button
              extraSmall
              secondary
              text="blocks.not"
              click={() => appendBlock(BlockType.NOT)}
            />
            <Button
              extraSmall
              primary
              text="blocks.if"
              click={() => appendBlock(BlockType.IF)}
            />

            <Button
              extraSmall
              primary
              text="blocks.group"
              click={() => appendBlock(BlockType.GROUP)}
            />
            <Button
              extraSmall
              secondary
              text="blocks.round"
              click={() => appendBlock(BlockType.ROUND)}
            />
            <Button
              extraSmall
              secondary
              text="blocks.round_up"
              click={() => appendBlock(BlockType.ROUND_UP)}
            />
            <Button
              extraSmall
              secondary
              text="blocks.round_down"
              click={() => appendBlock(BlockType.ROUND_DOWN)}
            />
          </>
        )}
        {onlyValues && (
          <Button
            extraSmall
            success
            text="blocks.field_name"
            click={() => appendBlock(BlockType.FIELD_NAME)}
          />
        )}
        <Button
          extraSmall
          success
          text="blocks.field"
          click={() => appendBlock(BlockType.FIELD)}
        />
        <Button
          extraSmall
          success
          text="blocks.value"
          click={() => appendBlock(BlockType.VALUE)}
        />
        {!onlyValues && (
          <Button
            extraSmall
            success
            text="blocks.field_option"
            click={() => showFieldSelector(true)}
          />
        )}
        {!onlyValues ? (
          <Button
            extraSmall
            success
            text="blocks.text"
            click={() => appendBlock(BlockType.TEXT)}
          />
        ) : (
          <Button
            extraSmall
            success
            text="blocks.text"
            click={() => appendBlock(BlockType.TEXT_I18N)}
          />
        )}
        {!onlyValues && (
          <>
            <Button
              extraSmall
              success
              text="blocks.true"
              click={() => appendBlock(BlockType.TRUE)}
            />
            <Button
              extraSmall
              success
              text="blocks.false"
              click={() => appendBlock(BlockType.FALSE)}
            />
            <Button
              extraSmall
              success
              text="blocks.null"
              click={() => appendBlock(BlockType.NULL)}
            />
          </>
        )}
      </div>
      {fieldSelectorVisible && (
        <FieldOptions
          fields={fields}
          appendBlock={appendBlock}
          hide={() => showFieldSelector(false)}
        />
      )}
    </div>
  );
};

export default GraphEditor;
