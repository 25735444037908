import React, { useEffect, useState } from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { apiList, apiTable, modal } from '../../actions';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ShareOfferReq, ShareOfferResponse } from 'profilpol-types';
import { successNotification } from '../../actions/notifications';
import { ButtonType } from '../../components/Common/Button';
import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import ShareOfferLink from '../SharedOfferLink';

interface Props {
  offerId: string;
}

const CopyOffer = ({ offerId }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.copy" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          formId="offerCopyForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() => ApiService.loadForm(`offer/form/copy`)}
          sendFetcher={(formId: string, values: ShareOfferReq) => {
            return ApiService.sendForm(
              `offer/copy/${offerId}`,
              values,
              'POST',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                small
                type={ButtonType.Button}
                danger
                click={() => dispatch(modal.hideModal())}
                text={'application.cancel'}
              />
              <Button
                disabled={loading}
                small
                type={ButtonType.Submit}
                success
                text={'offer.copy'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={FieldsMapper}
          onSavedSuccessfully={(data: ShareOfferResponse) => {
            dispatch(successNotification('offer.copied_successfully'));
            dispatch(apiTable.refreshApiTable());
            dispatch(modal.showModal(<ShareOfferLink status={data} />));
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default CopyOffer;
