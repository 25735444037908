import React from 'react';

import './Card.scss';

interface Props {
  children: React.ReactNode
}

const Card = ({children}: Props) => (
  <div className="card">
    {children}
  </div>
)

export default Card;
