import { FieldType, TableSchema } from '../types';

export const offerDraftsTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: 'offer.id',
            field: 'id',
            searchable: false,
            sortable: false,
            type: FieldType.Text,
        },
        {
            name: 'offer.name',
            field: 'name',
            searchable: true,
            sortable: true,
            type: FieldType.Text,
        },
        {
            name: 'offer.date',
            field: 'createDate',
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date,
        },
    ],
};
