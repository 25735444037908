import React, { useEffect, useState } from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
import ApiService from '../../../services/api-service';
import './OffersCartButton.scss';

const OffersCartButton = () => {
  const [count, setCount] = useState<number>();

  const getDraftsCount = async () => {
    await ApiService.callFetch('GET', 'offer/drafts/count', (res: number) => {
      setCount(res);
    });
  };

  useEffect(() => {
    getDraftsCount();
  }, []);

  return (
    <Button
      primary
      className="offers-cart-button"
      leftIcon
      faIcon={faShoppingCart}
      to="/panel/offers/drafts"
      text={typeof count === 'undefined' ? '-' : count.toString()}
    />
  );
};

export { OffersCartButton };
