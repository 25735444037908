import { SettingDetails } from 'profilpol-types';

interface GetSettings {
  type: 'GET_SETTINGS_SUCCESS';
  payload: SettingDetails[];
}
interface GetSettingsFailed {
  type: 'GET_SETTINGS_FAILED';
}

interface LoadingSettings {
  type: 'LOADING_SETTINGS';
  payload: boolean;
}

type Action = GetSettings | GetSettingsFailed | LoadingSettings;

export interface SettingsState {
  data: SettingDetails[];
  loading: boolean;
}

const initialState: SettingsState = {
  data: [],
  loading: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_SETTINGS_SUCCESS': {
      const settings = action.payload;
      return {
        ...state,
        data: settings,
      };
    }
    case 'GET_SETTINGS_FAILED': {
      return { ...state, data: [] };
    }

    case 'LOADING_SETTINGS': {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
};
