import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import React from 'react';
import { Button, Icon } from '../..';
import { __ } from '../../../../services/translation';
import { ButtonsContainer, Wrapper } from '../../../Layout';

import './RecipeTab.scss';

interface Props {
  item?: any;
  id?: number;
  name?: string;
  icon?: IconProp;
  bold?: boolean;
  noMargin?: boolean;
  buttons: {
    text: string;
    icon: IconProp;
    click?: (item: any) => void;
    to?: string;
    primary?: boolean;
    danger?: boolean;
  }[];
}

const RecipeTab = ({
  id,
  item,
  name,
  icon,
  buttons,
  bold,
  noMargin,
}: Props) => (
  <Wrapper className={clsx('tab-wrapper', { 'no-margin': noMargin })}>
    {icon && (
      <div className="icon-background">
        <Icon faIcon={icon} />
      </div>
    )}
    {typeof item.position !== 'undefined' && (
      <div className="item-position"> {item.position}</div>
    )}
    <div className="tab-content">
      <p className={clsx('tab-name', { bold: bold })}>{name}</p>
      <ButtonsContainer tabs>
        {buttons.map((button) => (
          <Button
            mobileIconOnly
            primary={button.primary}
            danger={button.danger}
            leftIcon
            reversed
            faIcon={button.icon}
            text={button.text}
            click={() => (button.click ? button.click(item) : {})}
            to={button.to}
          />
        ))}
      </ButtonsContainer>
    </div>
  </Wrapper>
);

export default RecipeTab;
