import React from 'react';
import { NavLink } from 'react-router-dom';
import { __ } from '../../../../services/translation';
import { Wrapper } from '../../../Layout';
import './NavigationTab.scss';

interface Props {
  tabs: {
    name: string;
    path: string;
  }[];
}

const NavigationTab = ({ tabs }: Props) => {
  return (
    <Wrapper className="nav-tab-wrapper">
      {tabs.map((tab) => (
        <NavLink
          key={tab.path}
          activeClassName="active"
          className="nav-tab-button"
          to={tab.path}
        >
          {__(tab.name)}
        </NavLink>
      ))}
    </Wrapper>
  );
};

export default NavigationTab;
