import React from 'react';
import clsx from 'clsx';

import './Main.scss';

interface Props {
  children: React.ReactNode;
  stretch?: boolean;
}

const Main = ({ children, stretch }: Props) => {
  return (
    <main className={clsx({ stretch: stretch})}>
      {children}
    </main>
  );
};

export default Main;
