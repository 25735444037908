import { Currency } from '../currency';
import { PaymentTypeDetails, PaymentTypeEntity } from '../payment-type';

export interface ContactPerson {
  isMain: boolean;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phone: string;
  [key: string]: any;
}

export interface ClientEntity {
  createdAt: Date;
  id: string;
  internalId: string;
  type: ClientType;
  paymentType: PaymentTypeEntity;
  companyName: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  companyVatNumber: string;
  defaultMargin: number;
  firstName: string;
  lastName: string;
  contacts: ContactPerson[];
  defaultCurrency: Currency;
}

export enum ClientType {
  COMPANY,
  PRIVATE,
}

export interface ClientListDetails {
  id: string;
  firstName: string;
  lastName: string;
  defaultCurrency: Currency;
  defaultMargin: number;
  companyName?: string;
  paymentType: PaymentTypeDetails;
}

export interface ClientDetails {
  createdAt: Date;
  id: string;
  internalId: string;
  type: ClientType;
  paymentType: PaymentTypeDetails;
  companyName: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  companyVatNumber: string;
  defaultMargin: number;
  firstName: string;
  lastName: string;
  contacts: ContactPerson[];
  defaultCurrency: Currency;
}

export interface ClientPreview {
  companyName: string;
  firstName: string;
  lastName: string;
}

export interface CreateClientReq {
  internalId: string;
  type: ClientType;
  paymentTypeId: string;
  companyName: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  defaultMargin: number;
  defaultCurrency: Currency;
  companyVatNumber: string;
  contacts: ContactPerson[];
}

export interface UpdateClientReq extends CreateClientReq { }

export interface PrepareOfferClient {
  id?: string;
  internalId: string;
  type: ClientType;
  companyName: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  defaultMargin: number;
  defaultCurrency: Currency;
  companyVatNumber: string;
  contacts: ContactPerson[];
}
