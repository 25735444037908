import React, { useEffect, useState } from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Alert, Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';
import { useDispatch } from 'react-redux';
import { ShareOfferResponse } from 'profilpol-types';
import './ShareOfferLink.scss';

interface Props {
  status: ShareOfferResponse;
}

const ShareOfferLink = ({ status }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.share_link" />
      </ModalHeader>

      {__('offer.share_link_description')}
      <ButtonsContainer center className="offer-link">
        <Button
          secondary
          blank
          to={`/offer/${status.accessToken}/${status.offerId}`}
          text="offer.see_offer"
        />
      </ButtonsContainer>
      <ButtonsContainer>
        <Button
          small
          primary
          text="application.ok"
          click={() => dispatch(modal.hideModal())}
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ShareOfferLink;
