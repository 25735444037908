import React from 'react';
import Pagination from '../Pagination';
import './List.scss';

interface Props {
  scheme: any;
  data: any;
  component: React.ComponentClass<any> | React.StatelessComponent<any>;
  narrow?: boolean;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  id?: string;
}

const List = ({
  data,
  initialPage,
  onPageChange,
  component,
  scheme,
}: Props) => (
  <>
    <div className="list">
      {data.items.map((item: any, index: number) =>
        React.createElement(component, {
          item,
          scheme,
          key: `list-key-${index.toString()}`,
        })
      )}
    </div>
    <Pagination
      initialPage={initialPage || 1}
      pageCount={Math.ceil(data.countTotal / scheme.countPerPage)}
      onPageChange={(page: number) => onPageChange && onPageChange(page)}
    />
  </>
);

export default List;
