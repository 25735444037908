import React from 'react';
import { ReactSVG } from 'react-svg';

import './Icon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

interface Props {
  className?: string;
  icon?: string;
  type?: string;
  faIcon?: IconProp;
  big?: boolean;
  small?: boolean;
}

const Icon = ({ icon, faIcon, className, big, small }: Props) => (
  <div className={clsx("icon-wrapper", className, {big: big, small: small} )}>
    <div className="icon">
      {faIcon && <FontAwesomeIcon icon={faIcon} />}
      {icon && <ReactSVG src={`/assets/icons/${icon}.svg`} />}
    </div>
  </div>
);

export default Icon;
