import clsx from 'clsx';
import React from 'react';
import { Button, Header } from '..';
import { __ } from '../../../services/translation';
import { Wrapper } from '../../Layout';

import './SectionHeader.scss';

interface Props {
  title: string;
  subtitle?: string;
  buttonName?: string;
  click?: () => void;
  noMarginTop?: boolean;
}

const SectionHeader = ({
  title,
  subtitle,
  buttonName,
  click,
  noMarginTop,
}: Props) => (
  <>
    <Wrapper
      className={clsx(`section-header-wrapper`, {
        'no-margin-top': noMarginTop,
      })}
    >
      <h2 className="section-title">{__(title)}</h2>
      {buttonName && <Button small secondary text={buttonName} click={click} />}
    </Wrapper>
    {subtitle && (
      <Wrapper>
        <p className="subtitle-header">{__(subtitle)}</p>
      </Wrapper>
    )}
  </>
);

export default SectionHeader;
