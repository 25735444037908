import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import userReducer, { UserState } from './user-reducer';
import notificationsReducer, {
  NotificationsState,
} from './notifications-reducer';
import menuReducer, { MenuState } from './menu-reducer';
import modalReducer, { ModalState } from './modal-reducer';
import langReducer, { LangState } from './lang-reducer';
import appStatusReducer, { AppStatusState } from './app-status-reducer';
import apiListReducer, { ApiListState } from './api-list-reducer';
import apiTableReducer, { ApiTableState } from './api-table-reducer';
import recipeReducer, { RecipeState } from './recipe-reducer';
import offerReducer, { OfferState } from './offer-reducer';
import settingsReducer, { SettingsState } from './settings-reducer';

export interface ApplicationState {
  user: UserState;
  notifications: NotificationsState;
  menu: MenuState;
  modal: ModalState;
  appStatus: AppStatusState;
  lang: LangState;
  apiList: ApiListState;
  apiTable: ApiTableState;
  recipe: RecipeState;
  offer: OfferState;
  settings: SettingsState;
}

const appReducer = combineReducers({
  user: userReducer,
  notifications: notificationsReducer,
  menu: menuReducer,
  modal: modalReducer,
  lang: langReducer,
  appStatus: appStatusReducer,
  apiList: apiListReducer,
  apiTable: apiTableReducer,
  recipe: recipeReducer,
  offer: offerReducer,
  settings: settingsReducer,
  form: formReducer
});

const rootReducer = (state: any, action: any) => appReducer(state, action);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
