import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  CreateOfferReq,
  Currency,
  OfferComponent,
  OfferCreatorComponent,
  OfferDescription,
  OfferDetails,
  OfferProduct,
  OfferSection,
  OfferService,
  RecipeDetails,
  RecipeProductDetails,
  RecipeSectionDetails,
  RecipeServiceDetails,
} from 'profilpol-types';
import CONFIG from '../../../config/app';
import ApiService from '../../../services/api-service';
import Button from '../Button';
import { ApplicationState } from '../../../reducers';
import { notifications } from '../../../actions';
import { history } from '../../../App';
import { useParams } from 'react-router';

interface FormData {
  name?: string;
  additionalInfo?: string;
  parsedDescription?: string;
  currency?: Currency;
  paymentTypeId?: string;
  validTo?: Date;
}

interface Props extends FormData {
  type: 'draft' | 'offer';
  disabled?: boolean;
}

const OfferDraftBtn = ({
  type,
  disabled,
  name,
  parsedDescription,
  additionalInfo,
  currency,
  paymentTypeId,
  validTo,
}: Props) => {
  const dispatch = useDispatch();
  const { offerId } = useParams<{ offerId?: string }>();

  const {
    client,
    recipe,
    components,
    additionalProducts,
    additionalServices,
    componentVisibility,
    itemInactive,
    itemVisibility,
    infoVisibility,
    infoValues,
    fieldVisibility,
    fieldValues,
    sectionVisibility,
    descriptionValues,
    descriptionVisibility,
    margins,
    quantities,
    lengths,
    language,
  } = useSelector((state: ApplicationState) => state.offer);

  const [loading, setLoading] = useState<boolean>(false);

  if (!client || !recipe) return null;

  const mapDescription = (recipe: RecipeDetails) => {
    const mapped: OfferDescription[] = recipe.description
      .filter(
        (description) =>
          description.useInDescription && descriptionVisibility[description.id]
      )
      .map((description) => {
        return {
          id: description.id,
          image: description.image,
          position: description.position,
          text: descriptionValues[description.id],
        };
      });

    return mapped;
  };

  const mapOfferComponents = (components: OfferCreatorComponent[]) => {
    console.log('mapping components', components);
    const mapped: OfferComponent[] = components.map((component) => ({
      id: component.id,
      active: componentVisibility[component.id],
      position: component.position,
      products: component.products
        .filter((product) => itemVisibility[product.id])
        .map((product) => ({
          productId: product.product.id,
          recipeProductId: product.id,
          margin:
            margins[product.id] ||
            (client ? client.defaultMargin : CONFIG.DEFAULT_MARGIN),
          quantity: quantities[product.id] || 0,
          length: lengths[product.id] || undefined,
          active: !itemInactive[product.id],
          position: product.position,
          price: product.product.price,
        })),
      services: component.services
        .filter((service) => itemVisibility[service.id])
        .map((service) => ({
          serviceId: service.service.id,
          recipeServiceId: service.id,
          margin:
            margins[service.id] ||
            (client ? client.defaultMargin : CONFIG.DEFAULT_MARGIN),
          quantity: quantities[service.id] || 0,
          active: !itemInactive[service.id],
          position: service.position,
          price: service.service.price,
        })),
    }));

    return mapped;
  };

  const mapAdditionalProducts = (
    additionalProducts: RecipeProductDetails[]
  ) => {
    const mapped: OfferProduct[] = additionalProducts.map((product) => ({
      productId: product.product.id,
      recipeProductId: product.id,
      margin:
        margins[product.id] ||
        (client ? client.defaultMargin : CONFIG.DEFAULT_MARGIN),
      quantity: quantities[product.id] || 0,
      length: lengths[product.id] || 0,
      active: true,
      position: product.position,
      price: product.product.price,
    }));

    return mapped;
  };

  const mapAdditionalServices = (
    additionalServices: RecipeServiceDetails[]
  ) => {
    const mapped: OfferService[] = additionalServices.map((service) => ({
      serviceId: service.service.id,
      recipeServiceId: service.id,
      margin:
        margins[service.id] ||
        (client ? client.defaultMargin : CONFIG.DEFAULT_MARGIN),
      quantity: quantities[service.id] || 0,
      active: true,
      position: service.position,
      price: service.service.price,
    }));

    return mapped;
  };

  const mapSections = (sections: RecipeSectionDetails[]): OfferSection[] => {
    const mapped: OfferSection[] = sections
      .filter((section) => sectionVisibility[section.id])
      .map((section) => ({
        id: section.id,
        name: section.name,
        description: section.description,
        position: section.position,
        infos: section.infos
          .filter((info) => infoVisibility[info.id])
          .map((info) => ({
            id: info.id,
            type: info.type,
            position: info.position,
            name: info.title,
            value: infoValues[info.id],
            image: info.image,
          })),
        inputs: section.inputs
          .filter((input) => fieldVisibility[input.id])
          .map((input) => ({
            id: input.id,
            type: input.type,
            position: input.position,
            name: input.title,
            value: fieldValues[input.id],
          })),
      }));

    return mapped;
  };

  const mappedDescription = mapDescription(recipe);

  const save = async () => {
    const draft: Partial<CreateOfferReq> = {
      name,
      parsedDescription,
      additionalInfo,
      currency: currency && Number(currency),
      paymentTypeId,
      validTo: validTo && new Date(validTo),
      language, //offer language
      client: client ? client : undefined,
      recipeId: recipe?.id,
      components: mapOfferComponents(components),
      description: mappedDescription,
      additionalProducts: mapAdditionalProducts(additionalProducts),
      additionalServices: mapAdditionalServices(additionalServices),
      sections: mapSections(recipe.sections),
      offerId,
    };

    setLoading(true);

    await ApiService.callFetch(
      'POST',
      type === 'draft' ? `offer/draft` : 'offer',
      (offer: OfferDetails) => {
        if (type === 'draft') {
          dispatch(notifications.successNotification('offer.draft_created'));
          history.push(`/panel/offers/drafts`);
        } else {
          dispatch(notifications.successNotification('offer.offer_created'));
          history.push(`/offer/${offer.accessToken}/${offer.id}`);
        }
      },
      null,
      draft
    );

    setLoading(false);
  };

  return (
    <Button
      disabled={loading || disabled}
      secondary={type === 'draft'}
      primary={type === 'offer'}
      text={type === 'draft' ? 'offer.save_draft' : 'offer.generate'}
      click={save}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...getFormValues('offer')(state),
  };
};

const OfferDraftButton = connect(mapStateToProps)(OfferDraftBtn);

export { OfferDraftButton };
