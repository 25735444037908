import { FieldType, TableSchema } from '../types';

export const paymentTypesTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'payment_type.name',
      field: 'name',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      defaultSort: true
    },
    {
      name: 'payment_type.clause',
      field: 'clause',
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
  ]
};
