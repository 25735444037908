import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../reducers";

import "./ProfileDetails.scss";

const ProfileDetails = () => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  if (!user) return null;
  return (
    <div className="profile-details">
      <p className="user-mail">{user.email}</p>
    </div>
  );
};

export default ProfileDetails;
