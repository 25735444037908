import React from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal, recipes } from '../../actions';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { CreateRecipeFieldReq, RecipeFieldDetails } from 'profilpol-types';
import { successNotification } from '../../actions/notifications';
import { ButtonType } from '../../components/Common/Button';
import ApiService from '../../services/api-service';
import { pathToObject } from '../../utils/path-to-object';
import { formOnTranslateString } from '../../utils/trans-form';
import RecipeFieldFieldsMapper from './RecipeFieldFieldsMapper';

interface Props {
  recipeId: string;
  id?: string;
}

const RecipeField = ({ recipeId, id }: Props) => {
  const dispatch = useDispatch();
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title={id ? 'recipe.edit_input' : 'recipe.new_input'} />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="recipeFieldForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() =>
            ApiService.loadForm(
              id ? `recipe/form/update-field/${id}` : 'recipe/form/add-field'
            )
          }
          sendFetcher={(formId: string, values: any) => {
            const field = {
              ...values,
              type: values.type,
              options: values.options
                ? values.options.map((option: any) => pathToObject(option))
                : [],
              recipeId,
            };

            return ApiService.sendForm(
              id ? `recipe/field/${id}` : 'recipe/field',
              pathToObject(field),
              id ? 'PATCH' : 'POST',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                small
                type={ButtonType.Button}
                danger
                click={() => dispatch(modal.hideModal())}
                text={'application.cancel'}
              />
              <Button
                disabled={loading}
                small
                type={ButtonType.Submit}
                success
                text={'application.save'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={(
            field,
            errors,
            fieldHtmlId,
            currentValue,
            onChange,
            onFocus,
            onBlur
          ) =>
            RecipeFieldFieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
              itfForm && itfForm.api && Number(itfForm.api.getValue('type')),
              recipeId
            )
          }
          onSavedSuccessfully={(data: RecipeFieldDetails) => {
            dispatch(
              successNotification(
                id ? 'recipe.field_updated' : 'recipe.field_created'
              )
            );
            if (id) {
              dispatch(recipes.updateRecipeFied(data));
            } else {
              dispatch(recipes.addNewField(data));
            }
            dispatch(modal.hideModal());
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default RecipeField;
