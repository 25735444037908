import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Button, Icon } from '../..';
import { __ } from '../../../../services/translation';
import { ListButton } from '../../../../types/list-button';
import { ButtonsContainer, Wrapper } from '../../../Layout';

import './DetailsTab.scss';

interface Props {
  excerpt: React.ReactNode;
  details?: React.ReactNode;
  icon?: IconProp;
  bold?: boolean;
  noMargin?: boolean;
  buttons: ListButton[];
}

const DetailsTab = ({ icon, bold, excerpt, details, buttons }: Props) => {
  const [toggled, setToggled] = useState<boolean>(false);

  return (
    <Wrapper className="details-tab">
      <Wrapper>
        {icon && (
          <div className="icon-background">
            <Icon faIcon={icon} />
          </div>
        )}
        <div className="tab-details-content">
          <div className={clsx('tab-name', { bold })}>{excerpt}</div>
          <div className="tab-buttons">
            <ButtonsContainer tabs>
              {buttons &&
                buttons
                  .filter(
                    (button) =>
                      typeof button.active === 'undefined' || button.active
                  )
                  .map((button, index) => (
                    <Button
                      key={index}
                      mobileIconOnly
                      primary={button.primary}
                      danger={button.danger}
                      leftIcon
                      reversed
                      faIcon={button.icon}
                      text={button.text}
                      click={button.click}
                      to={button.to}
                    />
                  ))}
              {details && (
                <Button
                  mobileIconOnly
                  primary
                  leftIcon
                  reversed
                  faIcon={faCaretDown}
                  text={'application.more'}
                  click={() => setToggled(!toggled)}
                />
              )}
            </ButtonsContainer>
          </div>
        </div>
      </Wrapper>
      {details && (
        <Wrapper className={`details-tab-wrapper ${toggled ? 'toggled' : ''}`}>
          {details}
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default DetailsTab;
