import React from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../../../App';

interface Props {
  to: string;
  children: React.ReactNode;
  onClick?: (event: any) => void;
  className?: string;
}

const LinkDelayed = ({ to, className, children, onClick }: Props) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    if (typeof onClick === 'function') onClick(e);

    setTimeout(() => {
      history.push(to);
    }, 200);
  };
  return (
    <Link to={to} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default LinkDelayed;
