import React from 'react';
import { FieldsMapper, GraphEditor } from '../../../components/Common';

const RecipeDescriptionFieldsMapper = (
  field: any,
  errors: any,
  fieldHtmlId: any,
  currentValue: any,
  onChange: any,
  onFocus: any,
  onBlur: any,
  recipeId: string
) => {
  if (field.id.includes('text')) {
    return (
      <GraphEditor
        onlyValues
        recipeId={recipeId}
        label={field.title}
        onChange={onChange}
        initialValue={currentValue || []}
      />
    );
  }
  return FieldsMapper(
    field,
    errors,
    fieldHtmlId,
    currentValue,
    onChange,
    onFocus,
    onBlur,
    { recipeId }
  );
};

export default RecipeDescriptionFieldsMapper;
