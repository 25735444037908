import {
  OfferClient,
  OfferCreatorComponent,
  ProductDetails,
  RecipeDetails,
  RecipeProductDetails,
  RecipeServiceDetails,
  ServiceDetails,
  I18nEntry,
  RecipeFieldType,
  Languages,
  OfferDetails,
  OfferForEdit,
  RefreshedPricesRes,
} from 'profilpol-types';
import uuid from 'uuid';
import { Parser as FormulaParser } from 'hot-formula-parser';
import { flattenBlocks, flattenBlocksWithoutQuotes } from '../utils/blocks';
import CONFIG from '../config/app';

export interface Visibilities {
  [key: string]: boolean;
}

export interface Values {
  [key: string]: string | number | boolean | null;
}

export interface I18nValues {
  [key: string]: I18nEntry;
}

export interface Quantities {
  [key: string]: number | null;
}

export interface Lengths {
  [key: string]: number | null;
}

export interface Inactivities {
  [key: string]: boolean;
}

export interface Percentages {
  [key: string]: number;
}

export interface OfferState {
  calculationUuid: string;
  client: OfferClient | null;
  recipe: RecipeDetails | null;
  components: OfferCreatorComponent[];
  additionalServices: RecipeServiceDetails[];
  additionalProducts: RecipeProductDetails[];
  infoValues: Values;
  infoVisibility: Visibilities;
  fieldVisibility: Visibilities;
  quantities: Quantities;
  lengths: Lengths;
  itemVisibility: Visibilities;
  itemInactive: Inactivities;
  sectionVisibility: Visibilities;
  componentVisibility: Visibilities;
  fieldValues: Values;
  descriptionVisibility: Visibilities;
  descriptionValues: I18nValues;
  margins: Percentages;
  loading: boolean;
  fieldNames: I18nValues;
  infoNames: I18nValues;
  optionNames: I18nValues;
  language: Languages;
  lockForm: boolean;
}

const initialState: OfferState = {
  calculationUuid: uuid.v4(),
  client: null,
  recipe: null,
  components: [],
  additionalProducts: [],
  additionalServices: [],
  infoValues: {},
  infoVisibility: {},
  fieldVisibility: {},
  quantities: {},
  lengths: {},
  itemVisibility: {},
  margins: {},
  sectionVisibility: {},
  componentVisibility: {},
  itemInactive: {},
  fieldValues: {},
  descriptionVisibility: {},
  descriptionValues: {},
  fieldNames: {},
  infoNames: {},
  optionNames: {},
  loading: false,
  language: 'pl',
  lockForm: false,
};

interface SetFormLock {
  type: 'SET_FORM_LOCK';
  payload: boolean;
}

interface GetRecipeForOffer {
  type: 'GET_RECIPE_FOR_OFFER_SUCCESS';
  payload: RecipeDetails;
}

interface GetRecipeForOfferEdit {
  type: 'GET_RECIPE_FOR_OFFER_EDIT_SUCCESS';
  payload: RecipeDetails;
}

interface SetOfferRecipe {
  type: 'SET_OFFER_RECIPE';
  payload: RecipeDetails;
}

interface GetRecipeForOfferFailed {
  type: 'GET_RECIPE_FOR_OFFER_FAILED';
}

interface LoadingRecipeForOffer {
  type: 'LOADING_RECIPE_FOR_OFFER';
  payload: boolean;
}

interface LoadingRefreshedPrices {
  type: 'LOADING_REFRESHED_PRICES';
  payload: boolean;
}

interface SetOfferClient {
  type: 'SET_OFFER_CLIENT';
  payload: OfferClient;
}


interface SetOfferLanguage {
  type: 'SET_OFFER_LANGUAGE';
  payload: Languages;
}


interface OverrideOfferMargin {
  type: 'OVERRIDE_OFFER_MARGIN';
  payload: {
    newMargin: number;
  };
}

interface UpdateFieldValue {
  type: 'UPDATE_FIELD_VALUE';
  payload: {
    field: string;
    value: any;
  };
}

interface UpdateOptionName {
  type: 'UPDATE_OPTION_NAME';
  payload: {
    field: string;
    value: I18nEntry;
  };
}

interface UpdateItemMargin {
  type: 'UPDATE_ITEM_MARGIN';
  payload: {
    field: string;
    margin: number;
  };
}

interface UpdateItemQuantity {
  type: 'UPDATE_ITEM_QUANTITY';
  payload: {
    field: string;
    quantity: number;
  };
}

interface RefreshData {
  type: 'REFRESH_DATA';
  payload: RecipeDetails;
}

interface ClearOffer {
  type: 'CLEAR_OFFER';
}

interface AddProductToOffer {
  type: 'ADD_PRODUCT_TO_OFFER';
  payload: {
    product: ProductDetails;
    quantity: number;
    length?: number
  };
}

interface ChangeProduct {
  type: 'CHANGE_OFFER_PRODUCT';
  payload: {
    product: ProductDetails;
    componentId: string;
    id: string;
    quantity: number;
    length?: number
  };
}

interface AddServiceToOffer {
  type: 'ADD_SERVICE_TO_OFFER';
  payload: {
    service: ServiceDetails;
    quantity: number;
  };
}

interface ToggleProductService {
  type: 'TOGGLE_PRODUCT_SERVICE';
  payload: {
    componentId: string;
    id: string;
  };
}

interface DeleteProduct {
  type: 'DELETE_PRODUCT';
  payload: {
    componentId: string;
    productId: string;
  };
}

interface DeleteService {
  type: 'DELETE_SERVICE';
  payload: {
    componentId: string;
    serviceId: string;
  };
}

interface LoadOffer {
  type: 'LOAD_OFFER';
  payload: OfferForEdit;
}

interface RefreshOfferPrices {
  type: 'REFRESH_OFFER_PRICES_SUCCESS',
  payload: RefreshedPricesRes
}

export type Action =
  | ChangeProduct
  | SetOfferClient
  | SetOfferRecipe
  | UpdateFieldValue
  | UpdateOptionName
  | UpdateItemMargin
  | UpdateItemQuantity
  | AddProductToOffer
  | AddServiceToOffer
  | ToggleProductService
  | RefreshData
  | GetRecipeForOffer
  | GetRecipeForOfferEdit
  | GetRecipeForOfferFailed
  | LoadingRecipeForOffer
  | DeleteProduct
  | DeleteService
  | OverrideOfferMargin
  | SetOfferLanguage
  | ClearOffer
  | LoadOffer
  | RefreshOfferPrices
  | SetFormLock
  | LoadingRefreshedPrices

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'CLEAR_OFFER':
      console.log('CLEARING OFFER');
      return initialState;
    case 'SET_OFFER_RECIPE': {
      return {
        ...state,
        recipe: action.payload
      }
    }
    case 'SET_FORM_LOCK': {
      return {
        ...state,
        lockForm: action.payload
      }
    }
    case 'GET_RECIPE_FOR_OFFER_SUCCESS': {
      const initialValues: Values = {};
      const initialMargins: Percentages = {};

      action.payload.sections.forEach((section) => {
        section.inputs.forEach((input) => {
          if (input.type === RecipeFieldType.CHECKBOX) {
            initialValues[input.id] = false;
          }
        });
      });

      action.payload.components.forEach((component) => {
        component.products.forEach(product => {
          initialMargins[product.id] = state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN;
        });
        component.services.forEach(service => {
          initialMargins[service.id] = state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN;
        });
      });



      // additional products and services does not reset
      return {
        ...state,
        components: action.payload.components,
        recipe: action.payload,
        margins: { ...state.margins, ...initialMargins },
        infoVisibility: {},
        fieldsVisibility: {},
        fieldValues: { ...initialValues, ...state.fieldValues }
      };
    }

    case 'GET_RECIPE_FOR_OFFER_EDIT_SUCCESS': {
      const initialValues: Values = {};
      const initialMargins: Percentages = {};

      action.payload.sections.forEach((section) => {
        section.inputs.forEach((input) => {
          if (input.type === RecipeFieldType.CHECKBOX) {
            initialValues[input.id] = false;
          }
        });
      });

      action.payload.components.forEach((component) => {
        component.products.forEach(product => {
          initialMargins[product.id] = state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN;
        });
        component.services.forEach(service => {
          initialMargins[service.id] = state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN;
        });

      });



      // additional products and services does not reset
      return {
        ...state,
        recipe: action.payload,
        margins: { ...state.margins, ...initialMargins },
        infoVisibility: {},
        fieldsVisibility: {},
        fieldValues: { ...initialValues, ...state.fieldValues }
      };
    }
    case 'GET_RECIPE_FOR_OFFER_FAILED':
      return {
        ...state,
        recipe: null,
      };
    case 'LOADING_RECIPE_FOR_OFFER':
      return {
        ...state,
        loading: action.payload,
      };
    case 'LOADING_REFRESHED_PRICES':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_OFFER_CLIENT':
      return {
        ...state,
        client: action.payload,
      };
    case 'SET_OFFER_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };

    case 'OVERRIDE_OFFER_MARGIN':
      const { newMargin } = action.payload;
      const currentMargins = { ...state.margins };


      Object.keys(currentMargins).forEach((id: string) => {
        currentMargins[id] = newMargin;
      });


      return {
        ...state,
        margins: currentMargins
      };
    case 'UPDATE_FIELD_VALUE': {
      return {
        ...state,
        calculationUuid: uuid.v4(),
        fieldValues: {
          ...state.fieldValues,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case 'CHANGE_OFFER_PRODUCT': {
      const { componentId, id, product, length, quantity } = action.payload
      return {
        ...state,
        quantities: {
          ...state.quantities,
          [action.payload.id]: action.payload.quantity,
        },
        lengths: {
          ...state.lengths,
          [action.payload.id]: action.payload.length,
        },
        components: state.components.map(component => {
          if (component.id === componentId) {
            return {
              ...component,
              products: component.products.map(currentProduct => {
                if (currentProduct.id === id) {
                  return {
                    ...currentProduct,
                    product: {
                      ...product,
                      price: currentProduct.product.price
                    }

                  }
                }
                return currentProduct;
              })
            }
          }
          return component;
        })
      }
    }
    case 'UPDATE_OPTION_NAME': {
      return {
        ...state,
        optionNames: {
          ...state.optionNames,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case 'UPDATE_ITEM_QUANTITY': {
      return {
        ...state,
        calculationUuid: uuid.v4(),
        quantities: {
          ...state.quantities,
          [action.payload.field]: action.payload.quantity,
        },
      };
    }
    case 'UPDATE_ITEM_MARGIN': {
      return {
        ...state,
        margins: {
          ...state.margins,
          [action.payload.field]: Math.round(action.payload.margin * 100) / 100,
        },
      };
    }
    case 'ADD_PRODUCT_TO_OFFER': {
      const newUuid = uuid.v4();
      if (!state.recipe) return state;
      const { product, quantity, length } = action.payload;

      return {
        ...state,
        quantities: {
          ...state.quantities,
          [newUuid]: quantity,
        },
        lengths: {
          ...state.lengths,
          [newUuid]: length,
        },
        itemVisibility: {
          ...state.itemVisibility,
          [newUuid]: true,
        },
        margins: {
          ...state.margins,
          [newUuid]: state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN
        },
        additionalProducts: [
          ...state.additionalProducts,
          {
            condition: [],
            quantity: [],
            id: newUuid,
            product: product,
          },
        ],
      };
    }
    case 'TOGGLE_PRODUCT_SERVICE': {
      if (!state.recipe) return state;
      const { id } = action.payload;
      const currentState = !!state.itemInactive[id];
      return {
        ...state,
        itemInactive: {
          ...state.itemInactive,
          [id]: !currentState,
        },
      };
    }
    case 'ADD_SERVICE_TO_OFFER': {
      const newUuid = uuid.v4();
      if (!state.recipe) return state;
      const { service, quantity } = action.payload;
      return {
        ...state,
        quantities: {
          ...state.quantities,
          [newUuid]: quantity,
        },
        itemVisibility: {
          ...state.itemVisibility,
          [newUuid]: true,
        },
        margins: {
          ...state.margins,
          [newUuid]: state.client ? state.client.defaultMargin : CONFIG.DEFAULT_MARGIN
        },
        additionalServices: [
          ...state.additionalServices,
          {
            condition: [],
            quantity: [],
            id: newUuid,
            service: service,
          },
        ],
      };
    }

    case 'DELETE_PRODUCT': {
      if (!state.recipe) return state;
      const { componentId, productId } = action.payload;
      return {
        ...state,
        quantities: {
          ...state.quantities,
          [productId]: null,
        },
        itemVisibility: {
          ...state.itemVisibility,
          [productId]: null,
        },

        additionalProducts: state.additionalProducts.filter(
          (product) => product.id !== productId
        ),
      };
    }

    case 'DELETE_SERVICE': {
      if (!state.recipe) return state;
      const { componentId, serviceId } = action.payload;
      return {
        ...state,
        quantities: {
          ...state.quantities,
          [serviceId]: null,
        },
        itemVisibility: {
          ...state.itemVisibility,
          [serviceId]: null,
        },

        additionalServices: state.additionalServices.filter(
          (service) => service.id !== serviceId
        ),
      };
    }

    case 'REFRESH_DATA': {
      const recipe = action.payload;
      const parser = new FormulaParser();
      if (!recipe) return;
      const allValues = {
        ...state.fieldValues,
        ...state.infoValues,
      };
      let newSectionVisibility: Visibilities = state.sectionVisibility;
      let newComponentVisibility: Visibilities = state.componentVisibility;
      let newDescriptionVisibility: Visibilities = state.descriptionVisibility;
      let newDescriptionValues: I18nValues = state.descriptionValues;
      let newInfoValues: Values = state.infoValues;
      let newInfoVisibilities: Visibilities = state.infoVisibility;
      let newFieldVisibilities: Visibilities = state.fieldVisibility;
      let newFieldValues: Values = state.fieldValues;
      let newQuantities: Quantities = state.quantities;
      let newLengths: Quantities = state.lengths;
      let newItemVisibilities: Visibilities = state.itemVisibility;
      let newDescription: I18nEntry = {};
      let newUuid = state.calculationUuid;

      const mappedOptions: any = {};
      Object.keys(state.optionNames).forEach((key) => {
        CONFIG.LANGUAGES.forEach((lang) => {
          if (!mappedOptions[lang]) {
            mappedOptions[lang] = {};
          }
          mappedOptions[lang][key] = state.optionNames[key][lang] || '';
        });
      });

      let mappedNames: I18nValues = {};
      [...recipe.infos, ...recipe.inputs].forEach((val) => {
        mappedNames[val.id] = val.title;
      });

      recipe.sections.forEach((section) => {
        if (section.condition) {
          const formulaVisibility = flattenBlocks(
            section.condition,
            '',
            { ...allValues, ...newFieldValues, ...newInfoValues },
            mappedNames
          );

          const result = parser.parse(formulaVisibility).result;

          newSectionVisibility = {
            ...newSectionVisibility,
            [section.id]: section.condition!.length > 0 ? result : true,
          };
        }
        section.infos.forEach((info) => {
          if (info.condition) {
            const formulaVisibility = flattenBlocks(
              info.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaVisibility).result;

            if (
              newInfoValues[info.id] !== null &&
              info.condition.length > 0 &&
              !!result !== true
            ) {
              newUuid = uuid.v4();
              newInfoValues = {
                ...newInfoValues,
                [info.id]: null,
              };
            }

            newInfoVisibilities = {
              ...newInfoVisibilities,
              [info.id]: info.condition!.length > 0 ? result : true,
            };
          } else {
            newInfoVisibilities = {
              ...newInfoVisibilities,
              [info.id]: true,
            };
          }
          if (info.value && newInfoVisibilities[info.id]) {
            const formulaValue = flattenBlocks(
              info.value,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaValue).result;

            if (result !== newInfoValues[info.id]) {
              newInfoValues = {
                ...newInfoValues,
                [info.id]: info.value.length > 0 ? result : null,
              };
            }
          }
        });
        section.inputs.forEach((input) => {
          if (input.condition) {
            const formulaVisibility = flattenBlocks(
              input.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            const result = parser.parse(formulaVisibility).result;

            if (
              newFieldValues[input.id] !== null &&
              newFieldValues[input.id] !== false &&
              input.condition.length > 0 &&
              !!result !== true
            ) {
              newUuid = uuid.v4();

              newFieldValues = {
                ...newFieldValues,
                [input.id]:
                  input.type === RecipeFieldType.CHECKBOX ? false : null,
              };
            }

            newFieldVisibilities = {
              ...newFieldVisibilities,
              [input.id]: input.condition!.length > 0 ? result : true,
            };
          } else {
            newFieldVisibilities = {
              ...newFieldVisibilities,
              [input.id]: true,
            };
          }
        });
      });
      recipe.components.forEach((component) => {
        if (component.condition) {
          const formulaVisibility = flattenBlocks(
            component.condition,
            '',
            { ...allValues, ...newFieldValues, ...newInfoValues },
            mappedNames
          );

          newComponentVisibility = {
            ...newComponentVisibility,
            [component.id]:
              component.condition!.length > 0
                ? parser.parse(formulaVisibility).result
                : true,
          };
        }
        [...component.products, ...component.services].forEach((item) => {
          if (item.quantity) {
            const formulaQuantity = flattenBlocks(
              item.quantity,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newQuantities = {
              ...newQuantities,
              [item.id]: parser.parse(formulaQuantity).result,
            };
          }
          if (
            item.hasOwnProperty('length') &&
            (item as RecipeProductDetails).length
          ) {
            const profile = item as RecipeProductDetails;
            if (!profile.length) return;
            const formulaLength = flattenBlocks(
              profile.length,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newLengths = {
              ...newLengths,
              [item.id]: parser.parse(formulaLength).result,
            };
          }
          if (item.condition) {
            const formulaVisibility = flattenBlocks(
              item.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              mappedNames
            );

            newItemVisibilities = {
              ...newItemVisibilities,
              [item.id]:
                item.condition!.length > 0
                  ? parser.parse(formulaVisibility).result
                  : true,
            };
          }
        });
      });
      recipe.description.forEach((description) => {
        if (description.condition) {
          const formulaVisibility = flattenBlocks(
            description.condition,
            '',
            { ...allValues, ...newFieldValues, ...newInfoValues },
            mappedNames
          );

          newDescriptionVisibility = {
            ...newDescriptionVisibility,
            [description.id]:
              description.condition!.length > 0
                ? parser.parse(formulaVisibility).result
                : true,
          };

          newDescriptionValues[description.id] = {};

          CONFIG.LANGUAGES.forEach((key: string) => {
            const value = flattenBlocksWithoutQuotes(
              description.text,
              '',
              {
                ...allValues,
                ...newFieldValues,
                ...newInfoValues,
                ...mappedOptions[key],
              },
              mappedNames,
              key
            );
            newDescriptionValues[description.id][key] = value;
          });
        }
      });



      return {
        ...state,
        infoValues: newInfoValues,
        infoVisibility: newInfoVisibilities,
        fieldVisibility: newFieldVisibilities,
        fieldValues: newFieldValues,
        quantities: newQuantities,
        lengths: newLengths,
        itemVisibility: newItemVisibilities,
        sectionVisibility: newSectionVisibility,
        componentVisibility: newComponentVisibility,
        descriptionVisibility: newDescriptionVisibility,
        descriptionValues: newDescriptionValues,
        calculationUuid: newUuid,
      };
    }
    case 'LOAD_OFFER': {
      const offer = action.payload;

      let newUuid = state.calculationUuid;
      const newInfoValues: Values = {};
      const newFieldValues: Values = {};
      const newInfoVisibilities: Visibilities = {};
      const newFieldVisibilities: Visibilities = {};
      const newSectionVisibilities: Visibilities = {};
      const newComponentVisibility: Visibilities = {};
      const newItemVisibility: Visibilities = {};
      const newQuantities: Quantities = {};
      const newInactivity: Inactivities = {};
      const newLengths: Lengths = {};
      const newMargins: Percentages = {};

      offer.sections.forEach(section => {
        newSectionVisibilities[section.id] = true;

        section.inputs.forEach(input => {
          newFieldValues[input.id] = input.value;
          newFieldVisibilities[input.id] = true;
        })
        section.infos.forEach(info => {
          newInfoValues[info.id] = info.value;
          newInfoVisibilities[info.id] = true;
        })
      })

      offer.components.forEach(component => {
        newComponentVisibility[component.id] = component.active;

        component.products.forEach(product => {
          newInactivity[product.recipeProductId] = !product.active;
          newItemVisibility[product.recipeProductId] = true;
          if (product.length) {
            newLengths[product.recipeProductId] = product.length
          }
          newQuantities[product.recipeProductId] = product.quantity || 0;
          newMargins[product.recipeProductId] = product.margin || 0;
        })

        component.services.forEach(service => {
          newInactivity[service.recipeServiceId] = !service.active;
          newItemVisibility[service.recipeServiceId] = true;
          newQuantities[service.recipeServiceId] = service.quantity || 0;
          newMargins[service.recipeServiceId] = service.margin || 0;
        })
      })

      offer.additionalProducts.forEach(product => {
        newInactivity[product.recipeProductId] = !product.active;
        newItemVisibility[product.recipeProductId] = true;
        if (product.length) {
          newLengths[product.recipeProductId] = product.length
        }
        newQuantities[product.recipeProductId] = product.quantity || 0;
        newMargins[product.recipeProductId] = product.margin || 0;
      })

      offer.additionalServices.forEach(service => {
        newInactivity[service.recipeServiceId] = !service.active;
        newItemVisibility[service.recipeServiceId] = true;
        newQuantities[service.recipeServiceId] = service.quantity || 0;
        newMargins[service.recipeServiceId] = service.margin || 0;
      })

      return {
        ...state,
        infoValues: newInfoValues,
        infoVisibility: newInfoVisibilities,
        fieldVisibility: newFieldVisibilities,
        fieldValues: newFieldValues,
        sectionVisibility: newSectionVisibilities,
        componentVisibility: newComponentVisibility,
        components: offer.componentsCreator,
        additionalProducts: offer.additionalProductsCreator,
        additionalServices: offer.additionalServicesCreator,
        quantities: newQuantities,
        lengths: newLengths,
        itemVisibility: newItemVisibility,
        itemInactive: newInactivity,
        calculationUuid: newUuid,
        margins: { ...state.margins, ...newMargins }
      }
    }
    case 'REFRESH_OFFER_PRICES_SUCCESS': {
      const data = action.payload;
      return {
        ...state,
        components: data.components,
        additionalProducts: data.additionalProducts,
        additionalServices: data.additionalServices,
      }
    }
    default:
      return state;
  }
};
