import React from 'react';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';
import { useDispatch } from 'react-redux';

interface Props {
  onClose?: () => void;
}

const OfferOutdatedRecipe = ({ onClose }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.outdated_recipe" />
      </ModalHeader>
      <p>{__('offer.outdated_recipe_text')}</p>
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => {
            dispatch(modal.hideModal(onClose));
          }}
          text={'application.ok'}
          danger
          small
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default OfferOutdatedRecipe;
