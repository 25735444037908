import React from "react";
import { LogoContainer, Wrapper } from "..";
import { HelloText, HomeCircle, Logo } from "../../Common";

import "./CircleWrapper.scss";

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}

const CicrleWrapper = ({children, title, subtitle}: Props) => (
  <Wrapper className="login-wrapper">
    <Wrapper column className="login-section">
      <Wrapper column className="content-wrapper">
        <LogoContainer hidden>
          <Logo big />
        </LogoContainer>
        <HelloText mobileHidden title={title} subtitle={subtitle}/>
        {children}
      </Wrapper>
    </Wrapper>
    <HomeCircle>
      <HelloText mobile title={title} subtitle={subtitle}/>
    </HomeCircle>
  </Wrapper>
);

export default CicrleWrapper;
