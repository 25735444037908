import React from 'react';
import {
  Button,
  Header,
  Subtitle,
  ApiTable,
  Thumbnail,
} from '../../../../components/Common';
import {
  ButtonsContainer,
  Container,
  Wrapper,
} from '../../../../components/Layout';
import { __ } from '../../../../services/translation';

import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { FieldType, profileTableSchema } from 'profilpol-tables';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { ProductType, Currency } from 'profilpol-types';
import {
  faPencilAlt,
  faTrash,
  faCaretDown,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { modal, notifications, apiTable } from '../../../../actions';
import { ItemDetails, Confirmation } from '../../../../modals';
import ApiService from '../../../../services/api-service';
import Details from './Item';
import { formatProductPrice } from '../../../../utils/format-price';

const List = () => {
  const dispatch = useDispatch();
  const scheme = new InteractiveTableSchema(profileTableSchema)
    .modifyFieldStatic('weight', {
      name: 'profile.weight',
      field: 'weight',
      type: FieldType.Custom,
      customRender: (value, field, all) => `${value} kg/m`,
    })
    .modifyFieldStatic('price', {
      name: 'profile.price',
      field: 'price',
      type: FieldType.Custom,
      customRender: (value, field, all) =>
        formatProductPrice(value, Currency.PLN, ProductType.PROFILE),
    })
    .addFieldAtTheBeginning({
      type: FieldType.Custom,
      name: '',
      field: 'thumbnail',
      mobile: true,
      customRender: (value, field, values) => {
        if (values.image) {
          return (
            <Thumbnail src={`${ApiService.url}product/image/${values.id}`} />
          );
        }
      },
    })
    .addFieldAtTheEnd({
      type: FieldType.Custom,
      name: '',
      field: 'actions',
      mobile: true,
      customRender: (value, field, values) => {
        return (
          <ButtonsContainer tabs>
            <Button
              reversed
              small
              leftIcon
              mobileIconOnly
              faIcon={faPencilAlt}
              text="application.edit"
              to={`/panel/profiles/edit/${values.id}`}
            />
            <Button
              reversed
              small
              leftIcon
              mobileIconOnly
              faIcon={faCopy}
              text="application.duplicate"
              to={`/panel/profiles/duplicate/${values.id}`}
            />
            <Button
              reversed
              small
              leftIcon
              mobileIconOnly
              danger
              faIcon={faTrash}
              text="application.delete"
              click={() => confirmDelete(values.id)}
            />
            <Button
              reversed
              small
              leftIcon
              mobileIconOnly
              primary
              faIcon={faCaretDown}
              text="application.more"
              click={() =>
                dispatch(
                  modal.showModal(
                    <ItemDetails
                      title={values.name}
                      content={<Details id={values.id} />}
                    />
                  )
                )
              }
            />
          </ButtonsContainer>
        );
      },
    });
  const total = useSelector(
    (state: ApplicationState) => state.apiTable.countTotal
  );

  const confirmDelete = (productId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="profiles.confirm_delete"
          confirmCallback={() => deleteProduct(productId)}
        />
      )
    );
  };

  const deleteProduct = async (productId: string) => {
    await ApiService.callFetch('DELETE', `product/${productId}`, () => {
      dispatch(notifications.successNotification('profiles.deleted'));
      dispatch(apiTable.refreshApiTable());
      dispatch(modal.hideModal());
    });
  };

  return (
    <Container>
      <Wrapper>
        <Header
          header="profiles.title"
          buttonText="profiles.button_text"
          buttonTo="/panel/profiles/new"
        />
      </Wrapper>
      <Wrapper>
        <Subtitle>
          {__('profiles.subtitle')}: <strong>{total}</strong>
        </Subtitle>
      </Wrapper>
      <ApiTable
        scheme={scheme}
        apiEndpointSubUrl={`product/list/${ProductType.PROFILE}`}
      />
    </Container>
  );
};

export default List;
