import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CONFIG from '../../../../config/app';
import {
  Container,
  FormContainer,
  ButtonsContainer,
} from '../../../../components/Layout';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  Spinner,
  Button,
  FieldsMapper,
  Header,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { PaymentTypeDetails, UpdatePaymentTypeReq } from 'profilpol-types';
import { __ } from '../../../../services/translation';
import { ButtonType } from '../../../../components/Common/Button';
import { successNotification } from '../../../../actions/notifications';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { useParams } from 'react-router';
import { history } from '../../../../App';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { pathToObject } from '../../../../utils/path-to-object';

const Edit = () => {
  const { paymentTypeId } = useParams<{ paymentTypeId: string }>();

  const dispatch = useDispatch();

  return (
    <Container>
      <Header header="payment_type.edit" />
      <Button
        leftIcon
        link
        faIcon={faChevronLeft}
        text={'application.back'}
        to="/panel/payment-types"
      />
      <FormContainer>
        <ItfApiForm
          formId="paymentTypeForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() =>
            ApiService.loadForm(`payment-type/form/update/${paymentTypeId}`)
          }
          sendFetcher={(formId: string, values: UpdatePaymentTypeReq) =>
            ApiService.sendForm(
              `payment-type/${paymentTypeId}`,
              pathToObject(values),
              'PATCH',
              null
            )
          }
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                big
                type={ButtonType.Submit}
                primary
                text={'application.save'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={FieldsMapper}
          onSavedSuccessfully={(data: PaymentTypeDetails) => {
            dispatch(successNotification('payment_type.updated'));
            history.push('/panel/payment-types');
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </Container>
  );
};

export default Edit;
