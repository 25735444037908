import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { UserRole, UserMeDetails } from "profilpol-types";
import { ApplicationState } from "../../reducers";
import { Spinner } from '../../components/Common';

interface Props {
  exact?: boolean;
  path?: string;
  render?: any;
  component?: any;
}

const RouteUnauthorized = ({ exact, path, component, render }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const loading = useSelector((state: ApplicationState) => state.user.loading);

  if (user && user.role === UserRole.Admin) {
    return <Route render={() => <Redirect to="/panel" />} />;
  }
  if (user && user.role === UserRole.Salesman) {
    return <Route render={() => <Redirect to="/panel" />} />;
  }

  if (render) return <Route exact={exact} path={path} render={render} />;

  return <Route exact={exact} path={path} component={component} />;
};

export default RouteUnauthorized;
