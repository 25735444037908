import { SelectOption, SelectOptionI18n } from '.';
import { Block } from '..';
import { I18nEntry } from '../i18n';

export interface RecipeFieldEntity {
  id: string;
  title: I18nEntry;
  type: RecipeFieldType;
  position: number;
  options?: SelectOptionI18n[];
  condition?: Block[];
}

export interface OfferField {
  id: string;
  type: RecipeFieldType;
  position: number;
  name: I18nEntry;
  value: string | number | boolean | null;
}

export interface RecipeFieldDetails {
  id: string;
  title: I18nEntry;
  type: RecipeFieldType;
  position: number;
  options?: SelectOptionI18n[];
  condition?: Block[];
}

export interface CreateRecipeFieldReq {
  recipeId: string;
  title: I18nEntry;
  type: RecipeFieldType;
  position: number;
  options?: SelectOptionI18n[];
  condition?: string;
}

export enum RecipeFieldType {
  TEXT,
  NUMBER,
  CHECKBOX,
  RADIOBUTTONS,
  SELECT,
}
