import React, { ChangeEvent, useEffect, useState } from 'react';
import { ButtonsContainer, FieldsGroup } from '../../components/Layout';
import { Button, Input, InputSelect } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal, offer } from '../../actions';
import { useDispatch } from 'react-redux';
import { ProductDetails, ProductType } from 'profilpol-types';
import ApiService from '../../services/api-service';

interface Props {
  id?: string;
  productId?: string;
  currentLength?: number;
  currentQuantity?: number;
  componentId?: string;
}

const OfferProduct = ({
  id,
  productId,
  currentLength,
  currentQuantity,
  componentId,
}: Props) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<ProductDetails[]>([]);
  const [product, setProduct] = useState<string | null>(productId || null); //product id
  const [quantity, setQuantity] = useState<number>(currentQuantity || 1);
  const [length, setLength] = useState<number | null>(currentLength || null);

  const getProducts = async () => {
    await ApiService.callFetch(
      'GET',
      'product/list-raw',
      (data: ProductDetails[]) => {
        setProducts(data);
      }
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (product && getSelectedProduct(product)?.type === ProductType.STANDARD) {
      setLength(null);
    }
  }, [product]);

  const getSelectedProduct = (id: string) => {
    return products.find((p) => p.id === id);
  };

  if (!products.length) return null;

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle
          title={
            productId ? 'offer.change_product_profile' : 'offer.add_product'
          }
        />
      </ModalHeader>
      {!productId && <p>{__('offer.add_product_description')}</p>}

      <InputSelect
        options={products.map((p) => ({ label: p.name, value: p.id }))}
        onChange={(productId: string) => {
          setProduct(productId);
        }}
        label="application.name_label"
        placeholder={'product.select'}
        value={product}
      />
      <FieldsGroup>
        {product && (
          <Input
            type="number"
            step={0.01}
            max={9999}
            min={0.01}
            label="application.quantity"
            input={{
              value: quantity,
              onChange: (e: ChangeEvent<any>) => {
                setQuantity(e.currentTarget.value);
              },
            }}
          />
        )}
        {product && getSelectedProduct(product)?.type === ProductType.PROFILE && (
          <Input
            type="number"
            step={0.01}
            max={9999}
            min={0.01}
            label="application.length"
            input={{
              value: length,
              onChange: (e: ChangeEvent<any>) => {
                setLength(e.currentTarget.value);
              },
            }}
          />
        )}
      </FieldsGroup>
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => {
            dispatch(modal.hideModal());
          }}
          text={'application.cancel'}
          danger
          small
        />
        <Button
          className="button-modal"
          click={() => {
            if (product) {
              const fullProduct = products.find((p) => p.id === product);
              if (fullProduct) {
                if (id && componentId) {
                  dispatch(
                    offer.changeOfferProduct(
                      componentId,
                      id,
                      fullProduct,
                      Number(quantity),
                      Number(length)
                    )
                  );
                } else {
                  dispatch(
                    offer.addProductToOffer(
                      fullProduct,
                      Number(quantity),
                      Number(length)
                    )
                  );
                }
              }
              dispatch(modal.hideModal());
            }
          }}
          disabled={!quantity || quantity <= 0 || !product}
          small
          text={'application.save'}
          primary
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default OfferProduct;
