export enum FieldType {
  Text,
  Custom,
  Url,
  Email,
  Tel,
  Img,
  Date,
  Boolean,
  Price,
  Percent,
}

interface TableButton {
  name: string;
  type: string;
  to?: string;
  click?: any;
}

export interface TableFieldSearchableValues {
  [key: string]: string;
  [key: number]: string;
}

export interface TableSchemaField {
  name: string;
  field: string;
  type?: FieldType; // defaults to Text
  customValue?: (values: any, field: TableSchemaField) => any;
  customRender?: (value: any, field: TableSchemaField, allValues: any) => any;
  mobile?: boolean;
  link?: boolean;
  sortable?: boolean;
  searchable?: boolean;
  defaultSort?: boolean;
  oppositeSortDir?: boolean;
  buttons?: TableButton[];
  searchableValues?: TableFieldSearchableValues;
  defaultSearch?: string | number;
  usesI18n?: boolean;
  alias?: string;
}

export interface TableSchema {
  countPerPage: number;
  fields: TableSchemaField[];
}

export interface TableQuery {
  searchBy?: string;
  searchQuery?: string;
  page?: number;
  sortBy?: string;
  sortDirBack?: boolean;
  alternative?: boolean;
}

export interface TableResponse<T> {
  countTotal: number;
  items: T[];
}
