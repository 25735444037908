import clsx from 'clsx';
import { Block, BlockType, I18nEntry, SelectOption } from 'profilpol-types';
import React from 'react';
import { Input } from '../..';
import { __ } from '../../../../services/translation';
import InputSelect from '../../InputSelect';
import CONFIG from '../../../../config/app';
import './SingleBlock.scss';

interface Props {
  block: Block;
  onClick: (block: Block) => void;
  deleteBlock: (block: Block) => void;
  updateBlock: (block: Block) => void;
  active: Block | null;
  options: SelectOption[];
  startDrag: (block: Block) => void;
  endDrag: (e: any) => void;
}

const SingleBlock = ({
  deleteBlock,
  updateBlock,
  block,
  onClick,
  active,
  options,
  startDrag,
  endDrag,
}: Props) => {
  const getContent = () => {
    switch (block.type) {
      case BlockType.IF:
        return __('blocks.if');
      case BlockType.ADD:
        return __('blocks.add');
      case BlockType.SUBSTRACT:
        return __('blocks.substract');
      case BlockType.MULTIPLE:
        return __('blocks.multiply');
      case BlockType.DIVIDE:
        return __('blocks.divide');
      case BlockType.EQUALS:
        return __('blocks.equals');
      case BlockType.MORE_THAN:
        return __('blocks.more_than');
      case BlockType.MORE_OR_EQUAL:
        return __('blocks.more_or_equal');
      case BlockType.LESSER_THAN:
        return __('blocks.lesser_than');
      case BlockType.LESSER_OR_EQUAL:
        return __('blocks.lesser_or_equal');
      case BlockType.OR:
        return __('blocks.or');
      case BlockType.AND:
        return __('blocks.and');
      case BlockType.TRUE:
        return __('blocks.true');
      case BlockType.FALSE:
        return __('blocks.false');
      case BlockType.NULL:
        return __('blocks.null');
      case BlockType.DIFFERENT:
        return __('blocks.different');
      case BlockType.ROUND:
        return __('blocks.round');
      case BlockType.ROUND_DOWN:
        return __('blocks.round_down');
      case BlockType.ROUND_UP:
        return __('blocks.round_up');
      case BlockType.NOT:
        return __('blocks.not');
      case BlockType.FIELD:
        return (
          <InputSelect
            compact
            value={block.value}
            noMargin
            onChange={(val: any) => handleBlockUpdate(val)}
            options={options.map((o) => ({ label: o.name, value: o.value }))}
            placeholder="application.select"
          />
        );
      case BlockType.FIELD_NAME:
        return (
          <InputSelect
            compact
            value={block.value}
            noMargin
            label={'blocks.field_name'}
            onChange={(val: any) => handleBlockUpdate(val)}
            options={options.map((o) => ({ label: o.name, value: o.value }))}
            placeholder="application.select"
          />
        );
      case BlockType.VALUE:
        return (
          <Input
            nomargin
            compact
            type="number"
            value={block.value}
            step={0.001}
            placeholder="application.value"
            input={{
              onChange: (e: any) => handleBlockUpdate(Number(e.target.value)),
              value: block.value,
            }}
          />
        );
      case BlockType.TEXT:
        return (
          <Input
            nomargin
            compact
            type="text"
            value={block.value}
            placeholder="application.value"
            input={{
              onChange: (e: any) => handleBlockUpdate(e.target.value),
              value: block.value,
            }}
          />
        );
      case BlockType.TEXT_I18N:
        const languages = CONFIG.LANGUAGES;
        return (
          <div className="text-group">
            {languages.map((lang) => (
              <Input
                nomargin
                compact
                type="text"
                value={((block.value || {}) as I18nEntry)[lang]}
                placeholder="application.value"
                label={lang}
                input={{
                  onChange: (e: any) => handleBlockUpdate(e.target.value, lang),
                  value: ((block.value || {}) as I18nEntry)[lang],
                }}
              />
            ))}
          </div>
        );

      case BlockType.GROUP:
        return <div className="group-block"></div>;
    }
  };

  const handleBlockUpdate = (
    value: string | number | SelectOption,
    lang?: string
  ) => {
    if (lang) {
      const newValue = typeof value === 'object' ? value.value : value;
      const newBlock = {
        ...block,
      };

      if (!newBlock.value) newBlock.value = {};

      (newBlock.value as I18nEntry)[lang] = newValue.toString();

      updateBlock(newBlock);
    } else {
      const newValue = typeof value === 'object' ? value.value : value;
      const newBlock = { ...block, value: newValue };
      updateBlock(newBlock);
    }
  };

  const handleBlockClick = (e: any) => {
    if (block.type !== BlockType.GROUP) return;
    e.stopPropagation();
    onClick(block);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();
    deleteBlock(block);
  };

  return (
    <div
      className={clsx(`single-block`, {
        group: block.type === BlockType.GROUP,
        'active-dropzone': active && active.uuid === block.uuid,
      })}
      draggable
      onClick={handleBlockClick}
      onDragOver={(e) => {
        if (block.type !== BlockType.GROUP) return;
        onClick(block);
        e.stopPropagation();
      }}
      onDragStart={(e) => {
        startDrag(block);
        e.stopPropagation();
      }}
    >
      <div className="content">
        {getContent()}
        {block.blocks &&
          block.blocks.map((b, index) => {
            return (
              <>
                {block.type === BlockType.IF && index === 1 && (
                  <span>{__('blocks.if_yes')}</span>
                )}
                {block.type === BlockType.IF && index === 2 && (
                  <span>{__('blocks.if_no')}</span>
                )}
                <SingleBlock
                  key={block.uuid}
                  options={options}
                  active={active}
                  block={b}
                  onClick={onClick}
                  deleteBlock={deleteBlock}
                  startDrag={startDrag}
                  endDrag={endDrag}
                  updateBlock={updateBlock}
                />
              </>
            );
          })}
      </div>
      {!block.denyDelete && (
        <button
          type="button"
          className="delete-block"
          onClick={handleDeleteClick}
        >
          &times;
        </button>
      )}
    </div>
  );
};
export default SingleBlock;
