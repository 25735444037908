import React from 'react';
import Input from '../Input';
import { __ } from '../../../services/translation';
import FileInput from '../FileInput';
import DateInput from '../DateInput';
import { parseInputNumber } from '../../../utils/parse-input-number';
import { parseDateInput } from '../../../utils/parse-date-input';
import { formOnTranslateString } from '../../../utils/trans-form';

import { SelectOption } from '../../../types/select-option';
import SectionHeader from '../SectionHeader';
import { InputSelect } from '..';
import clsx from 'clsx';
import GraphEditor from '../GraphEditor';

import './FieldsMapper.scss';
import { Block, SelectOptionI18n } from 'profilpol-types';

const formatValue = (
  value: string | number | boolean,
  type: string,
  multiple?: boolean
) => {
  switch (type) {
    case 'number':
      return parseInputNumber(value);
    case 'time':
      return parseInputNumber(value);
    case 'distance':
      return parseInputNumber(value);
    case 'cost':
      return parseInputNumber(value);
    case 'percent':
      return parseInputNumber(value);
    case 'dateTime':
      return parseDateInput(value, multiple);
    case 'date':
      return parseDateInput(value, multiple);
    default:
      return value;
  }
};

const getType = (type: string) => {
  if (type === 'staticSelect') return 'select';
  return type;
};

const FieldsMapper = (
  field: any,
  errors: any,
  fieldHtmlId: any,
  currentValue: any,
  onChange: any,
  onFocus: any,
  onBlur: any,
  options?: {
    recipeId?: string;
  }
) => {
  if (['groupStart', 'groupEnd', 'variablePlaceholder'].includes(field.type))
    return <></>;
  else if (field.type === 'header') {
    return <SectionHeader title={field.title} />;
  } else if (field.type === 'dateTime' || field.type === 'date') {
    return (
      <DateInput
        key={fieldHtmlId}
        label={field.title}
        required={field.required}
        readonly={field.readOnly}
        input={{
          name: fieldHtmlId,
          id: fieldHtmlId,
          placeholder: field.placeholder,
          value: currentValue,
          onChange: (value: any) => {
            onChange(
              formatValue(
                value,
                field.options.type || field.type,
                field.options.multiple
              )
            );
          },
          onFocus,
          onBlur,
        }}
        multiple={field.options.multiple}
        placeholder={field.placeholder}
        meta={{
          touched: errors.length > 0,
          error: errors.length
            ? formOnTranslateString(
                errors[0].messageWithPlaceholders,
                errors[0].params
              )
            : null,
        }}
      />
    );
  } else if (field.type === 'fileDragDrop') {
    return (
      <FileInput
        key={fieldHtmlId}
        accept={field.options.accept}
        hasImage={field.options.previewUrl}
        input={{
          name: fieldHtmlId,
          id: fieldHtmlId,
          value: currentValue,
          onChange,
          onFocus,
          onBlur,
        }}
        meta={{
          touched: errors.length > 0,
          error: errors.length
            ? formOnTranslateString(
                errors[0].messageWithPlaceholders,
                errors[0].params
              )
            : null,
        }}
      />
    );
  } else if (['checkbox'].includes(field.type)) {
    return (
      <div
        key={fieldHtmlId}
        className={clsx(
          'field',
          `field-${getType(field.options.type || field.type)}`
        )}
      >
        <Input
          type={field.options.type || field.type}
          readonly={field.readOnly}
          required={field.required}
          label={field.title}
          input={{
            name: fieldHtmlId,
            id: fieldHtmlId,
            placeholder: field.placeholder,
            value: currentValue,
            onChange: (e: any) =>
              onChange(formatValue(e.target.checked, 'checkbox')),
            onFocus,
            onBlur,
          }}
          placeholder={field.placeholder}
          meta={{
            touched: errors.length > 0,
            error: errors.length
              ? formOnTranslateString(
                  errors[0].messageWithPlaceholders,
                  errors[0].params
                )
              : null,
          }}
        />
      </div>
    );
  } else if (['staticSelect'].includes(field.type)) {
    return (
      <div className="field" key={fieldHtmlId}>
        <InputSelect
          readonly={field.readOnly}
          label={field.title}
          multiple={field.options && field.options.multipleChoices}
          options={
            field.options.possibleValues &&
            field.options.possibleValues.map(
              (
                val: string | SelectOption | SelectOptionI18n,
                index: number
              ) => {
                if (typeof val === 'object') {
                  return {
                    label: __(val.name),
                    value: val.value,
                  };
                }
                return {
                  label: __(val),
                  value: index,
                };
              }
            )
          }
          onChange={(e: any) => {
            onChange(e);
          }}
          value={currentValue}
          placeholder={field.placeholder}
          meta={{
            touched: errors.length > 0,
            error: errors.length
              ? formOnTranslateString(
                  errors[0].messageWithPlaceholders,
                  errors[0].params
                )
              : null,
          }}
        />
      </div>
    );
  } else if (
    field.type === 'custom' &&
    ['value', 'condition', 'quantity', 'length'].includes(field.id) &&
    options &&
    options.recipeId
  ) {
    return (
      <GraphEditor
        key={fieldHtmlId}
        recipeId={options.recipeId}
        label={field.title}
        onChange={(val: Block[]) => {
          onChange(val);
        }}
        initialValue={currentValue || []}
      />
    );
  } else if (!['fileDragDrop', 'custom'].includes(field.type)) {
    return (
      <div
        key={fieldHtmlId}
        className={clsx(
          'field',
          `field-${getType(field.options.type || field.type)}`
        )}
      >
        <Input
          type={getType(field.options.type || field.type)}
          readonly={field.readOnly}
          required={field.required}
          options={
            field.options.possibleValues &&
            field.options.possibleValues.map(
              (val: string | SelectOption, index: number) => {
                if (typeof val === 'object') {
                  return {
                    name: __(val.name),
                    value: val.value,
                  };
                }
                return {
                  name: __(val),
                  value: index,
                };
              }
            )
          }
          label={field.title}
          input={{
            name: fieldHtmlId,
            id: fieldHtmlId,
            placeholder: field.placeholder,
            value: currentValue,
            onChange: (e: any) => {
              onChange(
                formatValue(e.target.value, field.options.type || field.type)
              );
            },
            onFocus,
            onBlur,
          }}
          step={field.options.step}
          placeholder={field.placeholder}
          meta={{
            touched: errors.length > 0,
            error: errors.length
              ? formOnTranslateString(
                  errors[0].messageWithPlaceholders,
                  errors[0].params
                )
              : null,
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default FieldsMapper;
