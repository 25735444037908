import { FieldType, TableSchema } from '../types';

const currencyToString: {
  [key: number]: string;
} = {
  0: 'PLN',
  1: 'EUR',
};

export const clientsTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'client.id',
      field: 'id',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },

    {
      name: 'client.company_name',
      field: 'companyName',
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'client.payment_type',
      field: 'paymentType',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: (value) => currencyToString[value.defaultCurrency],
      searchableValues: currencyToString,
    },
    {
      name: 'client.currency',
      field: 'defaultCurrency',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: (value) => currencyToString[value.defaultCurrency],
      searchableValues: currencyToString,
    },
    {
      name: 'client.margin',
      field: 'defaultMargin',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'client.first_name',
      field: 'firstName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'client.last_name',
      field: 'lastName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
  ],
};
