import ApiService from "../services/api-service";
import { notifications } from "../actions";
import { history } from "../App";
export const logout = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    "LOGOUT",
    "LOGOUT",
    () => {
      history.push("/");
      dispatch(notifications.successNotification("logout_successful!"));
      dispatch({ type: "REFRESH_API_TABLE" });
      dispatch({ type: "REFRESH_API_GRID" });
    },
    null,
    "auth/logout"
  );
};

export const clearUserSession = () => ({ type: "CLEAR_USER_SESSION" });
