import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modal as modalActions } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';
import { ModalState } from '../../../../reducers/modal-reducer';

import './ModalHeader.scss';

interface Props {
  modal: ModalState;
  children: React.ReactNode;
  hideModal: () => void;
  navigation?: boolean;
}

const ModalHeader = ({ children, modal, hideModal, navigation }: Props) => (
  <div className="modal-header">
    {children}
    {modal.isClosable && (
      <button type="button" className={`modal-exit ${navigation ? 'navigation' : ''}`} onClick={hideModal}>
        &times;
      </button>
    )}
  </div>
);

const mapStateToProps = (state: ApplicationState) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ hideModal: modalActions.hideModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
