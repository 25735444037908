import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { __ } from '../../../services/translation';
import './Alert.scss';

interface AlertProps {
  type: 'error' | 'success' | 'notice';
  text: string;
  simple?: boolean;
  notification?: boolean;
  list?: boolean;
}

const Alert = ({ type, simple, notification, text, list }: AlertProps) => (
  <div
    className={`alert alert-${type} ${simple ? 'alert-simple' : ''} ${
      notification ? 'notification' : ''
    } ${list ? 'list' : ''}`}
  >
    {type === 'error' && (
      <FontAwesomeIcon className="alert-icon" icon={faExclamationCircle} />
    )}
    {type === 'notice' && (
      <FontAwesomeIcon className="alert-icon" icon={faExclamationCircle} />
    )}
    {type === 'success' && (
      <FontAwesomeIcon className="alert-icon" icon={faCheckCircle} />
    )}
    {__(text)}
  </div>
);

export default Alert;
