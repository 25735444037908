export enum ApiErrorCode {
  OtherError,
  InvalidLoginData,
  InvalidOldPass,
  UserExists,
  UserNotFound,
  FormValidationError,
  UserHasThisStatusNow,
  UpdateTokenExpired,
  NoSuchObjectWithThisId,
  ObjectNotUnique,
  ClientNotFound,
  ClientAlreadyExists,
  PaymentTypeNotFound,
  LanguageNotSupported,
  SalesmanNotFound,
  SalesmanAlreadyExists,
  AccountIsBlocked,
  AccountIsNotActivated,
  ServiceNotFound,
  ProductNotFound,
  SettingNotFound,
  CantAssignSameAsSubproduct,
  CantAssignParentProductAsSubproduct,
  NeedAtLeastOneContact,
  NoMainContact,
  OnlyOneMainContactAllowed,
  RecipeNotFound,
  RecipeFieldNotFound,
  NeedAtLeastOneOption,
  RecipeInfoNotFound,
  FileIsTooLarge,
  ForbiddenFileFormat,
  RecipeSectionNotFound,
  RecipeComponentNotFound,
  ImageIsRequired,
  OfferNotFound,
  CannotDeleteOfferWithThisStatus,
  RecipeProductNotFound,
  RecipeServiceNotFound,
  CannotFetchSettings,
  RecipeDescriptionNotFound,
  CantRemoveSalesmanHasOffers,
}
