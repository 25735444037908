import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { Route, Router, Redirect, Switch, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import flatpickr from 'flatpickr';
import { WorkBoxProvider } from 'react-workbox';
import i18next from 'i18next';
import reducers from './reducers';
import { RouteUnauthorized, RouteAdmin } from './route';

import {
  Modal,
  LocaleWrapper,
  OnlineContent,
  OfflineContent,
  AppOffline,
  UpdateInfoBar,
  Spinner,
} from './components/Common';
import { appStatus } from './actions';
import { NotificationContainer } from './components/Notifications';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';

import { AppWrapper } from './components/Layout';
import { scrollTop } from './utils/scroll-top';
import ErrorBoundary from './components/Common/ErrorBoundary';

import './index.scss';
import OfferForClient from './views/OfferForClient';
import RouteAuthorized from './route/RouteAuthorized';

const Login = React.lazy(() => import('./views/Login'));
const PageNotFound = React.lazy(() => import('./views/PageNotFound'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const Admin = React.lazy(() => import('./views/Admin'));
// const Offer = React.lazy(() => import('./components/Offer'));

// flatpickr.localize(Polish);
flatpickr.defaultConfig.time_24hr = true;
flatpickr.defaultConfig.disableMobile = true;
flatpickr.defaultConfig.allowInput = true;

export { flatpickr };
export { ReactSelect, ReactCreatableSelect };

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
export const store = createStoreWithMiddleware(reducers);

i18next.init({
  interpolation: { escapeValue: false },
});

export const history = createBrowserHistory({ basename: '/' });

history.listen((l) => scrollTop());

const App = () => (
  <WorkBoxProvider>
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <OnlineContent>
            <NotificationContainer />
            <LocaleWrapper>
              <UpdateInfoBar />
              <AppWrapper full>
                <Suspense fallback={<Spinner overlay />}>
                  <Routing />
                </Suspense>
              </AppWrapper>
              <Modal />
            </LocaleWrapper>
          </OnlineContent>
        </ErrorBoundary>
        <OfflineContent>
          <AppOffline />
        </OfflineContent>
      </Router>
    </Provider>
  </WorkBoxProvider>
);

const Routing = () => {
  return (
    <Switch>
      <RouteUnauthorized
        exact
        path="/"
        render={() => <Redirect to="/login" />}
      />
      <RouteUnauthorized exact path="/login" component={Login} />
      <RouteAuthorized path="/panel" component={Admin} />
      <Route path="/offer/:accessToken/:offerId" component={OfferForClient} />
      <Route path="/restore-pass" component={ResetPassword} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

const listenToWindowEvent = (name: string, mapEventToAction: any) => (
  dispatch: any
) => {
  const handleEvent = (e: any) => {
    dispatch(mapEventToAction(e));
  };
  window.addEventListener(name, handleEvent);
};

store.dispatch(listenToWindowEvent('offline', appStatus.setStatus));

store.dispatch(listenToWindowEvent('online', appStatus.setStatus));

store.dispatch(
  appStatus.setStatus({ type: window.navigator.onLine ? 'online' : 'offline' })
);

export default App;
