import { FieldType, TableSchema } from '../types';

export const productTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'product.name',
      field: 'name',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      defaultSort: true,
    },
    {
      name: 'product.code',
      field: 'code',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      defaultSort: false,
    },
    {
      name: 'product.price',
      field: 'price',
      searchable: true,
      sortable: true,
      type: FieldType.Price,
    },
    {
      name: 'product.weight',
      field: 'weight',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
  ],
};
