import { FieldType, TableSchema } from '../types';

export const serviceTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'service.name',
      field: 'name',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      defaultSort: true,
    },
    {
      name: 'service.price',
      field: 'price',
      searchable: true,
      sortable: true,
      type: FieldType.Price,
    },
  ],
};
