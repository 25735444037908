import React, { useState } from "react";
import { TableFieldSearchableValues, TableSchemaField } from "profilpol-tables";
import { __ } from "../../services/translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import "./SearchBar.scss";

interface Props {
  allFields: TableSchemaField[];
  search: { searchBy: string; searchQuery: string };
  sort: { sortBy: string; sortDirBack: boolean } | null;
  onSearchChange: (searchBy: string, searchQuery: string) => void;
  onSortChange?: (sortBy: string, sortDirection: boolean) => void;
  padded?: boolean;
  inModal?: boolean;
}

interface State {
  searchInputVisibility: boolean;
  sortInputVisibility: boolean;
}

class SearchBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchInputVisibility: false,
      sortInputVisibility: false,
    };
    this.showInputSection = this.showInputSection.bind(this);
  }

  private showInputSection(name: string) {
    switch (name) {
      case "search":
        return this.setState({
          searchInputVisibility: !this.state.searchInputVisibility,
        });
      case "sort":
        return this.setState({
          sortInputVisibility: !this.state.sortInputVisibility,
        });
    }
  }

  private handleSearchQueryChange = (searchQuery: string) => {
    const { onSearchChange, search } = this.props;
    onSearchChange(search.searchBy, searchQuery);
  };

  private handleSearchByChange = (searchBy: string) => {
    const { onSearchChange, search } = this.props;
    onSearchChange(searchBy, search.searchQuery);
  };

  private handleSortByChange = (sortBy: string) => {
    const { onSortChange } = this.props;
    if (typeof onSortChange !== "undefined") {
      const sortParsed = JSON.parse(sortBy);
      onSortChange(sortParsed.value, sortParsed.dir);
    }
  };

  private getSearchElement = () => {
    const { allFields, search } = this.props;

    const fields = allFields.find(
      (field) => field.field === search.searchBy
    ) as TableSchemaField;
    const searchableValues = fields ? fields.searchableValues : null;

    if (search.searchBy === "") {
      return null;
    } else if (searchableValues && typeof searchableValues === "object") {
      return (
        <select
          className="toolbar-input"
          placeholder={__("search.select_value")}
          onChange={(e) => this.handleSearchQueryChange(e.target.value)}
        >
          {Object.keys(searchableValues as TableFieldSearchableValues).map(
            (key) => (
              <option value={key} key={key}>
                {__(searchableValues[key])}
              </option>
            )
          )}
        </select>
      );
    } else {
      return (
        <input
          className="toolbar-input"
          type="text"
          placeholder={__("search.enter_requested_value")}
          value={search.searchQuery}
          onChange={(e) => this.handleSearchQueryChange(e.target.value)}
        />
      );
    }
  };

  render() {
    const { inModal, search, allFields, sort } = this.props;
    const { searchInputVisibility, sortInputVisibility } = this.state;
    return (
      <div className={`search-bar ${inModal ? "in-modal" : ""}`}>
        <div className={clsx("search-container", { "search-input-visibility": searchInputVisibility })}>
          <div className="search-icon-wrapper" onClick={() => this.showInputSection("search")}>
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
          </div>

            <div className="search-section">
              <div className="search-header-cont">
                <label className="search-header" htmlFor="search">
                  {__("search.header")}
                </label>
              </div>
              <div className={`search-fields ${inModal ? "in-modal" : ""}`}>
                <div className="search-field">
                  <select
                    id="search"
                    name="search"
                    className="toolbar-input"
                    value={search.searchBy}
                    onChange={(e) => this.handleSearchByChange(e.target.value)}
                  >
                    {search.searchBy === "" ? (
                      <option value="">{__("search.select_field")}</option>
                    ) : null}
                    {allFields
                      .filter((field) => field.searchable === true)
                      .map((field) => (
                        <option value={field.field} key={field.field}>
                          {__(field.name)}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={clsx("search-query", {"active": search.searchBy})}>{this.getSearchElement()}</div>
              </div>
            </div>
        </div>
        {sort && (
          <div className={clsx("sort-container", { "sort-input-visibility": sortInputVisibility })}>
            <div className="search-icon-wrapper" onClick={() => this.showInputSection("sort")}>
              <FontAwesomeIcon icon={faSort} className="search-icon" />
            </div>
              <div className={clsx("sort-section", {"sort-input-visibility": sortInputVisibility})}>
                <div className="search-header-cont">
                  <label htmlFor="sort" className="search-header">
                    {__("sort.header")}
                  </label>
                </div>
                <div className={`search-fields ${inModal ? "in-modal" : ""}`}>
                  <div className="search-field">
                    <select
                      name="sort"
                      id="sort"
                      className="toolbar-input"
                      value={JSON.stringify({
                        value: sort.sortBy,
                        dir: sort.sortDirBack,
                      })}
                      onChange={(e) => this.handleSortByChange(e.target.value)}
                    >
                      {sort.sortBy === "" ? (
                        <option value="">{__("sort.select_method")}</option>
                      ) : null}
                      {allFields
                        .filter((field) => field.sortable === true)
                        .map((field) => (
                          <React.Fragment key={field.field}>
                            <option
                              value={JSON.stringify({
                                value: field.field,
                                dir: true,
                              })}
                              key={`${field.field}_desc`}
                            >
                              {__(field.name)} {__("sort.descending")}
                            </option>
                            <option
                              value={JSON.stringify({
                                value: field.field,
                                dir: false,
                              })}
                              key={`${field.field}_asc`}
                            >
                              {__(field.name)} {__("sort.ascending")}
                            </option>
                          </React.Fragment>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
          </div>
        )}
      </div>
    );
  }
}

export default SearchBar;
