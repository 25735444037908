import React from 'react';

export const showModal = (
  content: React.ReactNode,
  isClosable = true,
  isFullscreen = false,
  onClosed?: () => void
) => (dispatch: any) => {
  dispatch({
    type: 'PREPARE_MODAL',
    payload: { content, isClosable, isFullscreen },
  });
  setTimeout(() => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: { content, isClosable, isFullscreen, onClosed },
    });
  }, 50);
};

export const hideModal = (onClose?: () => void) => (dispatch: any) => {
  dispatch({ type: 'HIDE_MODAL' });
  setTimeout(() => {
    dispatch({ type: 'UNPREPARE_MODAL' });
    if (typeof onClose === 'function') onClose();
  }, 300);
};

export const loadingModal = (loading: boolean) => ({
  type: 'LOADING_MODAL',
  payload: loading,
});
