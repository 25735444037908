import { isAfter, isBefore } from 'date-fns';
import { ShareOfferResponse } from 'profilpol-types';
import React from 'react';
import { Alert, Button } from '../../../components/Common';
import { __ } from '../../../services/translation';
import { formatDateTime } from '../../../utils/format-date';

import './StatusInfo.scss';

interface Props {
  status: ShareOfferResponse;
}

const StatusInfo = ({ status }: Props) => {
  const isValid = () => {
    if (!status.validToDate) return false;
    if (isAfter(new Date(status.validToDate), new Date())) {
      return true;
    }
  };

  return (
    <div className="share-status">
      {isValid() ? (
        <div className="status-shared">
          <Alert type="notice" text="offer.shared" />
          <div className="status-shared-info">
            <span>
              {__('offer.valid_to')}:{' '}
              <strong>{formatDateTime(status.validToDate)}</strong>
            </span>
            <Button
              primary
              small
              blank
              to={`/offer/${status.accessToken}/${status.offerId}`}
              text="offer.see_offer"
            />
          </div>
        </div>
      ) : (
        <Alert type="notice" text="offer.not_shared" />
      )}
    </div>
  );
};

export default StatusInfo;
