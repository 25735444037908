import clsx from 'clsx';
import React, { useRef, useEffect, useState } from 'react';
import { ReactSelect } from '../../../App';
import { FullScreenService } from '../../../services/full-screen-service';
import { __ } from '../../../services/translation';

interface Props {
  options: { label: string; value: string | number }[];
  onChange: any;
  value: any;
  placeholder: any;
  label?: string;
  multiple?: boolean;
  noMargin?: boolean;
  compact?: boolean;
  readonly?: boolean;
  allowCustomValue?: boolean;
  meta?: {
    error?: string | null;
    touched?: boolean | null;
  };
}

const InputSelect = ({
  options,
  value,
  onChange,
  placeholder,
  multiple,
  meta,
  compact,
  noMargin,
  readonly,
  label,
  allowCustomValue,
}: Props) => {
  const selector = useRef<any>(null);

  const handleBlur = () => {
    const { inputValue, value } = selector.current.state;
    if (inputValue)
      selector.current.onChange(
        inputValue ? { label: inputValue, value: inputValue } : value
      );
  };

  const handleClick = (e: any) => {
    if (e.keyCode === 13) {
      const { inputValue, value } = selector.current.state;
      selector.current.onChange(
        inputValue ? { label: inputValue, value: inputValue } : value
      );
    }
  };

  const labelLine = label && <label>{label ? __(label) : ''}</label>;

  const getDefaultValue = () => {
    if (multiple) {
      return value
        ? value.map((val: string) => {
            return options.find((opt) => opt.value === val);
          })
        : [];
    } else {
      const found = options.find((opt) => opt.value === value);
      if (found) return found;

      if (!found && allowCustomValue) {
        return { label: value, value };
      }
    }
  };

  const handleChange = (val: null | any[] | any) => {
    if (multiple) onChange(val || []);
    else if (typeof val !== 'undefined' || val !== null) onChange(val.value);
    else onChange(null);
  };

  return (
    <div className={clsx('input-container', { nomargin: noMargin, compact })}>
      {labelLine}
      <ReactSelect
        menuPortalTarget={
          FullScreenService.isFullScreen() ? null : document.body
        }
        menuPlacement="auto"
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isDisabled={readonly}
        defaultValue={getDefaultValue()}
        className={clsx('input-select', { compact })}
        classNamePrefix="input-select"
        inputId="input-select-field"
        onChange={handleChange}
        options={options}
        onBlurResetsInput={false}
        onBlur={handleBlur}
        onKeyDown={handleClick}
        formatCreateLabel={() => false}
        isValidNewOption={() => false}
        noOptionsMessage={() => null}
        isClearable
        creatable={true}
        isMulti={multiple}
        placeholder={placeholder ? __(placeholder) : ''}
        ref={selector}
      />
      {meta && meta.error && meta.touched && (
        <small className="text-danger">{meta.error}</small>
      )}
    </div>
  );
};

export default InputSelect;
