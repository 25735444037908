import * as React from 'react';
import './Spinner.scss';

interface Props {
  overlay?: boolean;
  small?: boolean;
  transparent?: boolean;
  halfTransparent?: boolean;
  dark?: boolean;
}

const Spinner = ({
  overlay,
  small,
  halfTransparent,
  transparent,
  dark,
}: Props) => (
  <div
    className={`spinner-container ${overlay ? 'overlay' : ''} ${
      small ? 'small' : ''
    } ${transparent ? 'transparent' : ''} ${
      halfTransparent ? 'half-transparent' : ''
    } ${dark ? 'dark' : ''}  `}
  >
    <div className="spinner" />
  </div>
);

export default Spinner;
