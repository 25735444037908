import {
  faClone,
  faPenAlt,
  faPlus,
  faStore,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { RecipeComponentDetails, RecipeSectionDetails } from 'profilpol-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LineTab, RecipeTab } from '..';
import { modal, notifications, apiList, recipes } from '../../../../actions';
import {
  Confirmation,
  RecipeComponent,
  RecipeProduct,
  RecipeSection,
  RecipeService,
} from '../../../../modals';
import RecipeProfile from '../../../../modals/RecipeProfile';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../services/translation';
import { ButtonsContainer, Wrapper } from '../../../Layout';
import Alert from '../../Alert';
import Button from '../../Button';

import './ProductTab.scss';

interface Props {
  recipeId: string;
  item: RecipeComponentDetails;
}

const ProductTab = ({ recipeId, item }: Props) => {
  const dispatch = useDispatch();
  const confirmDelete = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="recipe.confirm_component_delete"
          confirmCallback={() => deleteComponent()}
        />
      )
    );
  };

  const deleteComponent = async () => {
    await ApiService.callFetch('DELETE', `recipe/component/${item.id}`, () => {
      dispatch(notifications.successNotification('recipe.component_deleted'));
      dispatch(apiList.refreshApiList());
      dispatch(modal.hideModal());
      dispatch(recipes.getRecipe(recipeId));
    });
  };

  const editComponent = (item: RecipeComponentDetails) => {
    dispatch(
      modal.showModal(<RecipeComponent recipeId={recipeId} id={item.id} />)
    );
  };

  const addProduct = () => {
    dispatch(
      modal.showModal(
        <RecipeProduct componentId={item.id} recipeId={recipeId} />
      )
    );
  };

  const addProfile = () => {
    dispatch(
      modal.showModal(
        <RecipeProfile componentId={item.id} recipeId={recipeId} />
      )
    );
  };

  const addService = () => {
    dispatch(
      modal.showModal(
        <RecipeService recipeId={recipeId} componentId={item.id} />
      )
    );
  };

  const buttons = [
    {
      text: 'application.edit',
      icon: faPenAlt,
      click: editComponent,
      primary: true,
    },
    {
      text: 'application.delete',
      icon: faTrash,
      click: confirmDelete,
      danger: true,
    },
  ];

  const products =
    item.products.length > 0 ? (
      [...item.products]
        .sort((a, b) => a.position - b.position)
        .map((product) => {
          return (
            <LineTab
              type="product"
              componentId={item.id}
              recipeId={recipeId}
              item={product}
              key={product.id}
              name={product.product.name}
            />
          );
        })
    ) : (
      <Alert type="notice" text="recipe.no_products" />
    );
  const services =
    item.services.length > 0 ? (
      [...item.services]
        .sort((a, b) => a.position - b.position)
        .map((service) => {
          return (
            <LineTab
              type="service"
              componentId={item.id}
              item={service}
              recipeId={recipeId}
              key={service.id}
              name={service.service.name}
            />
          );
        })
    ) : (
      <Alert type="notice" text="recipe.no_services" />
    );

  return (
    <>
      <RecipeTab
        buttons={buttons}
        icon={faStore}
        noMargin
        name={item.name}
        item={item}
        bold
      />
      <div className="component-details">
        <div className="product-section">
          <div className="component-subheader-container">
            <h3 className={clsx('product-section-header')}>
              {__('recipe.products')}
            </h3>
            <ButtonsContainer>
              <Button
                primary
                click={addProduct}
                text="recipe.new_product"
                small
                faIcon={faPlus}
                leftIcon
              />
              <Button
                primary
                click={addProfile}
                text="recipe.new_profile"
                small
                faIcon={faPlus}
                leftIcon
              />
            </ButtonsContainer>
          </div>
          <div className="product-list">{products}</div>
        </div>
        <div className="service-section">
          <div className="component-subheader-container">
            <h3 className={clsx('product-section-header')}>
              {__('recipe.services')}
            </h3>
            <ButtonsContainer>
              <Button
                primary
                click={addService}
                text="recipe.new_service"
                small
                faIcon={faPlus}
                leftIcon
              />
            </ButtonsContainer>
          </div>
          <div className="service-list">{services}</div>
        </div>
      </div>
    </>
  );
};

export default ProductTab;
