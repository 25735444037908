import { I18nEntry } from '../i18n';

export enum BlockType {
  IF,
  ADD,
  SUBSTRACT,
  MULTIPLE,
  DIVIDE,
  VALUE,
  TEXT,
  FIELD,
  GROUP,
  EQUALS,
  LESSER_THAN,
  LESSER_OR_EQUAL,
  MORE_THAN,
  MORE_OR_EQUAL,
  AND,
  OR,
  TRUE,
  FALSE,
  NULL,
  DIFFERENT,
  ROUND,
  ROUND_DOWN,
  ROUND_UP,
  NOT,
  FIELD_NAME,
  TEXT_I18N,
}

export interface Block {
  type: BlockType;
  uuid: string;
  value?: string | number | I18nEntry;
  blocks?: Block[];
  denyDelete?: boolean;
}
