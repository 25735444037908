import {
  I18nEntry,
  Languages,
  OfferClient,
  OfferForEdit,
  ProductDetails,
  RecipeDetails,
  RefreshPricesReq,
  ServiceDetails,
} from 'profilpol-types';
import ApiService from '../services/api-service';

export const setOfferClient = (client: OfferClient) => ({
  type: 'SET_OFFER_CLIENT',
  payload: client,
});

export const setOfferRecipe = (recipe: RecipeDetails) => ({
  type: 'SET_OFFER_RECIPE',
  payload: recipe,
});

export const setFieldValue = (fieldId: string, value: any) => ({
  type: 'UPDATE_FIELD_VALUE',
  payload: {
    field: fieldId,
    value,
  },
});

export const setOptionName = (fieldId: string, value: I18nEntry) => ({
  type: 'UPDATE_OPTION_NAME',
  payload: {
    field: fieldId,
    value,
  },
});

export const setItemQuantity = (fieldId: string, quantity: number) => ({
  type: 'UPDATE_ITEM_QUANTITY',
  payload: {
    field: fieldId,
    quantity,
  },
});

export const setItemMargin = (fieldId: string, margin: number) => ({
  type: 'UPDATE_ITEM_MARGIN',
  payload: {
    field: fieldId,
    margin,
  },
});

export const refreshData = (recipe: RecipeDetails) => ({
  type: 'REFRESH_DATA',
  payload: recipe,
});

export const getRecipeForOffer = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_RECIPE_FOR_OFFER_SUCCESS',
    'GET_RECIPE_FOR_OFFER_FAILED',
    null,
    null,
    `recipe/${id}`,
    'LOADING_RECIPE_FOR_OFFER'
  );
};

export const getRecipeForOfferEdit = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_RECIPE_FOR_OFFER_EDIT_SUCCESS',
    'GET_RECIPE_FOR_OFFER_EDIT_FAILED',
    null,
    null,
    `recipe/${id}`,
    'LOADING_RECIPE_FOR_OFFER'
  );
};

export const refreshPrices = (data: RefreshPricesReq) => async (dispatch: any) => {
  await ApiService.post(
    dispatch,
    'REFRESH_OFFER_PRICES_SUCCESS',
    'REFRESH_OFFER_PRICES_FAILED',
    null,
    null,
    `offer/refresh-prices`,
    'LOADING_REFRESHED_PRICES',
    data
  );
};

export const addProductToOffer = (
  product: ProductDetails,
  quantity: number,
  length?: number
) => ({
  type: 'ADD_PRODUCT_TO_OFFER',
  payload: {
    product,
    quantity,
    length
  },
});

export const changeOfferProduct = (
  componentId: string,
  id: string,
  product: ProductDetails,
  quantity: number,
  length?: number
) => ({
  type: 'CHANGE_OFFER_PRODUCT',
  payload: {
    id,
    componentId,
    product,
    quantity,
    length
  },
});

export const addServiceToOffer = (
  service: ServiceDetails,
  quantity: number
) => ({
  type: 'ADD_SERVICE_TO_OFFER',
  payload: {
    service,
    quantity,
  },
});

export const toggleService = (serviceId: string) => ({
  type: 'TOGGLE_SERVICE',
  payload: {
    serviceId,
  },
});

export const toggleProductService = (id: string) => ({
  type: 'TOGGLE_PRODUCT_SERVICE',
  payload: {
    id,
  },
});

export const deleteProduct = (productId: string) => ({
  type: 'DELETE_PRODUCT',
  payload: {
    productId,
  },
});

export const deleteService = (serviceId: string) => ({
  type: 'DELETE_SERVICE',
  payload: {
    serviceId,
  },
});

export const clearOffer = () => ({
  type: 'CLEAR_OFFER',
});

export const setOfferLanguage = (language: Languages) => ({
  type: 'SET_OFFER_LANGUAGE',
  payload: language
});


export const overrideOfferMargin = (newMargin: number) => ({
  type: 'OVERRIDE_OFFER_MARGIN',
  payload: {
    newMargin
  }
});

export const loadOffer = (offer: OfferForEdit) => ({
  type: 'LOAD_OFFER',
  payload: offer
})


export const setFormLock = (lock: boolean) => ({
  type: 'SET_FORM_LOCK',
  payload: lock
})