import { Block } from '../blocks';
import { I18nEntry } from '../i18n';

export interface RecipeInfoEntity {
  id: string;
  type: RecipeIntoType;
  title: I18nEntry;
  position: number;
  image?: string;
  value?: Block[];
  condition?: Block[];
}

export interface OfferInfo {
  id: string;
  type: RecipeIntoType;
  position: number;
  name: I18nEntry;
  value: string | number | boolean | null;
  image?: string;
}

export interface RecipeInfoDetails {
  id: string;
  type: RecipeIntoType;
  title: I18nEntry;
  position: number;
  image?: string;
  value?: Block[];
  condition?: Block[];
}

export interface CreateRecipeInfoReq {
  recipeId: string;
  title: string; //because is sent as FormData
  type: string; //because is sent as FormData
  position: number;
  image?: string;
  condition?: string;
  value?: string;
}

export enum RecipeIntoType {
  TEXT,
  NUMBER,
  IMAGE,
}
