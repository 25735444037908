import React from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface ModalPayload {
  content: React.ReactNode;
  isClosable: boolean;
  isFullscreen: boolean;
  doNotOverwrite: boolean;
  loading: boolean;
  onClosed?: () => void;
}

interface ShowModal {
  type: 'SHOW_MODAL';
  payload: ModalPayload;
}

interface HideModal {
  type: 'HIDE_MODAL';
}

interface PrepareModal {
  type: 'PREPARE_MODAL';
  payload: ModalPayload;
}

interface UnprepareModal {
  type: 'UNPREPARE_MODAL';
}

interface LoadingModal {
  type: 'LOADING_MODAL';
  payload: boolean;
}

type Action =
  | ShowModal
  | HideModal
  | PrepareModal
  | UnprepareModal
  | LoadingModal;

export interface ModalState {
  isVisible: boolean;
  isClosable: boolean;
  isPrepared: boolean;
  isUnprepared: boolean;
  isFullscreen: boolean;
  loading: boolean;
  content: React.ReactNode;
}

const initialState: ModalState = {
  isVisible: false,
  isClosable: true,
  isPrepared: false,
  isUnprepared: false,
  isFullscreen: false,
  loading: false,
  content: null,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'PREPARE_MODAL':
      if (state.isVisible && action.payload.doNotOverwrite === true) {
        return state;
      }

      return { ...state, isPrepared: true };
    case 'UNPREPARE_MODAL':
      return { ...state, isPrepared: false, content: null };
    case 'SHOW_MODAL':
      if (state.isVisible && action.payload.doNotOverwrite === true) {
        return state;
      }

      disableBodyScroll(document.querySelector('.modal-content')!);
      return {
        ...state,
        onClosed: action.payload.onClosed,
        isVisible: true,
        content: action.payload.content,
        isClosable: action.payload.isClosable,
        isFullscreen: action.payload.isFullscreen,
      };
    case 'HIDE_MODAL':
      clearAllBodyScrollLocks();
      return {
        ...state,
        isVisible: false,
        isClosable: true,
        isPrepared: true,
      };
    case 'LOADING_MODAL':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
