import React from 'react';
import { Button, Logo } from '../../Common';
import { ButtonsContainer, LogoContainer, Wrapper } from '../../Layout';
import { i18n, __ } from '../../../services/translation';
import { formatDateTime } from '../../../utils/format-date';
import { OfferDetails, OfferStatus, UserRole } from 'profilpol-types';
import { differenceInHours } from 'date-fns';
import {
  faArrowLeft,
  faFile,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../../services/api-service';
import { useDispatch, useSelector } from 'react-redux';
import { modal } from '../../../actions';
import ShareOffer from '../../../modals/ShareOffer';
import { ApplicationState } from '../../../reducers';
import './OfferHeader.scss';
import { history } from '../../../App';
import CopyOffer from '../../../modals/CopyOffer';

interface Props {
  offer?: OfferDetails;
  standalone?: boolean;
}

const OfferHeader = ({ offer, standalone }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const current = useSelector((state: ApplicationState) => state.lang.current);

  if (!offer) return null;
  const differenceHours = differenceInHours(
    new Date(offer.validToDate),
    new Date()
  );

  const openShareModal = (offerId: string) => {
    dispatch(modal.showModal(<ShareOffer offerId={offerId} />));
  };

  const openCopyModal = (offerId: string) => {
    dispatch(modal.showModal(<CopyOffer offerId={offerId} />));
  };

  return (
    <Wrapper column>
      {standalone && (
        <div className="offer-header-top">
          <LogoContainer noPadding toRight>
            <Logo />
          </LogoContainer>
        </div>
      )}
      <header className="offer-header">
        <h1 className="offer-title">
          {__('offer.offer')} <span className="offer-number">{offer.id}</span>
        </h1>
        <ButtonsContainer className="offer-buttons">
          {standalone &&
            user &&
            [UserRole.Admin, UserRole.Salesman].includes(user.role) && (
              <Button
                small
                leftIcon
                mobileIconOnly
                secondary
                faIcon={faArrowLeft}
                text={'application.go_back'}
                click={() => history.push('/panel/offers')}
              />
            )}

          {offer.status === OfferStatus.NEW &&
            user &&
            [UserRole.Admin, UserRole.Salesman].includes(user.role) && (
              <Button
                small
                leftIcon
                mobileIconOnly
                primary
                faIcon={faPaperclip}
                text={offer.validToDate ? 'offer.extend' : 'offer.share'}
                click={() => openShareModal(offer.id)}
              />
            )}

          {user && [UserRole.Admin, UserRole.Salesman].includes(user.role) && (
            <Button
              small
              leftIcon
              mobileIconOnly
              secondary
              faIcon={faPaperclip}
              text={'offer.copy'}
              click={() => openCopyModal(offer.id)}
            />
          )}

          <Button
            text={'offer.pdf'}
            outline
            small
            leftIcon
            faIcon={faFile}
            blank
            to={
              standalone
                ? `${ApiService.url}offer/client-pdf/${offer.id}/${offer.accessToken}`
                : `${ApiService.url}offer/pdf/${offer.id}`
            }
          />
        </ButtonsContainer>
        {user && [UserRole.Admin, UserRole.Salesman].includes(user.role) && (
          <ButtonsContainer marginTop>
            <Button
              text={'offer.internal_pdf'}
              outline
              small
              leftIcon
              faIcon={faFile}
              blank
              to={`${ApiService.url}offer/internal-pdf/${offer.id}`}
            />
            <Button
              text={'offer.form_pdf'}
              outline
              small
              leftIcon
              faIcon={faFile}
              blank
              to={`${ApiService.url}offer/form-pdf/${offer.id}`}
            />
          </ButtonsContainer>
        )}
      </header>
      <Wrapper className="offer-subheader">
        {offer.status === OfferStatus.NEW && offer.validToDate && (
          <>
            <div className="offer-valid">
              <p>{__('offer.exp_date')}</p>
              <p className="offer-date">{formatDateTime(offer.validToDate)}</p>
            </div>

            {differenceHours > 0 ? (
              <p className="offer-days">
                <span className="remaning-days">{differenceHours}</span>
                {__('offer.hours')}
              </p>
            ) : null}
          </>
        )}
      </Wrapper>
    </Wrapper>
  );
};

export default OfferHeader;
