import { faFolderMinus, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Icon, Thumbnail } from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../services/translation';
import { ChangeType } from '../../../../types/configuration';
import './SingleChange.scss';

interface Props {
  type: ChangeType;
  name: string;
  recipeName?: string;
  quantity?: number;
  length?: number;
  hasImage?: boolean;
  id?: string;
  simple?: boolean;
}

const SingleChange = ({
  type,
  name,
  quantity,
  hasImage,
  id,
  recipeName,
  length,
  simple,
}: Props) => {
  if (type === ChangeType.ADDED) {
    return (
      <div className="single-change change-added">
        <div className="single-change-content">
          <Icon small faIcon={faFolderPlus} />

          <span>{__('offer.order_change_added')}</span>
          <span>
            <strong>
              {quantity} {__('product.units')}
              {length ? <> &times; {length} m</> : null}
            </strong>
            <span className="quantity-separator">&times;</span>
            {recipeName ? (
              <span>
                <strong>{name}</strong> ({recipeName})
              </span>
            ) : (
              name
            )}
          </span>
        </div>
        {hasImage && <Thumbnail src={`${ApiService.url}product/image/${id}`} />}
      </div>
    );
  }

  if (type === ChangeType.REMOVED) {
    return (
      <div className="single-change change-removed">
        <div className="single-change-content">
          <Icon small faIcon={faFolderMinus} />
          <span>{__('offer.order_change_removed')}</span>
          {!simple && (
            <>
              <strong>
                {quantity} {__('product.units')}
                {length ? <> &times; {length} m</> : null}
              </strong>

              <span className="quantity-separator">&times;</span>
            </>
          )}
          {recipeName ? (
            <span>
              <strong>{name}</strong> ({recipeName})
            </span>
          ) : (
            name
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default SingleChange;
