import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CONFIG from '../../../../config/app';
import {
  Container,
  FormContainer,
  ButtonsContainer,
} from '../../../../components/Layout';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  Spinner,
  Button,
  Header,
  InputSelect,
  FieldsMapper,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { ProductForm } from 'profilpol-types';
import { ButtonType } from '../../../../components/Common/Button';
import { successNotification } from '../../../../actions/notifications';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { useParams } from 'react-router';
import { history } from '../../../../App';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { pathToObject } from '../../../../utils/path-to-object';

const Edit = () => {
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useDispatch();

  return (
    <Container>
      <Header header="profiles.edit" />
      <Button
        leftIcon
        link
        faIcon={faChevronLeft}
        text={'application.back'}
        to="/panel/profiles"
      />
      <FormContainer>
        <ItfApiForm
          formId="profileForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() =>
            ApiService.loadForm(`product/form/update-profile/${productId}`)
          }
          sendFetcher={(formId: string, values: ProductForm) => {
            const updatedProduct = {
              ...values,
            };

            const parsed = pathToObject(updatedProduct);

            const product = new FormData();
            for (const key in parsed) {
              if (key === 'image' && values.image) {
                product.append('image', parsed.image, parsed.image.name);
              } else product.append(key, JSON.stringify(parsed[key]));
            }

            return ApiService.sendForm(
              `product/profile/${productId}`,
              product,
              'PATCH',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                big
                type={ButtonType.Submit}
                primary
                text={'application.save'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={(
            field,
            errors,
            fieldHtmlId,
            currentValue,
            onChange,
            onFocus,
            onBlur
          ) =>
            FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur
            )
          }
          onSavedSuccessfully={() => {
            dispatch(successNotification('profile.updated'));
            history.push('/panel/profiles');
          }}
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{
            ReactSelect: InputSelect,
            ReactCreatableSelect: InputSelect,
          }}
        />
      </FormContainer>
    </Container>
  );
};

export default Edit;
