import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
} from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { notifications } from '../../../actions';
import { history } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../../components/Layout';
import { ButtonType } from '../../../components/Common/Button';
import { __ } from '../../../services/translation';

import './EmailPrompt.scss';
import { CircleWrapper } from '../../../components/Layout';

interface Props {
  successNotification: (text: string) => void;
}

const ResetPassword = ({ successNotification }: Props) => {
  const dispatch = useDispatch();
  return (
    <CircleWrapper
      title={__('application.welcome')}
      subtitle={__('application.restore_pass')}
    >
      <FormContainer>
        <ItfApiForm
          formId="reset-password-request"
          schemaMayBeDynamic={false}
          schemaCacheEnabled
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() =>
            ApiService.loadForm('user/form/reset-pass-request')
          }
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm('user/reset-pass-request', values, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(
              notifications.successNotification('application.email_correct')
            );
            history.push('/');
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer center padding>
              <Button
                primary
                big
                type={ButtonType.Submit}
                text={'application.submit'}
              />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={FieldsMapper}
        />
      </FormContainer>
    </CircleWrapper>
  );
};

export default ResetPassword;
