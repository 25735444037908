import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { menu } from '../../../actions';
import './MenuToggle.scss';

interface Props {
  toggled: boolean;
  toggleMenu: () => void;
}

const MenuToggle = ({ toggleMenu, toggled }: Props) => (
  <button
    className={`menu-toggle  ${toggled && 'toggled'}`}
    title="Menu"
    type="button"
    onClick={toggleMenu}
    tabIndex={0}
  >
    <span className="line line-1" />
    <span className="line line-2" />
    <span className="line line-3" />
  </button>
);
const mapStateToProps = (state: ApplicationState) => {
  return {
    toggled: state.menu.toggled,
  };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ toggleMenu: menu.toggleMenu }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuToggle);
