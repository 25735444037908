import {
  BlockType,
  RecipeFieldDetails,
  SelectOptionI18n,
} from 'profilpol-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputSelect } from '../..';
import { ApplicationState } from '../../../../reducers';
import { __ } from '../../../../services/translation';
import { ButtonsContainer } from '../../../Layout';
import Button from '../../Button';
import CONFIG from '../../../../config/app';
import './FieldOptions.scss';
import { getCurrentFromI18nEntry } from '../../../../utils/language';

interface Props {
  fields: RecipeFieldDetails[];
  appendBlock: (type: BlockType, value: string | number) => void;
  hide: () => void;
}

const FieldOptions = ({ fields, appendBlock, hide }: Props) => {
  const [val, setVal] = useState();
  const [availableOptions, setAvailableOptions] = useState<SelectOptionI18n[]>(
    []
  );
  const [option, setOption] = useState<string | null>(null);
  const { current } = useSelector((state: ApplicationState) => state.lang);

  useEffect(() => {
    const field = fields.find((f) => f.id === val);
    if (field && field.options && fields.length > 0)
      setAvailableOptions(field.options);
  }, [val]);

  return (
    <div className="field-options-container">
      <div className="field-options-select">
        <InputSelect
          compact
          value={val}
          onChange={(v: any) => {
            setVal(v);
            setOption(null);
          }}
          options={fields
            .filter((f) => f.options && f.options.length > 0)
            .map((f) => ({
              label: getCurrentFromI18nEntry(f.title, current),
              value: f.id,
            }))}
          placeholder="application.select"
        />
        {availableOptions && availableOptions.length > 0 && (
          <InputSelect
            compact
            value={val}
            onChange={(v: any) => setOption(v)}
            options={availableOptions.map((f) => ({
              label: f.name[current] || f.name[CONFIG.DEFAULT_LANG],
              value: f.value,
            }))}
            placeholder="application.select"
          />
        )}
        <ButtonsContainer>
          <Button text="application.cancel" small primary danger click={hide} />
          <Button
            text="application.ok"
            disabled={!option}
            small
            primary
            click={() => {
              if (option) {
                appendBlock(BlockType.TEXT, option);
                hide();
              }
            }}
          />
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default FieldOptions;
