export const pathToObject = (object: any) => {
  const newObject: any = {};
  Object.entries(object).forEach(([key, value]: any) => {
    if (key.includes('.')) {
      const splitted = key.split('.');
      if (!newObject[splitted[0]]) {
        newObject[splitted[0]] = {};
      }
      newObject[splitted[0]][splitted[1]] = value;
    } else {
      newObject[key] = value;
    }
  });

  return newObject;
};
