import clsx from 'clsx';
import React from 'react';
import { clearScreenDown } from 'readline';
import { __ } from '../../../services/translation';

import './InfoParagraph.scss';

interface Props {
  text: string;
  noMargin?: boolean;
  tab?: boolean;
}

const InfoParagraph = ({text, noMargin, tab}: Props) => (
  <p className={clsx("info-paragraph", {"no-margin": noMargin, tab: tab})}>
    {__(text)}
  </p>
);

export default InfoParagraph;
