import { faFile } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button } from '../../Common';
import { ButtonsContainer, Wrapper } from '../../Layout';
import { __ } from '../../../services/translation';

import './OfferAccept.scss';
import { OfferDetails, OfferStatus } from 'profilpol-types';
import ApiService from '../../../services/api-service';
import { useDispatch } from 'react-redux';
import { modal, notifications } from '../../../actions';
import { Confirmation } from '../../../modals';
import OfferAccepted from '../../../modals/OfferAccepted';

interface Props {
  offer: OfferDetails;
  standalone: boolean;
  getOffer?: () => void;
}

const OfferAccept = ({ offer, getOffer, standalone }: Props) => {
  const dispatch = useDispatch();

  const confirmAccept = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="offer.confirm_accept"
          confirmCallback={acceptOffer}
        />
      )
    );
  };
  const acceptOffer = async () => {
    dispatch(modal.loadingModal(true));
    await ApiService.callFetch(
      'PATCH',
      `offer/accept/${offer.id}/${offer.accessToken}`,
      () => {
        dispatch(notifications.successNotification('offer.accepted'));
        dispatch(modal.showModal(<OfferAccepted />));
        if (typeof getOffer === 'function') getOffer();
      }
    );
    dispatch(modal.loadingModal(false));
  };
  return (
    <Wrapper column className="offer-accept">
      <ButtonsContainer center>
        {offer.status === OfferStatus.NEW && (
          <Button text={'offer.accept_offer'} secondary click={confirmAccept} />
        )}
      </ButtonsContainer>
    </Wrapper>
  );
};

export default OfferAccept;
