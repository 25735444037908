import { getDefaultLanguage } from "../utils/language";

export default class SessionService {
  static setCurrentLanguage(lang: string) {
    localStorage.setItem("language", JSON.stringify(lang));
  }

  static getCurrentLanguage() {
    try {
      return (
        JSON.parse(localStorage.getItem("language") || "null") ||
        getDefaultLanguage()
      );
    } catch {
      return getDefaultLanguage();
    }
  }
}
