import React from "react";
import { Button } from "../../components/Common";
import "./Error.scss";

const Error = () => (
  <>
    error
    <Button
      click={() => {
        window.location.reload();
      }}
      text="application.try_again"
    />
  </>
);

export default Error;
