import { FieldType, TableSchema } from '../types';

const statusToString: {
  [key: number]: string;
} = {
  0: 'status.new',
  1: 'status.accepted',
  2: 'status.cancelled',
  3: 'status.finished',
  4: 'status.draft',
  5: 'status.needs_verification'
};

export const offerTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'offer.id',
      field: 'id',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'offer.name',
      field: 'name',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'offer.date',
      field: 'createDate',
      searchable: false,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'offer.status',
      field: 'status',
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      customValue: (value) => statusToString[value.status],
      searchableValues: statusToString,
    },
    {
      name: 'offer.value',
      field: 'value',
      searchable: true,
      sortable: true,
      type: FieldType.Price,
    },
  ],
};
