import { ProductType } from 'profilpol-types';
import { formatThousands } from './format-thousands';

export const formatWeight = (
  n: number,
  type: ProductType = ProductType.STANDARD,
  isHtml = false
): string =>
  `${formatThousands(n, isHtml)} ${
    type === ProductType.STANDARD ? 'kg' : 'kg/m'
  }`;
