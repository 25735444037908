import { I18nEntry } from 'profilpol-types';
import CONFIG from '../config/app';

export const getDefaultLanguage = () => {
  const language = window.navigator.language.slice(0, 2).toLowerCase();
  if (CONFIG.LANGUAGES.find((lang) => lang === language)) return language;
  return CONFIG.DEFAULT_LANG;
};

export const getCurrentFromI18nEntry = (entry: I18nEntry, language: string) => {
  return (
    entry[language] ||
    entry[CONFIG.DEFAULT_LANG] ||
    (entry && typeof entry !== 'object' ? entry : '') ||
    ''
  );
};
