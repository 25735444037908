import React, { useState, useEffect } from 'react';
import './Ripple.scss';
import clsx from 'clsx';

interface Props {
  x: number;
  y: number;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  danger?: boolean;
}

const Ripple = ({ x, y, primary, secondary, success, danger }: Props) => {
  const [active, setActive] = useState<boolean>(true);
  useEffect(() => {
    const timeout = setTimeout(() => setActive(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  });
  if (!active) return null;
  return (
    <div
      className={clsx('ripple', {
        primary: primary,
        secondary,
        success,
        danger,
      })}
      style={{ top: `${y}px`, left: `${x}px` }}
    ></div>
  );
};
export default Ripple;
