import { OfferDetails, OfferStatus } from 'profilpol-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { Alert, Divider } from '../../Common';
import OfferAccept from '../OfferAccept';
import OfferClientDescription from '../OfferClientDescription';
import OfferCompanyDetails from '../OfferCompanyDetails';
import OfferProductDetails from '../OfferProductDetails';
import OfferSummary from '../OfferSummary';
import './OfferBody.scss';

interface Props {
  offer?: OfferDetails;
  standalone?: boolean;
  getOffer?: () => void;
}

const OfferBody = ({ offer, standalone, getOffer }: Props) => {
  if (!offer) {
    return <Alert type="error" text="offer.not_found" />;
  }

  return (
    <>
      {offer.status === OfferStatus.ACCEPTED && (
        <Alert type="success" text="offer.accepted" />
      )}

      {offer.status === OfferStatus.CANCELLED && (
        <>
          <Alert type="error" text="offer.cancelled" />
          <div className="offer-reason">{offer.reason}</div>
        </>
      )}
      {offer.status === OfferStatus.FINISHED && (
        <Alert type="success" text="offer.finished" />
      )}

      <OfferCompanyDetails client={offer.client} standalone={standalone} />
      <OfferProductDetails offer={offer} standalone={standalone} />
      <OfferSummary offer={offer} />
      <OfferAccept
        offer={offer}
        getOffer={getOffer}
        standalone={!!standalone}
      />
    </>
  );
};

export default OfferBody;
