import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'profilpol-tables';
import Spinner from '../Spinner';
import SearchBar from '../../SearchBar';
import debounce from '../../../utils/debounce';
import { apiList } from '../../../actions';
import List from '../List';
import { __ } from '../../../services/translation';
import { Alert } from '..';
import { ApplicationState } from '../../../reducers';
import SearchTabs from '../SearchTabs';
import { CustomSearchTab, CustomSearchType } from '../../../types/api-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './ApiList.scss';

interface Props {
  fetchApiListData: (url: string, method?: string) => void;
  loading: boolean;
  data: any;
  component: any;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  narrow?: boolean;
  padded?: boolean;
  id?: string;
  filterBy?: any[];
  customSearchTabs?: CustomSearchTab[];
  inModal?: boolean;
  asTable?: boolean;
  clearApiList: () => void;
}

interface State {
  customSearchBy: string;
  customSearchQuery: string | number | null;
  addressSuffix: string | number | null;
  searchQuery: string;
  searchBy: string;
  page: number;
  sortBy: string;
  sortDirBack: boolean;
  loading: boolean;
  data: {
    countTotal: number;
    items: any[];
  };
}

class ApiList<T> extends Component<Props, State> {
  private debouncedFetch: () => void;

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);
    const { scheme, customSearchTabs } = props;

    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1
    );

    this.state = {
      customSearchBy: customSearchTabs ? customSearchTabs[0].field : '',
      customSearchQuery: customSearchTabs ? customSearchTabs[0].default : '',
      searchQuery: defaultSearchField
        ? String(defaultSearchField.defaultSearch)
        : '',
      searchBy: defaultSearchField ? defaultSearchField.field : '',
      addressSuffix: null,
      page: 1,
      sortBy: '',
      sortDirBack: false,
      loading: true,
      data: {
        countTotal: 0,
        items: [],
      },
    };
  }

  componentDidMount() {
    const { sortBy, sortDirBack } = this.state;
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();

    let storageSearchBy;
    let storageSearchQuery;
    let storageSortBy;
    let storageSortDirBack;
    let storagePage;

    try {
      storageSearchBy = localStorage.getItem(`${apiEndpointSubUrl}_searchBy`);
      storageSearchQuery = localStorage.getItem(
        `${apiEndpointSubUrl}_searchQuery`
      );
      storageSortBy = localStorage.getItem(`${apiEndpointSubUrl}_sortBy`);
      storageSortDirBack = JSON.parse(
        localStorage.getItem(`${apiEndpointSubUrl}_sortDirBack`) || 'false'
      );
      storagePage = Number(
        JSON.parse(localStorage.getItem(`${apiEndpointSubUrl}_page`) || 'false')
      );
    } catch (e) {}

    const newSortBy =
      typeof storageSortBy !== 'undefined' && storageSortBy !== null
        ? storageSortBy
        : currSort.sortBy;
    const newSortDirBack =
      typeof storageSortDirBack !== 'undefined' && storageSortDirBack !== null
        ? storageSortDirBack
        : currSort.sortDirBack;

    if (newSortBy === sortBy && newSortDirBack === sortDirBack) return;

    this.setState(
      {
        searchBy: storageSearchBy || '',
        searchQuery: storageSearchQuery || '',
        sortBy: newSortBy,
        sortDirBack: newSortDirBack,
        page: storagePage || 1,
      },
      () => {
        this.fetch();
      }
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;
    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { clearApiList } = this.props;
    clearApiList();
  }

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();
    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack)
      return;

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_sortBy`, sortBy);
      localStorage.setItem(
        `${apiEndpointSubUrl}_sortDirBack`,
        JSON.stringify(sortDirBack)
      );
    } catch (e) {}

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch
    );
  };

  private async fetch() {
    const { fetchApiListData, apiEndpointMethod } = this.props;
    await fetchApiListData(this.buildUrl(), apiEndpointMethod);
  }

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;

    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find(
        (f) => f.defaultSort === true
      ) as TableSchemaField;
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    const { apiEndpointSubUrl } = this.props;
    if (page === newPage) return;

    localStorage.setItem(`${apiEndpointSubUrl}_page`, newPage.toString());

    this.setState(
      {
        page: newPage,
      },
      this.fetch
    );
  };

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;

    const newSearchQuery = searchBy === searchByNow ? searchQuery : '';

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_searchBy`, searchBy);
      localStorage.setItem(`${apiEndpointSubUrl}_searchQuery`, newSearchQuery);
      localStorage.setItem(`${apiEndpointSubUrl}_page`, '1');
    } catch (e) {}

    this.setState(
      {
        page: 1,
        searchBy,
        searchQuery: newSearchQuery,
      },
      this.debouncedFetch
    );
  };

  handleCustomSearchChange = (
    field: string,
    value: string | number | null,
    type: CustomSearchType
  ) => {
    if (type === CustomSearchType.MODIFY_URL_ADDRESS) {
      this.setState(
        {
          page: 1,
          addressSuffix: value,
        },
        this.fetch
      );
    } else {
      this.setState(
        {
          page: 1,
          customSearchBy: field,
          customSearchQuery: value,
        },
        this.fetch
      );
    }
  };

  private genSortItems = (header: TableSchemaField) => {
    const { sortBy, sortDirBack } = this.state;
    if (header.sortable !== true) return null;

    const handleSortChange = (dirBack: boolean) => {
      this.handleSortChange(header.field, dirBack);
    };

    const arrow1 = (
      <a
        onClick={() => handleSortChange(false)}
        className={`sort sort-up ${
          sortBy === header.field && sortDirBack !== true ? 'active' : ''
        }`}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </a>
    );
    const arrow2 = (
      <a
        onClick={() => handleSortChange(true)}
        className={`sort sort-down ${
          sortBy === header.field && sortDirBack === true ? 'active' : ''
        }`}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </a>
    );

    return (
      <>
        {arrow1}
        {arrow2}
      </>
    );
  };

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const {
      searchBy,
      searchQuery,
      page,
      sortBy,
      sortDirBack,
      customSearchBy,
      customSearchQuery,
      addressSuffix,
    } = this.state;

    const searchParams = [];
    if (searchBy && searchQuery !== null && typeof searchQuery !== 'undefined')
      searchParams.push(searchBy);
    if (
      customSearchBy &&
      customSearchQuery !== null &&
      typeof customSearchQuery !== 'undefined'
    ) {
      searchParams.push(customSearchBy);
    }

    const searchQueryParams = [];
    if (searchBy && searchQuery !== null && typeof searchQuery !== 'undefined')
      searchQueryParams.push(searchQuery);
    if (
      customSearchBy &&
      customSearchQuery !== null &&
      typeof customSearchQuery !== 'undefined'
    ) {
      searchQueryParams.push(customSearchQuery);
    }

    return `${apiEndpointSubUrl}${
      addressSuffix !== null ? `/${addressSuffix}` : ''
    }?${new URLSearchParams({
      searchBy: searchParams.join(','),
      searchQuery: searchQueryParams.join(','),
      page,
      sortBy,
      sortDirBack,
    } as any)}`;
  }

  render() {
    const {
      page,
      searchQuery,
      searchBy,
      sortBy,
      sortDirBack,
      customSearchQuery,
      addressSuffix,
    } = this.state;
    const {
      loading,
      scheme,
      narrow,
      filterBy,
      data,
      component,
      id,
      padded,
      customSearchTabs,
      inModal,
      asTable,
    } = this.props;
    let list;

    if (loading) {
      list = <Spinner overlay transparent />;
    } else if (data.countTotal === 0) {
      list = (
        <>
          <Alert type="error" simple text="application.no_data" />
        </>
      );
    } else if (filterBy && !loading) {
      const ids = filterBy.map((item: any) => item.id);
      const filteredData = () => {
        const filteredItems = data.items.filter(
          (item: any) => !ids.includes(item.id)
        );
        return {
          countTotal: filteredItems.length,
          items: filteredItems,
          loading,
        };
      };
      list = (
        <List
          data={filteredData()}
          scheme={scheme}
          narrow={narrow}
          initialPage={page}
          onPageChange={this.handlePageChange}
          component={component}
          id={id}
        />
      );
    } else {
      list = (
        <>
          <List
            data={data}
            scheme={scheme}
            narrow={narrow}
            initialPage={page}
            onPageChange={this.handlePageChange}
            component={component}
            id={id}
          />
        </>
      );
    }

    return (
      <>
        {data && (
          <SearchBar
            allFields={scheme.fields}
            search={{
              searchBy,
              searchQuery,
            }}
            sort={
              asTable
                ? null
                : {
                    sortBy,
                    sortDirBack,
                  }
            }
            onSearchChange={this.handleSearchChange}
            onSortChange={this.handleSortChange}
            inModal={inModal}
            padded={padded}
          />
        )}

        {customSearchTabs &&
          customSearchTabs.map((customSearch: CustomSearchTab) => (
            // <ButtonsContainer>
            <SearchTabs
              activeTab={customSearchQuery || addressSuffix}
              tabs={customSearch.options}
              chooseTab={(value: string | number | any) =>
                this.handleCustomSearchChange(
                  customSearch.field,
                  value,
                  customSearch.type
                )
              }
            />
            // </ButtonsContainer>
          ))}
        {asTable && (
          <div className="api-list-header">
            {scheme.fields.map((header: TableSchemaField) => (
              <div key={header.field}>
                {__(header.name)}
                {this.genSortItems(header)}
              </div>
            ))}
          </div>
        )}
        <div className="api-list">{list}</div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  data: state.apiList,
  loading: state.apiList.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchApiListData: apiList.fetchApiListData,
      clearApiList: apiList.clearApiList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApiList);
