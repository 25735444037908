import * as React from 'react';
import Flatpickr from 'react-flatpickr';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import { __ } from '../../../services/translation';
import RequiredIndicator from '../RequiredIndicator';
import { formatDate } from '../../../utils/format-date';
import CONFIG from '../../../config/app';
import './DateInput.scss';
import { getWeekYearWithOptions } from 'date-fns/fp';
import { Polish } from 'flatpickr/dist/l10n/pl';

interface Props {
  input: any;
  multiple?: boolean;
  error?: any;
  meta?: any;
  touched?: any;
  placeholder?: string;
  label?: string;
  min?: number;
  max?: boolean;
  value?: any;
  options?: any[];
  readonly?: boolean;
  nomargin?: boolean;
  help?: string;
  required?: boolean;
  onChange?: (value: string) => void;
  defaultDate?: any;
  minToday?: boolean;
}

const errorClass = (error: boolean, touched: boolean) =>
  error && touched ? 'is-invalid' : '';

const DateInput = (props: Props) => {
  const {
    nomargin,
    input,
    error,
    meta,
    touched,
    placeholder,
    multiple,
    label,

    max,
    help,
    required,
  } = props;

  return (
    <div
      className={`input-container ${max ? 'input-max' : ''} ${
        nomargin ? 'nomargin' : ''
      } `}
    >
      <div className="input-wrapper">
        {label && (
          <label htmlFor={input.name}>
            {__(label)} {required && <RequiredIndicator />}
          </label>
        )}
        <Flatpickr
          key={new Date().toString()}
          placeholder={formatDate(new Date())}
          onClose={(dates: any[]) => {
            if (multiple) {
              input.onChange(dates);
            } else input.onChange(dates[0]);
          }}
          value={input.value}
          options={{
            locale: Polish,
            enableTime: true,
            defaultDate: input.value,
            dateFormat: CONFIG.FLATPICKR_FORMAT,
            weekNumbers: true,
            mode: multiple ? 'multiple' : 'single',
          }}
        />
      </div>
      {meta.error && meta.touched && (
        <small className="text-danger">{meta.error}</small>
      )}
      {help && <small className="text-help">{help}</small>}
    </div>
  );
};

export default DateInput;
