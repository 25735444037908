import React from "react";
import ProfileDetails from "../ProfileDetails";

import "./ProfileCircle.scss";

const ProfileCircle = () => (
  <div className="profile-circle-wrapper">
    <div className="profile-circle">
      <ProfileDetails />
    </div>
    <div className="circle first"></div>
    <div className="circle second"></div>
  </div>
);

export default ProfileCircle;
