import React, { useEffect, useState } from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ShareOfferReq, ShareOfferResponse } from 'profilpol-types';
import { successNotification } from '../../actions/notifications';
import { ButtonType } from '../../components/Common/Button';
import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import StatusInfo from './StatusInfo';
import ShareOfferLink from '../SharedOfferLink';

interface Props {
  offerId: string;
}

const ShareOffer = ({ offerId }: Props) => {
  const [status, setStatus] = useState<ShareOfferResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const getStatus = async () => {
    await ApiService.callFetch(
      'GET',
      `offer/share-status/${offerId}`,
      (res: ShareOfferResponse) => {
        setStatus(res);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="offer.share" />
      </ModalHeader>
      {status && <StatusInfo status={status} />}
      <p>{__('offer.share_description')}</p>
      {loading && <Spinner overlay />}
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="offerShareForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() => ApiService.loadForm(`offer/form/share`)}
          sendFetcher={(formId: string, values: ShareOfferReq) => {
            return ApiService.sendForm(
              `offer/share/${offerId}`,
              values,
              'PATCH',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                small
                type={ButtonType.Button}
                danger
                click={() => dispatch(modal.hideModal())}
                text={'application.cancel'}
              />
              <Button
                disabled={loading}
                small
                type={ButtonType.Submit}
                success
                text={status ? 'application.extend' : 'application.share'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={FieldsMapper}
          onSavedSuccessfully={(data: ShareOfferResponse) => {
            dispatch(successNotification('offer.shared_successfully'));
            dispatch(modal.showModal(<ShareOfferLink status={data} />));
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default ShareOffer;
