import { FieldType, TableSchema } from "../types";

const roleToString: {
  [key: number]: string;
} = {
  0: "role.administator",
  1: "role.salesman"
};

export const usersForAdminTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "salesman.id",
      field: "id",
      searchable: false,
      sortable: false,
      type: FieldType.Text
    },
    {
      name: "salesman.email",
      field: "email",
      searchable: false,
      sortable: false,
      type: FieldType.Email
    },
    {
      name: "salesman.first_name",
      field: "firstName",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "salesman.last_name",
      field: "lastName",
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      defaultSort: true
    },
    {
      name: "salesman.phone",
      field: "phone",
      searchable: true,
      sortable: true,
      type: FieldType.Tel,
      defaultSort: true
    },
    
  ]
};
