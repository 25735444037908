import clsx from 'clsx';
import React from 'react';
import { __ } from '../../../services/translation';

import './HelloText.scss';

interface Props {
  title: string;
  mobileHidden?: boolean;
  mobile?: boolean;
  subtitle?: string;
}

const HelloText = ({ mobileHidden, mobile, title, subtitle }: Props) => (
  <div className={clsx("hello-text-wrapper", {"hidden": mobileHidden, "mobile-style": mobile})}>
    <h1 className="header-hello-text">{title}</h1>
    <p className="subtitle-hello-text">{subtitle}</p>
</div>
);

export default HelloText;