import React from 'react';
import './Thumbnail.scss';

interface Props {
  src: string;
}

const Thumbnail = ({ src }: Props) => {
  return (
    <a href={src} target="_blank" className="thumbnail">
      <img src={src} />
    </a>
  );
};

export default Thumbnail;
