import React from "react";
import { Switch, Route } from "react-router-dom";
import EmailPrompt from "./EmailPrompt";
import NewPassword from "./NewPassword";

const ResetPassword = () => (
  <>
    <Switch>
      <Route exact path="/restore-pass" component={EmailPrompt} />
      <Route
        path="/restore-pass/:userId/:updateToken"
        component={NewPassword}
      />
    </Switch>
  </>
);

export default ResetPassword;
