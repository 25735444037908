import clsx from 'clsx';
import React from 'react';
import { __ } from '../../../services/translation';
import './OfferSubtitle.scss';

interface Props {
  title: string;
  noBorder?: boolean;
  noMarginBottom?: boolean;
}

const OfferSubtitle = ({ title, noBorder, noMarginBottom }: Props) => {
  return (
    <h2
      className={clsx('offer-subtitle', {
        'no-border': noBorder,
        'no-margin-bottom': noMarginBottom,
      })}
    >
      {__(title)}
    </h2>
  );
};

export default OfferSubtitle;
