import React from 'react';
import { Card, Divider } from '../Common';
import { __ } from '../../services/translation';
import OfferFooter from './OfferFooter';
import OfferHeader from './OfferHeader';

import './Offer.scss';
import { OfferDetails } from 'profilpol-types';
import OfferBody from './OfferBody';

interface Props {
  offer?: OfferDetails;
  standalone?: boolean;
  getOffer?: () => void;
}

const Offer = ({ offer, standalone, getOffer }: Props) => (
  <Card>
    <OfferHeader offer={offer} standalone={standalone} />
    <OfferBody offer={offer} standalone={standalone} getOffer={getOffer} />
    {standalone && <OfferFooter />}
  </Card>
);

export default Offer;
