import clsx from 'clsx';
import React from 'react';
import './Container.scss';

interface Props {
  children: React.ReactNode;
  noMobilePadding?: boolean;
  fluid?: boolean;
  narrow?: boolean;
}

const Container = ({ children, fluid, narrow }: Props) => {
  return <div className={clsx(`container`, { narrow })}>{children}</div>;
};

export default Container;
