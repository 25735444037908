import { ApiErrorCode } from 'profilpol-types';
import ApiService from '../services/api-service';
import { errorNotification } from './notifications';

export const getSettings = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SETTINGS_SUCCESS',
    'GET_SETTINGS_FAILED',
    null,
    () => {
      dispatch(errorNotification(ApiErrorCode.CannotFetchSettings));
    },
    `setting/all`,
    'LOADING_SETTINGS'
  );
};
