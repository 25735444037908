import React from 'react';
import { __ } from '../../../services/translation';

import './OfferCompanyDetails.scss';
import { ClientType, OfferClient } from 'profilpol-types';
import OfferSubtitle from '../OfferSubtitle';
import { ButtonsContainer } from '../../Layout';
import { Button } from '../../Common';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';

interface Props {
  client: OfferClient;
  withClientEdit?: boolean;
  standalone?: boolean;
  isEdit?: boolean;
}

const OfferCompanyDetails = ({
  client,
  standalone,
  withClientEdit,
  isEdit,
}: Props) => {
  const { clientId, offerId } = useParams<{
    clientId?: string;
    offerId?: string; // if offer is edited this parameter is present
  }>();
  return (
    <div className="offer-info">
      <section className="client-data">
        <OfferSubtitle title="offer.client_data" />
        {!standalone && <div>{client.internalId}</div>}
        <div>{client.companyName}</div>
        {client.type === ClientType.COMPANY && (
          <div>{client.companyVatNumber}</div>
        )}
        <div>
          {client.postalCode} {client.city}
        </div>
        <div>
          {client.street} {client.streetNumber}
        </div>
        {withClientEdit && (
          <ButtonsContainer>
            <Button
              small
              rightIcon
              faIcon={faEdit}
              reversed
              primary
              text="application.edit"
              to={
                isEdit
                  ? `/panel/offers/edit/${offerId}/client`
                  : `/panel/offers/new/configure/${clientId}/client`
              }
            />
          </ButtonsContainer>
        )}
      </section>
      <section className="contact-person-data">
        <OfferSubtitle title="offer.contact_data" />
        <div>
          {client.firstName} {client.lastName}
        </div>
        <div>
          <a href={`tel:${client.phone}`}>{client.phone}</a>
        </div>
        <div>
          <a href={`mailto:${client.email}`}>{client.email}</a>
        </div>
        {withClientEdit && (
          <ButtonsContainer>
            <Button
              small
              rightIcon
              faIcon={faEdit}
              reversed
              primary
              text="application.edit"
              to={
                isEdit
                  ? `/panel/offers/edit/${offerId}/client`
                  : `/panel/offers/new/configure/client`
              }
            />
          </ButtonsContainer>
        )}
      </section>
    </div>
  );
};

export default OfferCompanyDetails;
