import { TableResponse } from 'profilpol-tables';
import uuid from 'uuid';

export interface ApiListState extends TableResponse<any> {
  loading: boolean;
  uuid: string;
}

const initialState: ApiListState = {
  countTotal: 0,
  items: [],
  uuid: uuid.v4(),
  loading: false,
};

interface FetchApiListDataSuccess {
  type: 'FETCH_API_LIST_DATA_SUCCESS';
  payload: any;
}

interface RefreshApiList {
  type: 'REFRESH_API_LIST';
}

interface LoadingApiList {
  type: 'LOADING_API_LIST';
  payload: boolean;
}

interface ClearApiList {
  type: 'CLEAR_API_LIST';
}

type Action =
  | FetchApiListDataSuccess
  | RefreshApiList
  | LoadingApiList
  | ClearApiList;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'FETCH_API_LIST_DATA_SUCCESS':
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        return initialState;
      } else if (Array.isArray(action.payload)) {
        return {
          countTotal: action.payload.length,
          items: action.payload,
          uuid: uuid.v4(),
          loading: false,
        };
      } else return action.payload;
    case 'REFRESH_API_LIST':
      return {
        ...state,
        uuid: uuid.v4(),
      };
    case 'LOADING_API_LIST':
      return {
        ...state,
        loading: action.payload,
      };
    case 'CLEAR_API_LIST':
      return initialState;
    default:
      return state;
  }
};
