import clsx from 'clsx';
import React from 'react';
import { __ } from '../../../services/translation';
import { formatDateTime } from '../../../utils/format-date';
import { formatPrice } from '../../../utils/format-price';
import { formatWeight } from '../../../utils/format-weight';

import './DetailLine.scss';

interface Props {
  title?: string;
  rawTitle?: string;
  data: string | number | boolean | Date;
  important?: boolean;
  type?: 'phone' | 'email' | 'date' | 'boolean' | 'price' | 'weight';
  breakLine?: boolean;
}

const DetailLine = ({ title, data, important, type, breakLine }: Props) => (
  <div
    className={clsx('detail-line', {
      important: important,
      'break-line': breakLine,
    })}
  >
    <span className="details-title">{__(title)}:</span>
    {!type && <span className="details-data">{data}</span>}
    {type && type === 'email' && (
      <a href={`mailto:${data}`} className="details-data">
        {data}
      </a>
    )}
    {type && type === 'phone' && (
      <a href={`tel:${data}`} className="details-data">
        {data}
      </a>
    )}
    {type && type === 'date' && typeof data !== 'boolean' && (
      <span className="details-data">{formatDateTime(data)}</span>
    )}
    {type && type === 'price' && typeof data === 'number' && (
      <span className="details-data">{formatPrice(data)}</span>
    )}
    {type && type === 'weight' && typeof data === 'number' && (
      <span className="details-data">{formatWeight(data)}</span>
    )}
    {type && type === 'boolean' && (
      <span className="details-data">
        {data ? __('application.yes') : __('application.no')}
      </span>
    )}
  </div>
);

export default DetailLine;
