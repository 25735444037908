import React from 'react';
import { Button } from '../../components/Common';
import { CircleWrapper } from '../../components/Layout';
import { __ } from '../../services/translation';

const PageNotFound = () => (
  <CircleWrapper
    title={__('application.page_not_found')}
    subtitle={__('application.info')}
  >
    <Button to="/" primary big text={'application.go_to_home_page'} />
  </CircleWrapper>
);

export default PageNotFound;
