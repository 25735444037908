import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserRole } from "profilpol-types";
import { ApplicationState } from "../../reducers";
import { Spinner } from '../../components/Common';

interface Props {
  exact?: boolean;
  path?: string;
  component: any;
}

const RouteAuthorized = ({ exact, path, component }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const loading = useSelector((state: ApplicationState) => state.user.loading);
  if(loading) return <Spinner overlay />
  if (!user || ![UserRole.Admin, UserRole.Salesman].includes(user.role)) {
    return <Route render={() => <Redirect to="/" />} />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

export default RouteAuthorized;
