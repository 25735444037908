import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ProductDetails } from 'profilpol-types';
import React, { useEffect, useState } from 'react';
import { Button, DetailLine, Spinner } from '../../../../../components/Common';
import ApiService from '../../../../../services/api-service';
import { __ } from '../../../../../services/translation';
import './Item.scss';

interface Props {
  id: string;
}

const Details = ({ id }: Props) => {
  const [item, setItem] = useState<ProductDetails | null>(null);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    await ApiService.callFetch(
      'GET',
      `product/${id}`,
      (data: ProductDetails) => {
        setItem(data);
      }
    );
  };

  if (!item) return <Spinner />;

  const details = (
    <div className="product-item">
      <div className="product-item-details">
        <DetailLine title="profile.id" data={item.id} />
        <DetailLine title="profile.price" data={item.price} type="price" />
        <DetailLine title="profile.weight" data={item.weight} type="weight" />
        <DetailLine title="profile.code" data={item.code} />
        <DetailLine
          title="profile.description"
          breakLine
          data={item.description}
        />
      </div>
      {item.parents && item.parents.length > 0 && (
        <div className="product-item-parents">
          <h2 className="product-item-header">{__('profile.used_in')}</h2>
          <ul>
            {item.parents.map((p) => (
              <li key={p.id} className="product-subitem">
                <span>
                  {p.product.name} &times; {p.quantity}
                </span>
                <Button
                  to={`/panel/profiles/edit/${p.product.id}`}
                  faIcon={faPencilAlt}
                  leftIcon
                  small
                  reversed
                  primary
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  return details;
};

export default Details;
