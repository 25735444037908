import {
  RecipeComponentDetails,
  RecipeDescriptionDetails,
  RecipeDetails,
  RecipeFieldDetails,
  RecipeInfoDetails,
  RecipeProductDetails,
  RecipeSectionDetails,
} from 'profilpol-types';

interface GetRecipe {
  type: 'GET_RECIPE_SUCCESS';
  payload: RecipeDetails;
}
interface GetRecipeFailed {
  type: 'GET_RECIPE_FAILED';
}

interface AddRecipeField {
  type: 'ADD_NEW_RECIPE_FIELD';
  payload: RecipeFieldDetails;
}

interface UpdateRecipeField {
  type: 'UPDATE_RECIPE_FIELD';
  payload: RecipeFieldDetails;
}

interface DeleteRecipeField {
  type: 'DELETE_RECIPE_FIELD_SUCCESS';
  payload: RecipeFieldDetails;
}

interface AddRecipeInfo {
  type: 'ADD_NEW_RECIPE_INFO';
  payload: RecipeInfoDetails;
}

interface UpdateRecipeInfo {
  type: 'UPDATE_RECIPE_INFO';
  payload: RecipeInfoDetails;
}

interface DeleteRecipeInfo {
  type: 'DELETE_RECIPE_INFO_SUCCESS';
  payload: RecipeFieldDetails;
}

interface AddRecipeSection {
  type: 'ADD_NEW_RECIPE_SECTION';
  payload: RecipeSectionDetails;
}

interface UpdateRecipeSection {
  type: 'UPDATE_RECIPE_SECTION';
  payload: RecipeSectionDetails;
}

interface DeleteRecipeSection {
  type: 'DELETE_RECIPE_SECTION_SUCCESS';
  payload: RecipeSectionDetails;
}

interface AddRecipeComponent {
  type: 'ADD_NEW_RECIPE_COMPONENT';
  payload: RecipeComponentDetails;
}

interface UpdateRecipeComponent {
  type: 'UPDATE_RECIPE_COMPONENT';
  payload: RecipeComponentDetails;
}

interface DeleteRecipeComponent {
  type: 'DELETE_RECIPE_COMPONENT_SUCCESS';
  payload: RecipeComponentDetails;
}

interface AddRecipeDescription {
  type: 'ADD_NEW_RECIPE_DESCRIPTION';
  payload: RecipeDescriptionDetails;
}

interface UpdateRecipeDescription {
  type: 'UPDATE_RECIPE_DESCRIPTION';
  payload: RecipeDescriptionDetails;
}

interface DeleteRecipeDescription {
  type: 'DELETE_RECIPE_DESCRIPTION';
  payload: string;
}

interface AddRecipeProduct {
  type: 'ADD_NEW_RECIPE_PRODUCT';
  payload: RecipeProductDetails;
}

interface UpdateRecipeProduct {
  type: 'UPDATE_RECIPE_PRODUCT';
  payload: RecipeProductDetails;
}

interface DeleteRecipeProduct {
  type: 'DELETE_RECIPE_PRODUCT_SUCCESS';
  payload: RecipeProductDetails;
}

interface AddRecipeService {
  type: 'ADD_NEW_RECIPE_SERVICE';
  payload: RecipeProductDetails;
}

interface UpdateRecipeService {
  type: 'UPDATE_RECIPE_SERVICE';
  payload: RecipeProductDetails;
}

interface DeleteRecipeService {
  type: 'DELETE_RECIPE_SERVICE_SUCCESS';
  payload: RecipeProductDetails;
}

interface LoadingRecipe {
  type: 'LOADING_RECIPE';
  payload: boolean;
}

type Action =
  | GetRecipe
  | GetRecipeFailed
  | AddRecipeField
  | UpdateRecipeField
  | DeleteRecipeField
  | AddRecipeInfo
  | UpdateRecipeInfo
  | DeleteRecipeInfo
  | AddRecipeSection
  | UpdateRecipeSection
  | DeleteRecipeSection
  | AddRecipeComponent
  | UpdateRecipeComponent
  | DeleteRecipeComponent
  | AddRecipeService
  | UpdateRecipeService
  | DeleteRecipeService
  | AddRecipeProduct
  | UpdateRecipeProduct
  | DeleteRecipeProduct
  | AddRecipeDescription
  | UpdateRecipeDescription
  | DeleteRecipeDescription
  | LoadingRecipe;

export interface RecipeState {
  data: RecipeDetails | null;
  loading: boolean;
}

const initialState: RecipeState = {
  data: null,
  loading: true,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_RECIPE_SUCCESS': {
      const recipe = action.payload;
      return {
        ...state,
        data: recipe,
      };
    }
    case 'GET_RECIPE_FAILED': {
      return { ...state, data: null };
    }

    case 'ADD_NEW_RECIPE_FIELD': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          inputs: [...state.data.inputs, field],
        },
      };
    }

    case 'UPDATE_RECIPE_FIELD': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          inputs: state.data.inputs.map((input) =>
            input.id === field.id ? field : input
          ),
        },
      };
    }

    case 'DELETE_RECIPE_FIELD_SUCCESS': {
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          inputs: state.data.inputs.filter(
            (input) => input.id !== action.payload.id
          ),
        },
      };
    }

    case 'ADD_NEW_RECIPE_INFO': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          infos: [...state.data.infos, field],
        },
      };
    }

    case 'UPDATE_RECIPE_INFO': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          infos: state.data.infos.map((info) =>
            info.id === field.id ? field : info
          ),
        },
      };
    }

    case 'DELETE_RECIPE_INFO_SUCCESS': {
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          infos: state.data.infos.filter(
            (info) => info.id !== action.payload.id
          ),
        },
      };
    }

    // case 'ADD_NEW_RECIPE_PRODUCT': {
    //   const field = action.payload;
    //   if (!state.data) return state;
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       products: [...state.data., field],
    //     },
    //   };
    // }

    // case 'UPDATE_RECIPE_INFO': {
    //   const field = action.payload;
    //   if (!state.data) return state;
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       infos: state.data.infos.map((info) =>
    //         info.id === field.id ? field : info
    //       ),
    //     },
    //   };
    // }

    // case 'DELETE_RECIPE_INFO_SUCCESS': {
    //   if (!state.data) return state;
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       infos: state.data.infos.filter(
    //         (info) => info.id !== action.payload.id
    //       ),
    //     },
    //   };
    // }

    case 'ADD_NEW_RECIPE_SECTION': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          sections: [...state.data.sections, field],
        },
      };
    }

    case 'UPDATE_RECIPE_SECTION': {
      const field = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          sections: state.data.sections.map((section) =>
            section.id === field.id ? field : section
          ),
        },
      };
    }

    case 'DELETE_RECIPE_SECTION_SUCCESS': {
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          sections: state.data.sections.filter(
            (section) => section.id !== action.payload.id
          ),
        },
      };
    }
    case 'ADD_NEW_RECIPE_COMPONENT': {
      const newComponent = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          components: [...state.data.components, newComponent],
        },
      };
    }

    case 'UPDATE_RECIPE_COMPONENT': {
      const updatedComponent = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          components: state.data.components.map((component) =>
            component.id === updatedComponent.id ? updatedComponent : component
          ),
        },
      };
    }

    case 'DELETE_RECIPE_COMPONENT_SUCCESS': {
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          description: state.data.description.filter(
            (component) => component.id !== action.payload.id
          ),
        },
      };
    }
    case 'ADD_NEW_RECIPE_DESCRIPTION': {
      const newDescription = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          description: [...state.data.description, newDescription],
        },
      };
    }

    case 'UPDATE_RECIPE_DESCRIPTION': {
      const updatedDescription = action.payload;
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          description: state.data.description.map((description) =>
            description.id === updatedDescription.id
              ? updatedDescription
              : description
          ),
        },
      };
    }

    case 'DELETE_RECIPE_DESCRIPTION': {
      if (!state.data) return state;
      return {
        ...state,
        data: {
          ...state.data,
          description: state.data.description.filter(
            (description) => description.id !== action.payload
          ),
        },
      };
    }

    case 'LOADING_RECIPE':
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};
