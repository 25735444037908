import clsx from 'clsx';
import React from 'react';
import './FormContainer.scss';

interface Props {
  children: React.ReactNode;
  full?: boolean;
}

const FormContainer = ({ children, full }: Props) => (
  <div className={clsx('form-container', { full })}>{children}</div>
);

export default FormContainer;
