import { OfferForClient } from 'profilpol-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { getCurrentFromI18nEntry } from '../../../utils/language';
import ConfigurationChanges from '../ConfigurationChanges';
import OfferClientDescription from '../OfferClientDescription';
import OfferSubtitle from '../OfferSubtitle';

import './OfferProductDetails.scss';

interface Props {
  offer: OfferForClient;
  standalone?: boolean;
}

const OfferProductDetails = ({ offer, standalone }: Props) => {
  const current = useSelector((state: ApplicationState) => state.lang.current);
  return (
    <section className="offer-subject">
      <OfferSubtitle title="offer.subject" />
      <span className="offer-subject-quantity">1x</span>{' '}
      <strong>{getCurrentFromI18nEntry(offer.recipeName, current)}</strong>
      <OfferClientDescription description={offer.parsedDescription} />
      <ConfigurationChanges offer={offer} simple={standalone} />
    </section>
  );
};

export default OfferProductDetails;
