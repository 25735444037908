import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { auth } from '../../../../actions';
import MenuItem from '../MenuItem';

import './Navigation.scss';
import { NavigationItem } from '../../../../types/navigation';
import { ApplicationState } from '../../../../reducers';

interface Props {
  items: NavigationItem[];
  desktopItems?: NavigationItem[];
  logout: () => void;
}

const Navigation = ({ items, desktopItems, logout }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  if(!user) return null;
  const basicItems: NavigationItem[] = [
    {
      icon: 'logout',
      name: 'navigation.change_password',
      link: '/panel/change-password',
    },
    {
      icon: 'logout',
      name: 'navigation.logout',
      onClick: logout,
    },
  ];

  const menuBasic = basicItems.map((item: NavigationItem) => (
    <MenuItem key={item.name} link={item.link} icon={item.icon} name={item.name} onClick={item.onClick} />
  ));

  const menu = items.filter(item => !item.roles || item.roles.includes(user.role)).map((item: NavigationItem) => (
    <MenuItem key={item.name} link={item.link} icon={item.icon} name={item.name} onClick={item.onClick} />
  ));

  const menuDekstop = desktopItems
    ? desktopItems.filter(item => !item.roles || item.roles.includes(user.role)).map((item: NavigationItem) => (
        <MenuItem desktop key={item.name} link={item.link} icon={item.icon} name={item.name} onClick={item.onClick} />
      ))
    : [];

  return (
    <div className="menu">
      <div className="menu-main">
        <div className="main-menu-content">
          {menu}
          {menuDekstop}
          {menuBasic}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      logout: auth.logout,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Navigation);
