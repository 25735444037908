import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from '../../../components/Common';
import { i18n } from '../../../services/translation';
import { ApplicationState } from '../../../reducers';

interface Props {
  children: React.ReactNode;
}

const LocaleWrapper = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { current } = useSelector((state: ApplicationState) => state.lang);
  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  useEffect(() => {
    if (current) i18n.load(current);
    checkI18nLoaded();
  }, [current]);

  if (loading) return <Spinner overlay />;

  return <>{children}</>;
};

export default LocaleWrapper;
