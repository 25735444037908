import * as React from 'react';
import { Currency, SelectOptionI18n } from 'profilpol-types';
import { __ } from '../../../services/translation';
import RequiredIndicator from '../RequiredIndicator';
import { Icon } from '..';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import CONFIG from '../../../config/app';
import './Input.scss';
import { getSignByCurrency } from '../../../utils/format-price';

interface InputOption {
  name: string;
  value: string;
  disabled?: boolean;
}

interface InputOptionI18n extends SelectOptionI18n {
  disabled?: boolean;
}

interface MappedOption {
  name: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  type: string;
  input: any;
  min?: number;
  max?: number;
  step?: number;
  value?: any;
  options?: (InputOptionI18n | InputOption)[];
  readonly?: boolean;
  required?: boolean;
  nomargin?: boolean;
  help?: string;
  onChange?: (value: string) => void;
  dark?: boolean;
  label?: string;
  margin?: boolean;
  className?: string;
  placeholder?: string;
  big?: boolean;
  disabled?: boolean;
  compact?: boolean;
  meta?: {
    error?: string | null;
    touched?: boolean | null;
  };
  rounded?: boolean;
  currency?: Currency;
}
const errorClass = (meta: any) =>
  meta && meta.error && meta.touched ? 'is-invalid' : '';

const Input = ({
  type,
  input,
  min,
  max,
  step,
  value,
  options,
  readonly,
  required,
  nomargin,
  help,
  onChange,
  dark,
  label,
  margin,
  className,
  placeholder,
  big,
  disabled,
  compact,
  meta,
  rounded,
  currency,
}: Props) => {
  const { current } = useSelector((state: ApplicationState) => state.lang);
  const [showPassword, setVisibilityPassword] = React.useState<boolean>(false);
  const containerClass = clsx('input-container', className, {
    'input-max': max,
    nomargin: nomargin,
    margin: margin,
    compact: compact,
    rounded,
    'input-with-icon': ['cost', 'percentage'].includes(type),
  });
  // `input-container ${className || ''} ${
  //   max ? 'input-max' : ''
  // } ${nomargin ? 'nomargin' : ''} ${type === 'hidden' ? 'hidden' : ''} ${margin ? 'margin' : ''} ${
  //   compact ? 'compact' : ''
  // }`;
  const labelLine = label && (
    <label htmlFor={input.name}>
      {label ? __(label) : ''} {required && <RequiredIndicator />}{' '}
    </label>
  );

  const getType = (type: string) => {
    switch (type) {
      case 'percentage':
        return 'number';
      case 'cost':
        return 'number';
      default:
        return type;
    }
  };

  const mappedOptions: MappedOption[] = options
    ? options.map((opt) => ({
        ...opt,
        value: opt.value.toString(),
        name:
          typeof opt.name === 'string'
            ? opt.name
            : opt.name[current] || opt.name[CONFIG.DEFAULT_LANG],
      }))
    : [];

  if (type === 'select') {
    const selectOptions = options
      ? mappedOptions.map((option) => (
          <option
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.name}
          </option>
        ))
      : null;
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {labelLine}
          <select
            defaultValue=""
            value={input.value}
            className={`input input-${type} ${max ? 'input-max' : ''} ${
              big ? 'input-big' : ''
            } ${nomargin ? 'nomargin' : ''} ${errorClass(meta)}`}
            id={input.name}
            {...input}
            placeholder={__(placeholder)}
          >
            <option disabled value={''}>
              Wybierz
            </option>
            {selectOptions}
          </select>
        </div>
        {meta && meta.error && meta.touched && (
          <small className="text-danger">{meta.error}</small>
        )}
      </div>
    );
  } else if (type === 'textarea') {
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {labelLine}
          <textarea
            className={`input input-${type} ${errorClass(meta)}`}
            id={input.name}
            readOnly={readonly}
            {...input}
            placeholder={__(placeholder)}
            disabled={disabled}
            maxLength={max}
          />
        </div>
        {meta && meta.error && meta.touched && (
          <small className="text-danger">{meta.error}</small>
        )}
      </div>
    );
  } else if (type === 'password') {
    return (
      <div className={containerClass}>
        {labelLine}
        <div className="input-wrapper">
          <input
            className={clsx('input', `input-${type}`, errorClass(meta), {
              dark: dark,
            })}
            id={input.name}
            readOnly={readonly}
            {...input}
            placeholder={__(placeholder)}
            type={showPassword ? 'text' : 'password'}
            min={min || 0}
            step={step || 1}
          />
          <div
            className="icon-box"
            onClick={() => setVisibilityPassword(!showPassword)}
          >
            <Icon
              className="password-icon"
              icon={showPassword ? 'passwordOff' : 'passwordOn'}
            />
          </div>
        </div>
        {meta && meta.error && meta.touched && (
          <small className="text-danger">{meta.error}</small>
        )}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  } else if (type === 'checkbox') {
    return (
      <div className="checkbox-outer-cont">
        <label htmlFor={input.name} className={`checkbox-container`}>
          <input
            id={input.name}
            onChange={input.onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            type={type}
            value={input.checked || input.value}
            checked={input.checked || input.value}
          />
          <p className="checkbox-label-label">{__(label)}</p>
          <span className="checkbox-checkmark" />
        </label>
        {meta && meta.error && meta.touched && (
          <small className="text-danger">{meta.error}</small>
        )}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  } else if (type === 'radio') {
    if (options && options.length)
      return (
        <div>
          {labelLine}
          {mappedOptions.map((option) => (
            <div className="radio-outer-cont" key={option.value}>
              <label
                htmlFor={option.value.toString()}
                className={`radio-container`}
              >
                <div>
                  <input
                    name={input.name}
                    id={option.value.toString()}
                    onChange={(e: any) => {
                      input.onChange(e);
                    }}
                    type="radio"
                    value={option.value}
                    checked={input.value === option.value}
                  />

                  <p className="radio-label-label">{option.name}</p>
                  <span className="radio-checkmark" />
                </div>
              </label>
            </div>
          ))}
        </div>
      );
    return null;
  } else {
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {labelLine}
          <input
            className={`input ${dark ? 'dark' : ''} input-${type} ${errorClass(
              meta
            )}  ${big ? 'input-big' : ''}`}
            id={input.name}
            readOnly={readonly}
            {...input}
            placeholder={__(placeholder)}
            type={getType(type)}
            max={max}
            min={min || 0}
            step={step || 1}
            disabled={disabled}
          />
          {type === 'password' && (
            <Icon className="password-icon" icon="passwordOn" />
          )}
          {type === 'number' && (
            <Icon className="password-icon" icon="iconmonstr-triangle-1" />
          )}
          {type === 'percentage' && <div className="input-icon">%</div>}
          {type === 'cost' && (
            <div className="input-icon">{getSignByCurrency(currency)}</div>
          )}
        </div>
        {meta && meta.error && meta.touched && (
          <small className="text-danger">{meta.error}</small>
        )}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  }
};
export default Input;
