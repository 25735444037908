import { UserMeDetails } from 'profilpol-types';

interface FetchUserDetailsSuccess {
  type: 'FETCH_USER_DETAILS_SUCCESS';
  payload: UserMeDetails;
}
interface LoadingUserDetails {
  type: 'LOADING_FETCH_USER_DETAILS';
  payload: boolean;
}
interface Logout {
  type: 'LOGOUT';
}

interface ClearUserSession {
  type: 'CLEAR_USER_SESSION';
}

type Action =
  | FetchUserDetailsSuccess
  | LoadingUserDetails
  | Logout
  | ClearUserSession;

export interface UserState {
  details: UserMeDetails | null;
  loading: boolean;
}

const initialState: UserState = {
  details: null,
  loading: true,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'FETCH_USER_DETAILS_SUCCESS': {
      const user = action.payload;
      return {
        ...state,
        details: action.payload,
      };
    }
    case 'LOADING_FETCH_USER_DETAILS': {
      return { ...state, loading: action.payload };
    }

    case 'LOGOUT':
      return { ...state, details: null };
    case 'CLEAR_USER_SESSION':
      return { ...state, details: null };

    default:
      return state;
  }
};
