import clsx from "clsx";
import React from "react";

import "./Wrapper.scss";

interface Props {
  children: React.ReactNode;
  column?: boolean;
  className?: string;
  primary?: boolean;
}

const Wrapper = ({ column, children, className, primary }: Props) => (
  <div
    className={clsx("wrapper", className, {
      "wrapper-column": column,
      "wrapper-primary": primary
    })}
  >
    {children}
  </div>
);

export default Wrapper;
