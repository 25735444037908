import { ItfApiForm } from 'itf_formbuilder_react';
import { AuthLoginRequest, AuthLoginResult } from 'profilpol-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { successNotification } from '../../actions/notifications';
import { getUserDetails } from '../../actions/user';
import { Button, Input, Spinner, FieldsMapper } from '../../components/Common';
import { ButtonType } from '../../components/Common/Button';
import {
  BottomContainer,
  BottomLinks,
  ButtonsContainer,
  FormContainer
} from '../../components/Layout';
import CicrleWrapper from '../../components/Layout/CircleWrapper';
import ApiService from '../../services/api-service';
import { __ } from '../../services/translation';
import { formOnTranslateString } from '../../utils/trans-form';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <CicrleWrapper title={__('login.welcome')} subtitle={__('login.subtitle')}>
      <FormContainer>
        <ItfApiForm
          formId="loginForm"
          schemaMayBeDynamic={false}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          schemaFetcher={() => ApiService.loadForm('auth/form/login')}
          sendFetcher={(formId: string, values: AuthLoginRequest) =>
            ApiService.sendForm('auth/login', values, 'POST', null)
          }
          submitButton={(loading: boolean) => (
            <BottomContainer>
              <BottomLinks>
                <Link to="/restore-pass">{__('login.forgot_password')}</Link>
              </BottomLinks>
              <ButtonsContainer center>
                <Button
                  disabled={loading}
                  big
                  type={ButtonType.Submit}
                  primary
                  text={'login.login'}
                />
              </ButtonsContainer>
            </BottomContainer>
          )}
          onRenderFullField={FieldsMapper}
          onSavedSuccessfully={(data: AuthLoginResult) => {
            successNotification('login.logged_in');
            dispatch(getUserDetails());
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </CicrleWrapper>
  );
};

export default Login;
