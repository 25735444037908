import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { UserRole } from 'profilpol-types';
import { Main } from '../../components/Layout';
import { Sidebar } from '../../components/Common';
import PaymentTypes from './PaymentTypes';
import { RouteAdmin } from '../../route';
import { NavigationItem } from '../../types/navigation';
import Profiles from './Profiles';
import './Admin.scss';

const Products = React.lazy(() => import('./Products'));
const Recipes = React.lazy(() => import('./Recipes'));
const Services = React.lazy(() => import('./Services'));
const Traders = React.lazy(() => import('./Traders'));
const Offers = React.lazy(() => import('./Offers'));
const ChangePassword = React.lazy(() => import('./ChangePassword'));
const Clients = React.lazy(() => import('./Clients'));
const Settings = React.lazy(() => import('./Settings'));

const sidebarMenuItems: NavigationItem[] = [
  {
    name: 'navigation.offers',
    link: '/panel/offers',
    roles: [UserRole.Admin, UserRole.Salesman],
  },
  {
    name: 'navigation.clients',
    link: '/panel/clients',
  },
  {
    name: 'navigation.recipes',
    link: '/panel/recipes',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.products',
    link: '/panel/products',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.profiles',
    link: '/panel/profiles',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.services',
    link: '/panel/services',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.salesmen',
    link: '/panel/traders',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.payment_types',
    link: '/panel/payment-types',
    roles: [UserRole.Admin],
  },
  {
    name: 'navigation.settings',
    link: '/panel/settings',
    roles: [UserRole.Admin, UserRole.Salesman],
  },
];

const Admin = () => {
  return (
    <Main stretch>
      <Sidebar menuItems={sidebarMenuItems} />
      <div className="main-content">
        <Switch>
          <Route
            exact
            path="/panel"
            render={() => <Redirect to="/panel/clients" />}
          />
          <RouteAdmin path="/panel/products" component={Products} />
          <RouteAdmin path="/panel/profiles" component={Profiles} />
          <RouteAdmin path="/panel/recipes" component={Recipes} />
          <RouteAdmin path="/panel/services" component={Services} />
          <RouteAdmin path="/panel/traders" component={Traders} />
          <Route path="/panel/offers" component={Offers} />
          <Route path="/panel/settings" component={Settings} />
          <Route path="/panel/clients" component={Clients} />
          <RouteAdmin path="/panel/payment-types" component={PaymentTypes} />
          <Route path="/panel/change-password" component={ChangePassword} />
        </Switch>
      </div>
    </Main>
  );
};

export default Admin;
