import React from 'react';
import { OfferComponentDetails, OfferForClient } from 'profilpol-types';
import { useSelector } from 'react-redux';
import { __ } from '../../../services/translation';
import { ChangeType } from '../../../types/configuration';
import SingleChange from './SingleChange';
import { ApplicationState } from '../../../reducers';
import { getCurrentFromI18nEntry } from '../../../utils/language';
import './ConfigurationChanges.scss';

interface Props {
  offer: OfferForClient;
  simple?: boolean;
}

const ConfigurationChanges = ({ offer, simple }: Props) => {
  const current = useSelector((state: ApplicationState) => state.lang.current);
  const areThereAnyChanges = () => {
    return (
      offer.additionalProducts.length > 0 ||
      offer.additionalServices.length > 0 ||
      offer.components
        .filter((c) => c.active)
        .some((c) => {
          return (
            c.products.some((p) => !p.active) ||
            c.services.some((s) => !s.active)
          );
        })
    );
  };

  const componentHasChanges = (c: OfferComponentDetails) => {
    return (
      c.products.some((p) => !p.active) || c.services.some((s) => !s.active)
    );
  };

  if (!areThereAnyChanges()) return null;
  return (
    <div className="component-changes-container">
      <h3 className="offer-changes-header">{__('offer.order_changes')}</h3>
      {[...offer.components]
        .filter((component) => component.active)
        .sort((a, b) => a.position - b.position)
        .filter((c) => componentHasChanges(c))
        .map((component) => (
          <div className="component-changes">
            {component.products
              .filter((product) => !product.active)
              .sort((a, b) => a.position - b.position)
              .map((product) => (
                <SingleChange
                  type={ChangeType.REMOVED}
                  name={getCurrentFromI18nEntry(product.productName, current)}
                  recipeName={
                    product.name &&
                    getCurrentFromI18nEntry(product.name, current)
                  }
                  id={product.productId}
                  hasImage={!!product.image}
                  quantity={product.quantity}
                  length={product.length}
                  simple={simple}
                />
              ))}
            {component.services
              .filter((service) => !service.active)
              .sort((a, b) => a.position - b.position)
              .map((service) => (
                <SingleChange
                  type={ChangeType.REMOVED}
                  name={getCurrentFromI18nEntry(service.serviceName, current)}
                  quantity={service.quantity}
                  simple={simple}
                />
              ))}
          </div>
        ))}
      {offer.additionalProducts
        .sort((a, b) => a.position - b.position)
        .map((product) => (
          <SingleChange
            type={ChangeType.ADDED}
            name={getCurrentFromI18nEntry(product.productName, current)}
            quantity={product.quantity}
            recipeName={
              product.name && getCurrentFromI18nEntry(product.name, current)
            }
            id={product.productId}
            hasImage={!!product.image}
            length={product.length}
            simple={simple}
          />
        ))}
      {offer.additionalServices
        .sort((a, b) => a.position - b.position)
        .map((service) => (
          <SingleChange
            type={ChangeType.ADDED}
            name={getCurrentFromI18nEntry(service.serviceName, current)}
            quantity={service.quantity}
            simple={simple}
          />
        ))}
    </div>
  );
};

export default ConfigurationChanges;
