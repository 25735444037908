import uuidv4 from 'uuid/v4';
import { ApiErrorCode } from 'profilpol-types';
import SingleNotification from '../types/notifications/notifications';
import {} from '../services/translation';

interface HideNotification {
  type: 'HIDE_NOTIFICATION';
  payload: string;
}

interface SuccessNotification {
  type: 'SUCCESS_NOTIFICATION';
  payload: string;
}

interface ErrorNotification {
  type: 'ERROR_NOTIFICATION';
  payload: ApiErrorCode;
}
interface ErrorNotificationText {
  type: 'ERROR_NOTIFICATION_TEXT';
  payload: string;
}
type Action =
  | HideNotification
  | SuccessNotification
  | ErrorNotification
  | ErrorNotificationText;

export type NotificationsState = SingleNotification[];

const initialState: NotificationsState = [];

const getError = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.InvalidLoginData:
      return 'error.invalid_login_data';

    case ApiErrorCode.OtherError:
      return 'error.an_error_occured';

    case ApiErrorCode.InvalidOldPass:
      return 'error.invalid_old_password';

    case ApiErrorCode.NoSuchObjectWithThisId:
      return 'error.no_such_object_with_this_id';
    case ApiErrorCode.FormValidationError:
      return 'error.form_validation_error';
    case ApiErrorCode.ClientNotFound:
      return 'error.client_not_found';
    case ApiErrorCode.PaymentTypeNotFound:
      return 'error.payment_type_not_found';
    case ApiErrorCode.LanguageNotSupported:
      return 'error.language_not_supported';
    case ApiErrorCode.AccountIsBlocked:
      return 'error.account_is_blocked';
    case ApiErrorCode.AccountIsNotActivated:
      return 'error.account_is_not_activated';
    case ApiErrorCode.ServiceNotFound:
      return 'error.service_not_found';
    case ApiErrorCode.ProductNotFound:
      return 'error.product_not_found';
    case ApiErrorCode.SettingNotFound:
      return 'error.setting_not_found';
    case ApiErrorCode.CantAssignSameAsSubproduct:
      return 'error.cant_assign_same_as_subproduct';
    case ApiErrorCode.CantAssignParentProductAsSubproduct:
      return 'error.cant_assign_parent_product_as_subproduct';
    case ApiErrorCode.NeedAtLeastOneContact:
      return 'error.need_at_least_one_contact';
    case ApiErrorCode.NoMainContact:
      return 'error.no_main_contact';
    case ApiErrorCode.OnlyOneMainContactAllowed:
      return 'error.only_one_main_contact_allowed';
    case ApiErrorCode.RecipeNotFound:
      return 'error.recipe_not_found';
    case ApiErrorCode.RecipeFieldNotFound:
      return 'error.recipe_field_not_found';
    case ApiErrorCode.NeedAtLeastOneOption:
      return 'error.need_at_least_one_option';
    case ApiErrorCode.RecipeInfoNotFound:
      return 'error.recipe_info_not_found';
    case ApiErrorCode.ForbiddenFileFormat:
      return 'error.forbidden_file_format';
    case ApiErrorCode.FileIsTooLarge:
      return 'error.file_is_too_large';
    case ApiErrorCode.ImageIsRequired:
      return 'error.image_is_required';
    case ApiErrorCode.CannotFetchSettings:
      return 'error.cannot_fetch_settings';
    case ApiErrorCode.OfferNotFound:
      return 'error.offer_not_found';
    case ApiErrorCode.CantRemoveSalesmanHasOffers:
      return 'error.cant_remove_salesman_has_offers';
    default:
      return 'error.an_error_occured';
  }
};

const getErrorNotification = (text: string) => ({
  type: 'error',
  text,
  id: uuidv4(),
});
const getSuccessNotification = (text: string) => ({
  type: 'success',
  text,
  id: uuidv4(),
});

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SUCCESS_NOTIFICATION': {
      const notification = getSuccessNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION_TEXT': {
      const notification = getErrorNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION': {
      const notification = getErrorNotification(getError(action.payload));
      return [...state, notification];
    }
    case 'HIDE_NOTIFICATION':
      return state.filter(
        (notification: SingleNotification) => notification.id !== action.payload
      );
    default:
      return state;
  }
};
