import ApiService from '../services/api-service';

export const changeLanguage = (lang: string, saveInSession = true) => ({
  type: 'CHANGE_LANGUAGE',
  payload: {
    lang,
    saveInSession,
  },
});

export const changeServerLang = (lang: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'CHANGE_SERVER_LANG_SUCCESS',
    'CHANGE_SERVER_LANG_FAILED',
    null,
    null,
    `user/change-language/${lang}`,
    'LOADING_CHANGE_SERVER_LANG'
  );
};
