import React from 'react';
import './Tabs.scss';
import { NavLink } from 'react-router-dom';
import { SingleTab } from '../../../types/tabs';
import { __ } from '../../../services/translation';

interface Props {
  tabs: SingleTab[];
  activeTab?: string | number | null;
  auto?: boolean;
  chooseTab?: (type: any) => void;
}

const Tabs = ({ activeTab, chooseTab, tabs, auto }: Props) => (
  <div className={`tabs-container ${auto ? 'auto' : ''}`}>
    {chooseTab
      ? tabs
          .filter(tab => typeof tab.isActive === 'undefined' || tab.isActive)
          .map((tab: SingleTab) => (
            <button
              key={tab.label}
              type="button"
              tabIndex={0}
              className={`tabs-option ${
                activeTab === tab.value ? 'active' : ''
              }`}
              onClick={() => chooseTab(tab.value)}
            >
              {__(tab.label)}
            </button>
          ))
      : tabs
          .filter(tab => typeof tab.isActive === 'undefined' || tab.isActive)
          .map((tab: SingleTab) => (
            <NavLink
              key={tab.label}
              exact
              tabIndex={0}
              activeClassName="active"
              className="tabs-option"
              to={tab.to || ''}
            >
              {__(tab.label)}
            </NavLink>
          ))}
  </div>
);

export default Tabs;
