import { ProductType, SettingType, Currency } from 'profilpol-types';
import { store } from '../App';
import { __ } from '../services/translation';
import { formatThousands } from './format-thousands';

export const getSignByCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.PLN:
      return 'zł';
    case Currency.EUR:
      return '€'
    default: return 'zł'
  }
}

const formatWithCurrency = (value: string, currency: Currency) => {
  switch (currency) {
    case Currency.PLN:
      return `${value} zł`;
    case Currency.EUR:
      return `€ ${value}`;
  }
};

export const getConvertedValue = (
  value: number,
  currency: Currency,
  forceRatio?: number
) => {
  const settings = store.getState().settings.data;

  const rateSetting = settings.find(
    (setting) => setting.type === SettingType.EUR_PLN_RATIO
  );

  const rate = forceRatio
    ? { value: forceRatio, type: SettingType.EUR_PLN_RATIO }
    : rateSetting
      ? settings.find((setting) => setting.type === SettingType.EUR_PLN_RATIO)
      : null;

  switch (currency) {
    case Currency.PLN:
      return value;
    case Currency.EUR:
      if (!rate) return __('application.no_pln_ratio');
      return value / rate.value;
  }
};

export const formatProductPrice = (
  n: number,
  currency: Currency = Currency.PLN,
  type: ProductType = ProductType.STANDARD,
  forceRatio?: number
): string => {
  const val = formatWithCurrency(
    formatThousands(getConvertedValue(n, currency, forceRatio)),
    currency
  );
  if (type === ProductType.PROFILE) return `${val}/m`;
  return `${val}/szt`;
};

export const formatPrice = (
  n: number,
  currency: Currency = Currency.PLN,
  isHtml = false,
  forceRatio?: number
): string => {
  return formatWithCurrency(
    formatThousands(getConvertedValue(n, currency, forceRatio), isHtml),
    currency
  );
};

export const formatPriceWithoutConvertion = (
  n: number,
  currency: Currency = Currency.PLN,
  isHtml = false
): string => formatWithCurrency(formatThousands(n, isHtml), currency);
